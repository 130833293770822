/* === contact details === */
.about-contact {
    background: transparent;

    .db-box {
        padding-top: 0 !important;
    }

    [class^=col] {
        text-align: left;
        margin-top: 30px;

        h5 {
            color: $colorRed;
            font-size: 18px;
            margin-bottom: 8px;

            &[data-type]:before {
                color: $colorRed;
                font-family: "Font Awesome 5 Free";
                margin-right: 8px;
            }

            &[data-type^="phone"]:before {
                content: "\f4ad";
            }

            &[data-type^="address"]:before {
                content: "\f1ad";
            }

            &[data-type^="social"]:before {
                content: "\f086";
            }

        }

        div {
            margin-bottom: 8px;
        }

        a {
            display: flex;

            &:before {
                content: "\f0e0";
                color: $colorRed;
                font-family: "Font Awesome 5 Free";
                margin-right: 8px;
            }

        }

        .contact-social a {
            display: inline-block;
            width: 30px;
            height: 30px;
            background-size: contain;
            margin-right: 5px;
            background-repeat: no-repeat !important;
            opacity: 0.8;

            &:before {
                display: none;
            }

            &.twitter {
                background-image: url($PUBLIC_IMG_URL+"/icons/twitter.svg");
            }

            &.facebook {
                background-image: url($PUBLIC_IMG_URL+"/icons/facebook.svg");
            }

            &.linkedin {
                background-image: url($PUBLIC_IMG_URL+"/icons/linkedin.svg");
            }

            &.email {
                background-image: url($PUBLIC_IMG_URL+"/icons/email.svg");
            }
        }

    }
    .contact-form-link {
        a::before {
        display: none;
        }
    }    

    hr {
        margin-top: 30px;
        margin-bottom: 40px;
    }

}

.about-contact-mini {
    [class^=col]:first-child {
        margin-top: 0 !important;
    }

    [class^=col] {
        margin-top: 25px !important;
    }

    h5 {
        font-size: 16px !important;

        & + div {
            font-size: 15px;
        }
    }
}

/* === form box === */

.contact-form-box {

    .db-box {
        margin: 0;

        .db-header {
            margin-bottom: 20px;

            p {
                margin-top: 10px;
            }

            div {
                font-size: 22px;
                font-weight: 600;
            }
        }
    }

    .contact-form {
        @media (min-width: 600px) {
            max-width: 550px;
        }

        label {
            width: 100%;
            font-weight: 400;
            margin-bottom: 10px;
            font-size: 14px;
        }

        input,
        textarea,
        .contact-form-react-select {
            margin-bottom: 21px !important;
        }

        input,
        textarea {
            width: 100%;
            border: 1px solid $colorBorder;
            border-radius: 4px;
            font-size: 14px !important;
            padding: 7px 10px !important;
        }

        .contact-btn-box,
        iframe[title^="reCAPTCHA"] {
            margin-top: 25px;
        }

        .iframe-container {
            overflow: hidden;

            iframe {
                width: 100%;
                height: 880px;

                // hide the iframe background
                margin: -60px -40px;

                @media (max-width: 600px) {
                    width: calc(100% + 80px); // 80px = 40px x 2 (account for negative right/left margin)
                }
            }
        }
    }
}

.contact-page,
.pricing-contact-form {
    .react-tel-input {
        input,
        .flag-dropdown {
            border-color: $colorBorder !important;
        }
    }
}

/* === map === */

.map {
    min-height: 600px;
    height: 100%;
    padding: 10px;
    border: 1px solid #D6D8DA;
    border-radius: 4px;
    background: $colorWhite;

    & > div {
        height: 100% !important;
        min-height: 580px;
    }
}

.mapMark {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background-color: $colorWhite;
    background-image: url($PUBLIC_IMG_URL+"/icons/big_m.png") !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
}