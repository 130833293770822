html,
body {
  margin: 0 !important;
  padding: 0 !important;
  color: $colorBlack;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $siteFont !important;
}

.pageWrapper {
  height: 100%;
  position: relative;
  min-height: 100vh;
  padding-bottom: 165px;
}

// For smooth transition of scroll
html {
  scroll-behavior: smooth;
}

p {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0;
  padding: 0;
  //color: $colorBlack1;
}

hr {
    &.hr-2 {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
}

.red {
  color: $colorRed !important;
}

.green {
  color: $colorGreen;
}

input,
textarea,
select {
  outline: none;
  resize: none;
  border-radius: 0;
  border: 1px solid $colorGrey;
  background: $colorWhite; /* override default chrome mobile style that makes it gray */
}

// input type="number" - hide arrows
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

iframe {
  border: 0;
  margin: 0;
  padding: 0;
}

ul,
li,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: none !important;
    color: $colorRed;
  }

  &:active {
    color: $colorRed;
  }

  &:focus {
    color: $colorRed;
    outline: none;
  }
}

.form-control {
  height: auto;
  box-shadow: none !important;
  border-color: #ced4da !important;
}

button {
  box-shadow: none !important;
}

.line-clamp-2 {
  -webkit-line-clamp: 2 !important;
}

.mb-20 {
    margin-bottom: 20px;
}

hr.m-30 {
  margin-top: 30px;
  margin-bottom: 30px;
  border-top: 0.5px solid rgba(0,0,0,.1);
}

hr.m-20 {
  margin-top: 20px;
  margin-bottom: 20px;
  border-top: 0.5px solid rgba(0, 0, 0, 0.1);
}

.mt-4-offset {
    margin-top: -1.5rem;
}

.reflect-img {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

section.directory {
  .leftside {
    .widget {
      &:first-of-type {
        margin-top: 0 !important;
      }
    }

    .profile {
      background: $colorWhite;
      margin-bottom: 30px;

      .back {
        width: 100%;
        height: 100px;
        background-size: cover !important;
        background-repeat: no-repeat;
        background-position: 45%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }

      .body {
        background: $colorWhite;
        padding: 10px 20px;
        border-top: 1px solid #f8f8f8;
        margin: 30px 0 0 0;
      }

      .image {
        border: 1px solid $colorBorder;
        background: $colorWhite;
        border-radius: 4px;
        width: 64px;
        height: 64px;
        padding: 1px;
        margin-top: -30px;
        float: left;

        .img {
          width: 60px;
          height: 60px;
          background-size: contain !important;
          background-position: center center !important;
          background-repeat: no-repeat !important;

          &.applicationsLogo {
            background-size: contain !important;
          }

          h2 {
            margin: 0;
            text-align: center;
            line-height: 60px;
            text-transform: uppercase;
          }
        }
      }

      .image + h2 {
        font-size: 17px;
        color: $colorBlack;
        font-weight: 600;
        margin: 0px 0 0 80px;
        text-transform: lowercase;
      }

      .profileLink {
        color: $colorRed;
        font-size: 13px;
        border: none;
        margin: 0px 0 0 7px;
      }

      ul {
        border-top: 0.75px solid $colorBorder;
        margin: 10px 0 0 0;

        li {
          margin: 0;
          border-bottom: 0.75px solid $colorBorder;
          border-radius: 0;
          position: relative;
          padding: 5px 10px;
          height: 61px;
          display: flex;
          align-items: flex-end;

          h2 {
            color: $colorRed;
            font-size: 26px;
            margin: 0;
          }

          a {
            color: $colorBlack;
            font-size: 14px;

            &:hover {
              text-decoration: none;
            }
          }

          .add {
            width: 50px;
            height: 52px;
            position: absolute;
            right: 0;
            top: 4px;
            border-left: 0.75px solid $colorBorder;

            a {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;

              img {
                width: 25px;
              }
            }

          }

          &:last-of-type {
            border-bottom: 0 !important;

            a {
              color: $colorBlack;
            }
          }
        }
      }
    }

    .discover {
      padding: 20px;
      background: $colorWhite;
      margin-bottom: 30px;
      border-radius: 4px;
      -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);

      .title {
        text-align: center;
        padding: 0 0 17px;

        .line {
          display: block;
          border-bottom: 1px solid $colorGrey;
          margin: 0 0 -10px 0;
        }

        h4 {
          color: $colorGrey3;
          text-align: center;
          background: $colorWhite;
          display: inline;
          padding: 0 5px;
          font-size: 15px;
        }
      }

      ul {
        li {
          cursor: pointer;
          font-size: 14px;

          &:not(:last-child) {
            margin-bottom: 20px;
          }

          a {
            color: $colorBlack1;
            display: flex; // aligns icon and text
          }

          i {
            display: inline-block;
            height: 21px;
            width: 21px;
            background-size: contain;
            background-position: center center !important;
            background-repeat: no-repeat !important;
            margin-right: 10px;

            svg {
              max-width: 20px;
              color: $colorRed;
              margin-top: -2px;
            }
          }

          &:hover,
          &.active {
            transition: 0.3s linear;
          }
        }
      }
    }

    .myblock {
      padding: 15px;
      background: $colorWhite;
      border: 1px solid $colorGrey;
      border-radius: 4px;
      box-shadow: 0 0 10px $colorGrey;
    }

  }

  .rightside {
    & > div {
      &:first-of-type {
        margin-top: 0;
      }
    }

    a.seeall {
      font-weight: 300;
      color: $colorWhite;
      background: $colorRed;
      margin: 20px auto 10px auto;
      width: 200px;
      border-radius: 4px;
      display: block;
      text-align: center;
      line-height: 35px;

      &:hover {
        text-decoration: none;

        background: $colorBlack1;
      }
    }

    .filters {
      margin-top: 30px;
      background: $colorWhite;
      border-top: 1px solid $colorBorder;
      border-radius: 4px;
      -webkit-box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1);
      box-shadow: 0 0px 1px 0px rgba(0, 0, 0, 0.1);
      border-bottom: 0.75px solid #e5e5e5;

      h1 {
        padding: 0 0 0 30px;
        background-size: 20px;
        margin: 0;
        font-size: 20px;
        display: inline-block;
        background-position: left center;
        background-repeat: no-repeat;
        font-weight: 600;

        svg {
          width: 21px;
          color: $colorRed;
        }
      }

      h1 + span {
        margin-left: 6px;
        color: $colorGrey3;
        margin-top: 2px;
        font-size: 15px;
      }

      a {
        color: $colorWhite;
      }

      .firstLine {
        padding: 5px 15px;
        display: flex;
        align-items: center;

        a {
          display: inline-block;
          float: right;
          text-align: center;
          font-size: 13px;
          border-radius: 4px;
          line-height: 20px;
          margin: 10px 0 0 10px;
          padding: 4px 10px;
          width: 134px;

          &.addStartup {
            background-color: $colorBlack1;

            &:before {
              background: url($PUBLIC_IMG_URL+"/plus.png") center center no-repeat;
            }
          }

          &.columns {
            background-color: $colorGrey3;

            &:before {
              background: url($PUBLIC_IMG_URL+"/columns.png") center center no-repeat;
            }
          }
        }
      }

      .secondLine {
        padding: 0 15px;
        background: $colorWhite1;
        display: table;
        width: 100%;
        //border: 1px solid $colorBorder;
        border-radius: 0;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;

        div {
          display: table-cell;
          text-align: center;
          border-right: 1px solid $colorBorder;
          width: 20%;
          &:last-of-type {
            width: 130px;
            border-right: 0;
            padding-left: 15px;
          }
        }

        input,
        select {
          height: 50px;
          line-height: 50px;
          color: $colorBlack1;
          background: $colorWhite1;
          border: 0;
          width: 90%;
          font-size: 12px;
          margin: 0 auto;
        }

        input {
          background: url($PUBLIC_IMG_URL+"/icons/search.png") right center no-repeat;
          background-size: 15px;
        }

        select {
          width: 100%;
          margin: 0;
          padding: 0 5%;
          font-size: 12px;
          color: $colorBlack1;
          border-radius: 4px;
          background-color: $colorWhite1;
          -webkit-appearance: none;
          -moz-appearance: none;
          -ms-appearance: none;
          text-indent: 0.01px;
          appearance: none !important;
          background: url($PUBLIC_IMG_URL+"/icons/dropdown.png") 95% center no-repeat;
          background-size: 12px;
        }

        select::-ms-expand {
          display: none;
        }

        a.addFilter {
          display: inline-block;
          border-radius: 4px;
          margin: 10px 0 10px 0;
          padding: 5px 13px;
          width: 135px;
          background-color: $colorRed;
          font-size: 12px;

          &:before {
            display: inline-block;
            width: 13px;
            background-size: contain !important;
            height: 13px;
            margin: 2px 8px 0 3px;
            content: " ";
            float: left;
            background: url($PUBLIC_IMG_URL+"/plus.png") center center no-repeat;
          }
        }

        &.filters {
          margin: 0;

          a {
            color: $colorBlack;
            font-size: 14px;
            padding-bottom: 6px;
            margin-right: 20px;
            display: inline-block;
            padding-top: 7px;

            span {
              color: $colorWhite;
              background: $colorBlack;
              border-radius: 10px;
              display: inline-block;
              text-align: center;
              vertical-align: middle;
              font-size: 12px;
              padding: 0px 5px;
              margin-left: 5px;
            }

            &:hover,
            &.active {
              color: $colorRed;
              border-bottom: 3px solid $colorRed;
              text-decoration: none;

              span {
                background: $colorRed;
                font-size: 12px;
              }
            }
          }
        }
      }
      .widNew25 {
        div {
          width: 25%;
        }
      }
    }

    .table {
      background: $colorWhite;
      margin-top: 9px;
      margin-bottom: 0;
      border-radius: 4px;
      border: 1px solid rgba(0,0,0,0.1);
      display: table;

      span,
      p,
      h3 {
        font-weight: 300;
        font-size: 13px;
        overflow: hidden;
        display: block;

        a {
          color: $colorRed;
          &:hover,
          &:active {
            color: $colorRed;
          }
        }
      }

      .row {
        display: table-row;
        width: 100%;
        padding: 0 15px;
        margin-left: -9px;
        margin-right: -9px;
        height: 105px;

        &:first-of-type {
          span,
          p,
          h3 {
            display: inline;
          }
        }

        &:before,
        &:after {
          display: none;
        }

        &:first-of-type {
          .element {
            white-space: nowrap;

            span,
            h3 {
              color: $colorBlack;
            }
          }

          span,
          p {
            font-weight: 600;
          }

          i {
            display: inline-block;
            position: relative;
            width: 16px;
            height: 16px;

            /*&:before {
    display: block;
    content: "\f0dc";
    font-family: FontAwesome;
    font-style: normal;
    font-size: 16px;
    color: $colorPureBlack;
    margin-left: 5px;
    position: absolute;
  }

  &.sortAsc {
    top: 5px;

    &:before {
      content: "\f0de";
    }
  }

  &.sortDesc {
    bottom: 5px;

    &:before {
      content: "\f0dd";
    }
  } */
          }
        }

        .element {
          display: table-cell;
          vertical-align: middle;
          padding: 10px 9px;
          max-width: 180px;
          min-width: 100px;
          border-bottom: 1px solid $colorBorder;

          &.heightauto {
            height: auto !important;

            span,
            p {
              height: auto !important;
              line-height: 20px;
            }
          }

          &:first-of-type {
            width: 45px;
            min-width: 45px;
            max-width: 45px;
            text-align: center;

            input {
              margin-left: 10px;
            }
          }

          &.longText {
            min-width: 280px;
            max-width: 450px;
          }

          &.after {
            p,
            span {
              display: block;
              float: left;
              width: 99%;
            }

            //&:after {
            //  content: '...';
            //}
          }

          p {
            margin-bottom: 0;
          }

          &:nth-child(2) {
            white-space: nowrap;
            width: 220px !important;

            .logo {
              float: left;
              width: 45px;
              height: 45px;
              border-radius: 4px;
              border: 1px solid $colorBorder;
              margin: 8px 10px 0 0;
              background-color: $colorWhite;
              background-size: contain !important;
              background-repeat: no-repeat;
              background-position: center center !important;

              h2 {
                line-height: 45px;
                text-align: center;
                color: $colorBlack;
                text-decoration: none;
                margin: 0;
                text-transform: uppercase;
              }
            }

            h3 {
              color: $colorRed;
              font-weight: 600;
              display: table-cell;
              max-width: 145px;
              vertical-align: middle;
              height: 58px;
            }
          }

          .apply {
            color: $colorRed;

            &:hover {
              text-decoration: none;
            }
          }
        }

        &:nth-child(even) {
          background: $colorWhite1;
        }
      }
    }
  }

  .btn-grey {
    color: $colorWhite;
    background-color: $colorBlack;
    border-color: $colorBlack;
  }

  .btn-outline-grey {
    color: $colorBlack;
    border-color: $colorBlack;
  }

}

.lock {
  width: 100%;
  display: block;
  text-align: center;
  padding: 50px 20px 55px 20px;
  background: $colorWhite;
  margin-bottom: 30px;

  &.greyBackground {
    background: none !important;
  }

  img {
    max-width: 50px;
    margin-bottom: 15px;
  }

  h2 {
    font-size: 25px;
    font-weight: 300;
  }

  h3 {
    font-weight: 600;
    font-size: 19px;
    margin-bottom: 5px;
  }

  p {
    //max-width: 560px; changed for summary in Research Page
    margin: 0 auto !important;
    font-size: 16px;
  }

  a {
    display: inline-block;
    line-height: 1.5;
    color: $colorWhite !important;
    border-radius: 4px;
    max-width: 170px;
    width: 43%;
    padding: 6px 12px;
    margin-right: 10px;
    margin-top: 15px;
    margin-left: 10px;

    &:hover {
      background: $colorRed;
    }
  }
}

.learn {
  background: $colorGrey3;
}

.buy {
  background: $colorBlack1;
}

div.clear,
br.clear {
  clear: both;
}

.filterBackground {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 30;
  background: $colorGrey3;
  opacity: 0.5;
  filter: alpha(opacity=50);
  top: 0;
  left: 0;
}

.checkbox {
  clear: both;

  input {
    cursor: pointer;
    position: absolute;
    opacity: 0;
    width: 20px;
    height: 20px;
    left: 0;
    top: 180px;

    & + label:before {
      content: " ";
      display: block;
      float: left;
      width: 20px;
      height: 20px;
      margin: 0 10px 0 10px;
      background-size: contain !important;
    }

    &:checked {
      & + label:before {
        background: url($PUBLIC_IMG_URL+"/icons/ticked.png") center center no-repeat;
      }
    }

    &:not(checked) {
      & + label:before {
        background: url($PUBLIC_IMG_URL+"/icons/checkbox.png") center center no-repeat;
      }
    }
  }

  label {
    padding-left: 0 !important;
  }
}

.no-margin-checkbox label:before {
  margin-left: 0px !important;
  margin-right: 2px !important;
  width: 17px !important;
}

.popup {
  position: fixed;
  top: 25vh;
  left: 50%;
  top: 25vh;
  border-radius: 4px;
  background: $colorWhite;
  overflow-y: hidden;
  z-index: 1050;
  margin-top: 0px !important;
  height: auto !important;

  ul {
    border: none !important;
  }

  h3 {
    font-size: 18px;
    padding: 15px !important;
  }

  .buttons {
    background: #e5e6e8;
    padding-bottom: 20px !important;

    a {
      margin-top: 2px;
    }
  }

  .title {
    text-align: center;
    font-size: 18px !important;
    font-weight: 600;
    padding: 0 !important;

    span.line {
      display: block !important;
      width: 90% !important;
      border-bottom: 1px solid $colorGrey4 !important;
      margin: 0 auto -25px !important;
      padding-bottom: 30px !important;
    }

    img {
      max-width: 50px;
      margin: 10px 0;
    }

    h4 {
      font-size: 15px !important;
      font-weight: 600 !important;
      display: inline-block !important;
      text-align: center !important;
      padding: 5px !important;
      text-transform: uppercase !important;
      background: $colorWhite2 !important;
      color: $colorBlack !important;
    }
  }

  .short {
    width: 80% !important;
    margin: 0px auto;

    input,
    select {
      width: 48% !important;
      margin: 0 0% 20px !important;

      &:first-of-type {
        float: left;
      }

      &:last-of-type {
        float: right;
      }
    }
  }

  select {
    width: 80%;
    padding: 0 5%;
    font-size: 16px;
    color: $colorBlack1;
    height: 40px;
    border-radius: 4px;
    background-color: $colorWhite;
    -webkit-appearance: none;
    line-height: 40px;
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: "";
    -ms-appearance: none;
    appearance: none !important;
  }

  .Select {
    width: 80%;
    margin: 0 auto;
    font-size: 16px;
    color: $colorBlack1;
    height: 40px;
    border-radius: 4px;
    background-color: $colorWhite;
    line-height: 40px;
    text-indent: 0.01px;
    text-overflow: "";

    .Select-clear-zone {
      display: none;
    }

    .Select-value-label {
      line-height: 47px;
    }
  }

  select::-ms-expand {
    display: none;
  }

  form {
    width: 100% !important;
    margin-left: 0 !important;
    background: none !important;

    input,
    textarea {
      border: 1px solid $colorBorder !important;
      background: $colorWhite !important;
    }

    input.error,
    textarea.error,
    select.error {
      border: 1px solid red !important;
    }

    .Select-input input {
      border: 0px !important;
    }
  }

  .fullWidth {
    width: 90% !important;
    margin: 0 !important;
  }

  .tabs {
    height: 73px;
    position: relative;
    padding-top: 15px;

    a {
      color: $colorGrey4;
      line-height: 45px;
      color: $colorGrey4;
      font-weight: 600;
      font-size: 16px;
      padding: 0 36px 24px;
      text-transform: uppercase;

      &.active,
      &:hover {
        text-decoration: none;
        border-bottom: 1px solid $colorRed;
      }
    }
  }

  .breadCrumbs {
    //line-height: 40px;
    padding: 22px 0 15px 30px;

    a {
      color: $colorBlack;
      font-size: 20px;
      //font-weight: 600;

      &:hover {
        color: $colorRed;
        text-decoration: none;
      }
    }

    span {
      display: inline-block;
      padding: 0 12px;
      width: 10px;
      height: 14px;
      background: url($PUBLIC_IMG_URL+"/bread.png") center center no-repeat;
      background-size: contain;
    }
  }

  a.close {
    position: absolute;
    top: 8px;
    right: 14px;
    opacity: 0.4;
    filter: alpha(opacity=40);
    z-index: 999;

    img {
      max-width: 15px;
    }

    &:hover {
      opacity: 1;
      filter: alpha(opacity=100);
    }
  }

  .content {
    background: $colorGrey;
    position: relative;
    z-index: 99;
    text-align: center;
    // hide scroll bar on mobile
    // overflow: hidden;
    // padding: 15px;
    // padding-bottom: 12px;

    ul.preload {
      box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.05);
      border: 1px solid $colorBorder;
      position: absolute;
      top: 75px;
      left: calc(10% + 5px);
      width: calc(80% - 10px) !important;
      margin: 0 !important;
      background: $colorWhite;
      white-space: normal;
      z-index: 99;
      text-align: left !important;

      li {
        border-bottom: 1px solid $colorBorder;
        width: 100% !important;
        padding: 3px 5%;

        a {
          color: $colorRed;
        }
      }
    }

    .social {
      img {
        max-width: 80%;
      }
    }

    h3 {
      font-size: 20px;
      font-weight: 600;
      color: $colorRed;
    }

    p {
      font-size: 16px;
    }

    input {
      width: 80%;
      margin: 10px 5%;
      padding: 0 5%;
      font-size: 16px;
      height: 40px;
    }

    textarea {
      width: 455px;
      height: 105px;
      resize: none;
      line-height: 20px;
      margin: 22px;
      padding: 10px;
      font-size: 16px;
      margin-bottom: 15px;
    }

    .attach {
      div {
        width: 40%;
        margin: 10px 5%;
        display: inline-block;
        float: left;

        a.chooseFile {
          color: $colorWhite;
          width: 100%;
          background: $colorRed;
          line-height: 40px;
          display: block;
          font-size: 16px;
          margin-bottom: -30px;

          &:hover {
            background: $colorGrey4;
            text-decoration: none;
          }
        }

        input {
          opacity: 0;
          margin: -40px 0 0 0;
          width: 100%;
        }
      }
    }

    .important {
      text-align: center;

      &:before {
        content: "\f06a";
        display: inline-block;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 50px;
        color: $colorRed;
      }
    }
  }

  .buttons {
    padding: 0 0 10px 0;
    text-align: center;

    a {
      display: inline-block;
      width: 170px;
      line-height: 38px;
      border-radius: 4px;
      color: $colorWhite;
      text-decoration: none;
      font-size: 14px;
      text-transform: uppercase;

      &:first-of-type {
        background: $colorBlack1;
      }

      &:last-of-type {
        background: $colorRed;
      }
    }
  }
}

.filterBlock {
  //margin: -320px 0 0 -300px;
  //width: 700px;
  margin: -45vh 0px 0px -30vw;
  width: 60%;
  height: auto;
  //height: 100vh;

  .content {
    .left {
      width: 45%;
      height: 70vh;
      //height: 530px;
      float: left;
      background: $colorWhite2;
      overflow-y: scroll;
      padding: 15px 5px;

      .title {
        span.line {
          width: 90%;
        }
      }

      a {
        color: $colorBlack;
        font-size: 15px;
        display: block;
        border-radius: 4px;
        text-align: left;
        margin: 0px 10px;
        padding: 5px 15px;
        //font-size: 16px;
        //font-weight: 600;
        //line-height: 30px;
        //margin: 5px 10px;
        //padding: 5px 15px;

        &:after {
          display: block;
          float: right;
          content: " ";
          width: 10px;
          //height: 30px;
          height: 15px;
          background: url($PUBLIC_IMG_URL+"/filter-arrow.png") center center no-repeat;
          background-size: contain;
        }

        &:hover,
        &.active {
          color: $colorWhite;
          text-decoration: none;
          background: $colorBlack1;

          &:after {
            background: url($PUBLIC_IMG_URL+"/filter_active.png") center center no-repeat;
            background-size: contain;
          }
        }
      }
    }

    .right {
      width: 55%;
      height: 70vh;
      //height: 530px;
      float: right;
      padding: 25px 0;
      overflow-y: scroll;
      background: $colorWhite1;

      label {
        width: 90%;
        margin: 5px 5%;
        font-size: 15px;
        text-align: left;
        font-weight: 600;
      }

      button {
        width: 90%;
        margin: 15px 5%;
        color: $colorWhite;
        line-height: 40px;
        border: 0;
        background: $colorGrey7;
        border-radius: 4px;
        text-transform: uppercase;

        &:hover {
          color: $colorWhite;
          background: $colorGrey4;
        }
      }

      .filterContent {
        & > div:not(.rc-slider) {
          position: relative;
        }
      }

      input {
        width: 90%;
        margin: 5px 5%;
        border-radius: 4px;
      }

      .checkbox {
        margin-bottom: 24px;
        margin-left: 5px;

        input {
          margin: 5px 5px 5px 15px;
        }

        label {
          width: 70%;
          float: left;
          margin: 5px 5px;
          text-align: left;
          padding: 0;
        }
      }
    }
  }
}

.buyPro {
  width: 700px;
  height: 380px;
  margin: -190px 0 0 -350px;

  .title {
    img {
      margin: 14px 0 15px;
    }
  }
  .content {
    padding: 48px 0 73px;

    h3 {
      padding: 0;
    }

    p {
      padding: 0 50px;
      text-align: center;
      margin: 0;
    }
  }
}

.success {
  width: 450px;
  height: auto;
  // height: 390px;
  margin: -195px 0 0 -225px;

  .close {
    top: 25px;
  }
  .title {
    padding: 20px;

    h3 {
      margin: 0;
      padding: 25px;
    }
  }

  .content {
    padding: 35px 0 30px;

    h3 {
      color: $colorBlack;
      text-transform: uppercase;
      font-size: 23px;
    }

    p {
      padding: 0 90px;
    }

    img {
      max-width: 65px;
    }
  }

  .buttons {
    a {
      &:last-of-type {
        background: $colorGrey4;
        width: 83%;
        margin: 19px auto;
      }
    }
  }
}

.apply {
  width: 450px;
  min-height: 300px;
  height: auto;
  margin: -275px 0 0 -225px;

  .title {
    h3 {
      font-size: 18px;
      font-weight: 600;
      padding: 10px 0 19px;
    }
  }

  .content {
    padding: 25px 0 20px;

    .attach {
      div {
        p {
          font-size: 17px;
          font-weight: 600;
          text-align: left;
          padding: 4px 0 7px;
          margin: 0;
        }

        .fileformlabel,
        .errorsContainer {
          margin: 0 !important;
          width: 100%;
          overflow: hidden;
          font-weight: 500;
          max-height: 45px;
          /* white-space: nowrap; */
          height: auto;
          font-size: 12px;
          color: $colorWhite;
        }

        a.chooseFile {
          border-radius: 4px;
        }
      }

      div:first-of-type {
        width: 38%;
        margin: 10px 2% 0 10%;
      }

      div:last-of-type {
        width: 38%;
        margin: 10px 10% 0 2%;
      }
    }
    }

  .buttons {

    span {
      margin: 10px;
      line-height: 40px;
    }

    a {
      &:last-of-type {
        background: $colorBlack1;
        width: 33%;
        float: right;
        margin: 0 10% 0 0;
      }
    }
  }
}

.golive {
  width: 500px;
  min-height: 320px;
  height: auto;
  margin: -200px 0 0 -250px;

  .title {
    h3 {
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 600;
      padding: 10px 0 19px;
    }
  }

  .content {
    padding: 25px 30px 20px;
    text-align: left;

    .line {
      height: 1px;
      border-bottom: 1px solid $colorBorder;
    }

    p {
      margin: 10px 0;
    }

    .red {
      color: $colorRed;
      font-weight: 600;
    }
  }

  .errorMessage {
    color: $colorRed;
    text-align: center;
    margin: 0;
    padding: 10px 0;
  }

  .buttons {
    margin-top: 15px;
    background: $colorWhite;
    color: $colorWhite;

    a {
      width: 42%;
      margin: 0 7%;

      &:first-of-type {
        margin-right: 1%;
      }

      &:last-of-type {
        margin-left: 1%;
      }
    }
  }
}

.update {
  width: 400px;
  // min-height: 300px;
  height: auto;
  margin: -250px 0 0 -200px;

  .title {
    h3 {
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 600;
      padding: 10px 0 19px;
    }
  }

  .content {
    padding: 25px 30px 20px;
    text-align: left;

    label {
      width: 90%;
      margin: 0px 5%;
    }

    .success-info {
      text-align: center;

      &:before {
        content: "\f05a";
        display: inline-block;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 50px;

        // differentiate info success from regular success modal
        // otherwise people think it's a success message and don't read the text
        color: #333;
      }
    }

    .important {
      text-align: center;

      &:before {
        content: "\f06a";
        display: inline-block;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 50px;
        color: $colorRed;
      }
    }

    p.delete {
      text-align: center;
    }

    input,
    select {
      width: 90% !important;
      margin: 10px 5%;
    }

    .iCalendar {
      position: relative;
      font-size: 18px;
      margin-top: 20px;
      margin-bottom: -37px;
      width: 20px;
      left: 23px;
      z-index: 10;
      display: block;
    }
  }
  .buttons {
    // margin-top: 15px;
    // margin-bottom: 15px;

    a {
      width: 42%;
      margin: 0 7%;

      &:first-of-type {
        margin-right: 1%;
      }

      &:last-of-type {
        margin-left: 1%;
      }
    }
  }
}

.forgot {
  width: 440px;
  height: 325px;
  margin: -163px 0 0 -220px;

  .title {
    h3 {
      font-size: 19px;
      font-weight: 500;
      padding: 5px 0 20px 0;
      color: $colorGrey4;
    }
  }
  .content {
    padding: 25px 0 10px;

    p {
      margin: 0px 0 5px 10%;
      text-align: left;
    }

    input {
      width: 80%;
      margin: 10px auto 15px;
      padding: 0 10px;
    }

    a {
      background: $colorBlack1;
      width: 80%;
      margin: 10px 10% 0;
      color: $colorWhite;
      display: block;
      line-height: 40px;
      border-radius: 4px;
    }
  }

  p {
    font-size: 13px;
    padding: 22px 65px 0;
    text-align: center;

    a {
      color: $colorRed;
    }
  }
}

.title {
  span.line {
    display: block;
    width: 90%;
    border-bottom: 1px solid $colorGrey4;
    margin: 0 auto -25px;
    padding-bottom: 30px;
  }

  h4 {
    display: inline-block;
    font-size: 15px;
    text-align: center;
    padding: 5px;
    text-transform: uppercase;
    background: $colorWhite2;
  }
}

.login {
  width: 450px;
  min-height: 426px;
  margin: -213px 0 0 -225px;

  .content {
    padding: 35px 0 23px;

    .linked {
      width: 80%;

      span {
        width: 80%;
        padding: 0 15px;
      }
    }

    p {
      margin: 0px 0 5px 10%;
      text-align: left;
    }

    .title {
      width: 90%;
      margin: 0 auto;
    }
  }

  .buttons {
    p {
      display: inline-block;
      width: 43%;
      float: left;
      margin: 0 0 10px 10%;
      line-height: 55px;
      text-align: left;
    }

    a {
      background: $colorGrey4 !important;
      margin: 15px 10% 0 0;
      width: 32%;
      float: right;

      &:hover {
        background-color: $colorRed;
      }
    }

    .closeButton {
      width: 90%;
      margin: 0;
    }

    .redButton {
      background-color: $colorRed !important;

      &:hover {
        background-color: $colorBlack1 !important;
      }
    }
  }
}

.changePopup {
  max-height: 380px;
  height: auto;
  margin: -190px 0 0 -225px;
}
.connectpopUp {
  margin: -300px 0 0 -250px;
  width: 500px;
}
#ignoreBtn {
  background-color: $colorBlack1;
}
.connect {
  margin-top: 20px;
  background-color: $colorRed;
  color: $colorWhite;
  font-weight: 600;
  padding: 4px 20px;
  border: none;
  border-radius: 4px;

  img {
    height: 14px;
  }
  &:focus {
    outline: none;
  }

  h3 {
    color: $colorBlack !important;
    text-transform: uppercase;
    font-weight: 600;
    padding: 10px 0;
  }

  ul {
    li {
      width: 50%;
      height: 40px;
      padding: 0 5%;
      margin: 3px 0;
      float: left;
      text-align: left;
      position: relative;

      input {
        margin: 0px !important;
      }

      label {
        display: block;

        &:before {
          height: 100% !important;
          min-height: 22px;
        }
      }

      select {
        width: 100%;
        padding: 0px 5%;
        margin: 5px 0;
        font-size: 16px;
        color: $colorBlack1;
        height: 30px;
        background-color: $colorWhite;
        border: 1px solid $colorBorder;
        line-height: 30px;
      }
    }
  }

  .line {
    height: 1px;
    margin: 5px 0;
    border-top: 1px solid $colorBorder;
  }

  p {
    width: 80%;
    margin: 5px auto;
    font-size: 13px !important;
    padding: 10px 0;
  }

  p.italic {
    font-style: italic;
  }
}

// fix btn style issue on iOS devices
.btn {
  -webkit-appearance: none;
}

.btn-main,
.btn-main:hover {
  background-color: $colorRed;
  color: $colorWhite !important;
}

.btn-main-outline {
  background: $colorWhite;
  border: 1px solid $colorRed;
  color: $colorRed !important;
}

.btnLoading {
  background-color: $colorRed;

  -webkit-animation: loadingBtn 1s infinite ease-in-out;
  animation: loadingBtn 1s infinite ease-in-out;

  @-webkit-keyframes loadingBtn {
    0% {
      -webkit-transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
      opacity: 0;
    }
  }

  @keyframes loadingBtn {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0;
    }
  }
}

.register {
  width: 440px;
  height: 615px;
  margin: -308px 0 0 -220px;

  .content {
    padding: 35px 0 43px;

    input {
      margin: 0 10% 20px;
      color: $colorBlack1;
    }

    p {
      margin: 0px 0 5px 10%;
      text-align: left;

      &.red {
        font-size: 14px;
        margin: -15px 0 5px 10%;
        text-align: left;
      }
    }

    .title {
      width: 90%;
      margin: 0 auto;
    }
  }

  .buttons {
    p {
      display: inline-block;
      width: 43%;
      line-height: 14px;
      font-size: 12px;
      margin-top: 25px;
      margin-left: 6%;
      text-align: left;

      a {
        background: none !important;
        color: $colorRed;
        margin: 0;
        padding: 0;
        display: inherit;
        width: auto;
        text-transform: none;
        text-decoration: none;
        line-height: 14px;
        font-size: 12px;
      }
    }

    a.buyProButton {
      background: $colorBlack1;
      width: 145px;
      float: right;
      margin: 22px 10% 0 0;

      &:hover {
        background-color: $colorRed;
      }
    }
  }
}

.top-block {
  background: $colorWhite;
  margin-bottom: 30px;

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  & > img {
    width: 100%;
  }

  & > .coverImg {
    width: 100%;
    height: 195px;
    background-repeat: no-repeat;
    background-size: cover !important;
    background-position: center center;
    background: lightgray; // default for testapp where cover img doesn't load
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    @media (max-width: 600px) {
        height: 150px;
    }
  }

  .logoBlock {
    border: 1px solid #b8bbbe;
    background: $colorWhite;
    float: left;
    padding: 3px;
    background-size: contain !important;
    background-position: 50% 50% !important;
    background-repeat: no-repeat !important;
    border-radius: 10px;
    z-index: 1;
    display: block;
    margin-right: 20px;

    width: 100%;
    aspect-ratio: 1;
    margin-top: -50%;

    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      text-transform: uppercase;
      text-align: center;
      margin: 0;
    }

    img {
      width: 142px;
      height: 142px;
    }
  }

  .textInformation {

    h1 {
      margin-bottom: 10px;
      display: inline-block;
      font-size: 19px;
      font-weight: 600;
    }

    .tick {
      position: relative;

      i {
        color: $colorWhite;
        background: $colorRed;
        border-radius: 10px;
        text-align: center;
        width: 20px;
        height: 20px;
        line-height: 20px;
        margin: 0 10px;
      }

      i:hover {
        & + .message {
          display: block !important;
        }
      }

      .message {
        background: #222 none repeat scroll 0% 0%;
        color: $colorWhite !important;
        border: 0px solid $colorWhite;
        border-radius: 4px;
        font-size: 12px !important;
        line-height: 1.5;
        font-family: "helvetica neue", arial, helvetica, freesans, "liberation sans", "numbus sans l", sans-serif !important;
        max-width: 200px;
        font-weight: 400;
        padding: 10px;
        white-space: normal;
        position: absolute;
        min-width: 200px;
        top: -8px;
        right: -210px;
        z-index: 20;
        display: none;
        text-align: left !important;
        text-transform: none !important;

        &:before {
          width: 0px;
          height: 0px;
          border-style: solid;
          border-width: 10px 10px 10px 0;
          border-color: transparent #222 transparent transparent;
          left: -10px;
          top: 9px;
          content: "";
          font-size: 0px;
          position: absolute;
        }
      }
    }

    .info {
      margin-left: 10px;

      span {
        margin-right: 30px;
        font-size: 14px;

        &:before {
          content: " ";
          line-height: 15px;
          width: 15px;
          height: 15px;
          display: inline-block;
          background-size: contain !important;
          margin-right: 5px;
        }
      }

      .views {
        &:before {
          background: url($PUBLIC_IMG_URL+"/icons/eye.png") left bottom no-repeat;
        }
      }

      .connections {
        &:before {
          background: url($PUBLIC_IMG_URL+"/m.png") left bottom no-repeat;
        }
      }

      .skype {
        &:before {
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          content: "\f17e";
          font-size: 20px;
          color: $colorPureBlack;
        }
      }

      .startups {
        &:before {
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          content: "\f135";
          font-size: 20px;
          color: $colorPureBlack;
        }
      }
    }
  }

  .buttons {
    margin-top: 20px;
    float: right;

    a {
      padding: 5px 35px 5px 15px;
      margin-left: 20px;
      color: $colorWhite;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 600;
      background: $colorRed;
      border-radius: 4px;

      &:before {
        width: 20px;
        height: 20px;
        margin: 0 10px 0 0;
        font-family: $fontAwesome;
        display: inline-block;
      }

      &:hover {
        color: $colorWhite;
      }

      &.edit {
        &:before {
          content: "\f044";
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
        }
      }

      &.report {
        &:before {
          content: "\f06a";
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
        }
      }

      &.favourite {
        &:before {
          content: "\f005";
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
        }
      }

      &.connect {
        background: $colorBlack1;
        &:before {
          content: " ";
          background: url($PUBLIC_IMG_URL+"/users.png") left bottom no-repeat;
          background-size: 14px;
        }
      }

      &.cancel {
        &:before {
          content: "\f05e";
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
        }
      }

      &.accept {
        &:before {
          content: "\f058";
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
        }
      }

      &.ignore {
        &:before {
          content: "\f057";
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
        }
      }

      &.message {
        &:before {
          content: "\f003";
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
        }
      }

      &.apply {
        &:before {
          content: "\f044";
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
        }
      }

      &.connect {
        background: $colorRed;
      }

      &.website {
        background: $colorBlack1;
        &:before {
          content: "\f0ac";
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
        }
      }
    }
  }

  .description {
    width: 95%;
    margin: 20px 2.5%;
    padding-top: 15px;
    border-top: 1px solid $colorBorder;

    p {
      margin-bottom: 40px;
      overflow: hidden;
    }

    iframe {
      max-width: 50%;
      float: left;
      margin: 10px;
    }

    img {
      max-width: 100%;
      max-height: 900px;
    }

    .productPicture {
      max-width: 50%;
      margin: 10px;
      float: left;
    }

    .showmore {
      display: block;
      text-align: center;

      a {
        font-weight: 600;
        color: $colorBlack;
        text-decoration: none !important;

        img {
          max-width: 12px;
        }

        &:hover {
          color: $colorRed;
        }
      }
    }
  }
}

.left-block {
  width: 22%;
}

.content-block {
  //width: 22%;

  .leftside {
    .widget {
      background: $colorWhite;
      margin: 30px 0;
      -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);

      &.profile {
        margin-top: 0;

        .image {
          float: left;
          width: 71px;
          height: 71px;
          padding: 2px;
          border: 1px solid $colorBorder;
          border-radius: 4px;
          margin: 10px;

          .img {
            width: 65px;
            height: 65px;
            background-size: cover !important;
            background-position: center center !important;
            background-repeat: no-repeat !important;
          }
        }

        h3 {
          color: $colorRed;
          font-weight: 400;
          font-size: 16px;
          padding: 10px 5px 5px 5px;
          margin: 0;
        }

        p {
          font-size: 14px;
          line-height: 14px;
          color: $colorGrey4;
          padding: 5px;
        }

        .line {
          width: 100%;
          border-bottom: 1px solid $colorBorder;
          margin-bottom: 10px;
        }

        .progress {
          width: 90%;
          background: $colorWhite1;
          height: 8px;
          border-radius: 8px;
          border: 0;
          margin: 0 auto;

          .complete {
            background: $colorGreen;
            height: 8px;
            height: 8px;
            border-radius: 8px;
            border: 0;
            width: 60%;
          }
        }

        .progressText {
          text-align: center;
          color: $colorGrey4;
          padding: 5px;
        }
      }

      .title {
        border-bottom: 1px solid $colorBorder;

        h3 {
          font-size: 14px;
          font-weight: 400;
          padding: 10px;
          line-height: 30px;
          margin: 0;

          &:before {
            width: 20px;
            height: 20px;
            display: inline-block;
            margin: 0 10px -5px 0;
            content: " ";
            background-size: contain !important;
          }

          &.info {
            &:before {
              background: url($PUBLIC_IMG_URL+"/icons/side_info.png") left center no-repeat;
            }
          }

          &.location {
            &:before {
              font-family: "Font Awesome 5 Free";
              font-weight: 900;
              content: "\f041";
              width: auto;
              color: $colorRed;
              font-size: 20px;
            }
          }

          &.supports {
            &:before {
              font-family: "Font Awesome 5 Free";
              font-weight: 900;
              content: "\f24e";
              width: auto;
              color: $colorRed;
              font-size: 20px;
            }
          }

          &.company {
            &:before {
              background: url($PUBLIC_IMG_URL+"/icons/corporate.png") left center no-repeat;
            }
          }

          &.interest {
            &:before {
              background: url($PUBLIC_IMG_URL+"/interests.png") left center no-repeat;
            }
          }

          &.chain {
            &:before {
              background: url($PUBLIC_IMG_URL+"/icons/chain.png") left center no-repeat;
            }
          }

          &.news {
            &:before {
              background: url($PUBLIC_IMG_URL+"/icons/news_white.png") left center no-repeat;
              height: 15px;
              width: 15px;
              vertical-align: middle;
              margin-top: -7px;
            }
          }

          &.startups {
            &:before {
              background: url($PUBLIC_IMG_URL+"/icons/startup_white.png") left center no-repeat;
              height: 15px;
              width: 15px;
              vertical-align: middle;
              margin-top: -7px;
            }
          }

          &.favorites {
            &:before {
              background: url($PUBLIC_IMG_URL+"/side_fav.png") left center no-repeat;
            }
          }

          &.connections {
            &:before {
              background: url($PUBLIC_IMG_URL+"/side_con.png") left center no-repeat;
            }
          }

          &.twitter,
          &.facebook {
            &:before {
              font-family: "Font Awesome 5 Brands";
              font-weight: 900;
              margin: 0;
              color: $colorRed;
              height: auto;
              width: auto;
              margin-right: 10px;
            }
          }

          &.twitter {
            &:before {
              content: "\f099";
            }
          }

          &.facebook {
            &:before {
              content: "\f39e";
            }
          }
        }
      }

      .text {
        padding: 14px;
        font-size: 14px;

        p {

            &:not(:first-of-type) {
                margin-top: 15px;
            }

          span {
            font-weight: 600;
          }

          a {
            &.social {
              padding: 0px;
              margin: 0 5px;
              content: "";
              font-family: FontAwesome;
              color: $colorBlack;

              &:hover {
                color: $colorRed;
              }

              &.twitter {
                &:before {
                  content: "\f099" !important;
                  font-family: "Font Awesome 5 Brands";
                  font-weight: 900;
                }
              }
              &.facebook {
                &:before {
                  content: "\f38e" !important;
                  font-family: "Font Awesome 5 Brands";
                  font-weight: 900;
                }
              }
              &.linked {
                &:before {
                  content: "\f0e1" !important;
                  font-family: "Font Awesome 5 Brands";
                  font-weight: 900;
                }
              }
              &.website {
                &:before {
                  content: "\f0c1" !important;
                  font-family: "Font Awesome 5 Free";
                  font-weight: 900;
                }
              }
            }
          }

          li {
            padding: 0 0 0 20px;
            background: url($PUBLIC_IMG_URL+"/icons/tick.png") left center no-repeat;
            background-size: 10px;
            line-height: 18px !important;
          }

          .side-info-show-more {
            font-weight: 100 !important;

            i {
              vertical-align: middle;
            }

            a {
                color: #D54347 !important;
            }

            a:hover {
              color: $colorBlack !important;
          }
        }
        }

        .oneitem {
          border-bottom: 1px solid $colorGrey;
          font-size: 12px;
          padding: 8px 0;

          p {
            line-height: 15px;
            display: table-cell;
            vertical-align: middle;
            height: 60px;

            a {
              //color: $colorBlack1;

              &:hover {
                color: $colorRed;
                text-decoration: none;
              }
            }

          }

          .image {
            float: left;
            width: 45px;
            height: 45px;
            border-radius: 4px;
            border: 1px solid $colorBorder;
            margin: 8px 10px 0 0;
            background-color: $colorWhite;
            background-size: contain !important;
            background-repeat: no-repeat;
            background-position: center center !important;

            h2 {
              color: $colorBlack;
              line-height: 45px;
              text-align: center;
              margin: 0;
              text-transform: uppercase;
            }
          }

          &.event {
            .image {
              background: $colorRed;
              border: none;
              text-align: center;

              span {
                color: $colorWhite;

                &:first-of-type {
                  display: block;
                  margin: 2px 0;
                  font-size: 14px;
                  line-height: 15px;
                  font-weight: 700;
                }

                &:last-of-type {
                  font-size: 28px;
                  line-height: 20px;
                  font-weight: 600;
                }
              }
            }
          }
        }

        a.seeall {
          font-weight: 300;
          color: $colorRed;
          display: block;
          text-align: center;
          line-height: 35px;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  .right-side:not(:last-child) {
    margin-bottom: 30px;
  }

  .right-side {

    & > div {
      &:first-of-type {
        margin-top: 0 !important;
      }
    }

    .newsContent {
      background: $colorWhite;
      margin: 30px 0;
      -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
      position: relative;
      h1 {
        margin: 0 0 10px !important;
        font-size: 23px;
        text-align: left;
      }

      p {
        font-size: 15px;

        a {
          color: $colorRed;

          &:hover {
            color: $colorBlack;
          }
        }
      }

      img {
        max-width: 100%;
        max-height: 100%;
        margin: 15px 0;
      }

      .line {
        height: 1px;
        border-bottom: 1px solid $colorBorder;
      }

      .newsSingle.row {
        margin: 0px -15px !important;
      }

      .newsSingle {
        margin-bottom: 15px;
        margin-top: 25px;

        .img {
          height: 100%;
          min-height: 130px;
          background-size: contain !important;
          background-repeat: no-repeat !important;
          border: 0.75px $colorBorder solid;
          border-radius: 5px;
          background-position: center;

          a {
            display: block;
            width: 100%;
            height: 100%;
          }
        }

        h2 {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 5px;

          &:hover {
            color: $colorRed;
          }
        }

        span {
          color: $colorGrey3;
          margin-bottom: 15px;
          display: inline-block;
          font-size: 14px;
        }
      }

      @media (max-width: 767px) {
        .newsSingle.row:last-of-type {
          margin-bottom: 5px !important;
        }

        .newsSingle {
          h2 {
            margin-top: 16px;
          }

          p {
            margin: 0;
          }
        }
      }
    }

    .jobsContent {
      margin-top: 30px;
      padding: 20px;
      position: relative;
      background: $colorWhite;
      -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);

      .jobFooter {
        background: #f8f8f8;
        position: absolute;
        bottom: -10px;
        height: 40px;
        border-bottom: 1px solid $colorBorder;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        width: 100%;
        margin-left: -20px;
        padding: 0 20px;
        line-height: 40px;
        border: 1px solid $colorBorder;
      }

      h1 {
        margin: 0 0 10px !important;
        font-size: 25px;
        text-align: left;
      }

      p {
        font-size: 12px;

        a {
          color: $colorRed;
        }

        .jobButtons {
          line-height: 40px;
          padding: 0 15px;
          border-left: 1px solid $colorBorder;
          float: right;
          font-weight: 700;

          &:hover {
            color: $colorBlack;
          }
        }

        .accept {
          color: $colorGreen !important;
        }

        .reject {
          color: $colorRed !important;
        }
      }

      .line {
        height: 1px;
        border-bottom: 1px solid $colorBorder;
      }

      .jobSingle {
        padding: 10px 0;
        border-bottom: 1px solid $colorBorder;

        a {
          &:hover {
            text-decoration: none;
          }
        }

        .img {
          width: 80px;
          float: left;
          margin: 0 15px 15px 0;
          height: 80px;
          background-size: contain !important;
          background-repeat: no-repeat !important;
          background-position: center center !important;

          h2 {
            font-size: 25px;
            line-height: 80px;
            text-align: center;
            width: 100%;
          }
        }

        h2,
        h1 {
          width: 60%;
          font-size: 16px;
          font-weight: 600;
          margin: 5px 0 5px;
          color: $colorBlack1;
        }

        .nameH2 {
          margin: 0;
          line-height: 65px;
          font-size: 25px;
          width: 100%;
        }

        p {
          margin: 0 0 10px;
          width: 100%;
        }

        .info {
          float: left;
          width: 100%;

          div {
            display: inline-block;
            margin-right: 10px;
            padding-left: 20px;
            line-height: 20px;
            background-size: 15px !important;

            &.ticks {
              background: url($PUBLIC_IMG_URL+"/icons/tick.png") left center no-repeat;
            }
            &.location {
              background: url($PUBLIC_IMG_URL+"/location.png") left center no-repeat;
              background-size: 13px !important;
            }
            &.time {
              font-size: 14px;
              background: url($PUBLIC_IMG_URL+"/icons/calendar.png") left center no-repeat;
            }
            &.deadline {
              font-size: 14px;
              background: url($PUBLIC_IMG_URL+"/icons/calendar.png") left center no-repeat;
            }
          }

          span {
            color: $colorGrey3;
          }
        }

        .costs {
          width: 150px;
          float: right;
          text-align: right;

          span {
            display: block;

            &:first-of-type {
              font-size: 25px;
              color: $colorBlack;
            }

            &:last-of-type {
              font-size: 16px;
              color: $colorBlack;
              margin-bottom: 20px;
            }
          }

          a {
            border: 1px solid $colorRed;
            color: $colorRed;
            font-size: 12px;
            padding: 5px 35px;
            border-radius: 4px;
            text-transform: uppercase;
          }
        }
      }

      .mainContent {
        padding: 15px 0;
        border-bottom: 1px solid $colorBorder;

        &:last-of-type {
          border-bottom: none;
        }

        .left {
          width: 25%;
          float: left;

          h3 {
            font-size: 14px;
            text-transform: uppercase;
            color: $colorGrey4;
            font-weight: 600;
            margin: 0;
          }

          a {
            .img {
              width: 80px;
              margin: 0px 15px 15px 0;
              height: 80px;
              background-size: contain !important;
              background-repeat: no-repeat !important;
              background-position: center center !important;
            }

            h2 {
              font-size: 16px;
              font-weight: 600;
              margin: 25px 0 5px;
              color: $colorBlack1;
            }

            &:hover {
              h2 {
                color: $colorRed;
              }
            }
          }
        }

        .right {
          width: 74%;
          float: left;

          p {
            font-size: 14px;
            margin-bottom: 25px;
            overflow: hidden;
          }

          .links {
            margin-bottom: 25px;
            font-size: 14px;

            a {
              color: $colorBlack;
              margin-right: 10px;

              &:before {
                padding: 8px;
                content: "";
                font-family: $fontAwesome;
              }

              &:hover {
                color: $colorRed;
                text-decoration: none;
              }

              &.facebook {
                &:before {
                  content: "\f39e" !important;
                  font-family: "Font Awesome 5 Brands";
                  font-weight: 900;
                }
              }

              &.twitter {
                &:before {
                  content: "\f099" !important;
                  font-family: "Font Awesome 5 Brands";
                  font-weight: 900;
                }
              }

              &.linked {
                &:before {
                  content: "\f0e1" !important;
                  font-family: "Font Awesome 5 Brands";
                  font-weight: 900;
                }
              }

              &.link {
                &:before {
                  content: "\f0c1" !important;
                  font-family: "Font Awesome 5 Free";
                  font-weight: 900;
                }
              }
            }
          }

          .applyButton {
            font-size: 14px;
            padding: 5px 40px;
            background: $colorRed;
            border-radius: 4px;
            color: $colorWhite;
          }
        }
      }

      .seeall {
        margin-top: 25px;
        background: none;
        color: $colorRed;

        &:hover {
          background: none;
          color: $colorBlack;
        }
      }
    }

    .block {
      padding: 10px 20px 30px;
      background: $colorWhite;

      &:not(.questions) {
        margin: 30px 0;
      }

      &:first-of-type {
        margin: 0 10px 30px 0;
      }

      .title {
        h2 {
          font-size: 18px;
          font-weight: 400;
          margin-bottom: 20px;
        }
      }

      .oneitem {
        border-bottom: 1px solid $colorBorder;

        .img {
          width: 200px;
          float: left;
          margin: 0 15px 15px 0;
          height: 130px;
          background-size: contain !important;
          background-repeat: no-repeat !important;
          background-position: center center !important;

          a {
            display: block;
            width: 100%;
            height: 100%;
          }
        }

        h3 {
          font-size: 18px;
          font-weight: 600;
          margin: 5px 0 15px;
          color: $colorBlack1;

          &:hover {
            color: $colorRed;
          }
        }

        p {
          margin: 0 0 20px;
          color: $colorBlack;
        }

        span {
          color: $colorGrey3;
        }
      }
    }

    .seeall {
      color: $colorRed;
      display: table;
      margin: 20px auto 10px;
    }

    .questions {
      h3 {
        font-size: 24px;
        color: $colorBlack;
      }

      p {
        color: $colorBlack1;
        border-bottom: 1px solid $colorBorder;
        padding: 0 0 20px;

        &:last-of-type {
          border-bottom: 0;
        }
      }
    }
    .team,
    .investors,
    .mainContent .services {
      margin: 30px 0;
      padding: 10px;

      div {
        margin: 10px 0;
        padding: 0;
        // &:not(.profile-section-box):not(.section-heading):not(.locked-inline-section):not(.paywall-label-header):not(.paywall-label-message) {
        //     margin: 10px 0;
        //     padding: 0;
        // }

        div {
          overflow: hidden;
          height: 85px;
        }

        .image {
          width: 70px;
          height: 70px;
          float: left;
          border: 1px solid $colorBorder;
          border-radius: 4px;
          margin: 0 10px !important;
          background-size: contain !important;
          background-repeat: no-repeat !important;
          background-position: center center !important;
          margin-right: 14px !important;

          h2 {
            text-align: center;
            color: $colorRed;
            padding-top: 15px;
          }
        }

		  .imagePeople,
		  .peopleImage,
		  .peopleProfileImage {
			  width: 70px;
			  height: 70px;
			  float: left;
			  border: 1px solid $colorBorder;
			  border-radius: 4px;
			  margin: 0 10px !important;
			  background-repeat: no-repeat !important;
			  background-position: center center !important;
		  }

		  .imagePeople{
			  background-size: 45px !important;
		  }

		  .person-education, .person-experience {
				h3 {
					pointer-events: none !important;
					margin-bottom: 6px !important;
				}
			}

		  .peopleImage {
				margin-left: 0 !important;
				background-size: 45px
			}

			.peopleProfileImage {
				margin-right: 15px !important;
				margin-left: 0 !important;
				background-size: 45px ;
			}

        h3 {
          font-size: 16px;
          font-weight: 600;
          color: $colorBlack;
          margin: 8px 0 0 0;

          &:hover {
            color: $colorRed;
          }
        }

        p {
          font-size: 14px;
          margin: 0px !important;
          color: $colorGrey4;
        }
      }
    }

    .team {
        padding: 30px;
        margin-right: 0 !important;

        h2 {
            margin-bottom: 0 !important;
        }

        .row {
            margin: 0 -15px;
        }

        [class^=col] {
            margin: 0;
            padding-right: 15px;
            padding-left: 15px;
            margin-top: 25px;
        }

        .title.d-flex {
            margin: 0;
        }

        div .image {
            margin-left: 0 !important;
        }

        div div {
            height: unset;
        }
		  div p {
				margin-top: 3px !important;
		  }
    }

    .fundingrounds {
        background: $colorWhite;
        padding: 30px;
        margin-bottom: 30px;

        @media (max-width: 600px) {
            margin-bottom: 15px;
        }

		.verified-badge,
		.fa-check {
		  color: $colorRed !important;
		}

    .verified-badge, .pending-badge {
      font-size: 13px;
      font-weight: 400;

      i {
        font-weight: 600;
      }
    }

    .pending-badge {
      color: $colorBlack !important;

      .fa-check {
        color: $colorBlack !important;
      }
    }

      .funding-filters {
        margin-bottom: 10px;
        padding: 0;

        .filter-row {
          display: flex;
          margin-bottom: 10px;

          @media (max-width: 600px) {
            display: block;
          }
        }

        .filter-field {
          padding-left: 0;

          @media (max-width: 600px) {
            margin-bottom: 10px;
          }
        }

        .filter-clear-btn {
          display: flex;
          justify-content: flex-end;
          margin-right: 15px;
          margin-top: 15px;
        }

        input {
          border: 1px solid #cccccc;
          padding: 5px 15px 5px 7px;
        }
      }

      .nestedCell {
        padding: 0;

        div {
          padding: 5px 12px;
        }

        div:not(:last-child) {
          border-bottom: 1px solid $colorBorder;
        }
      }

      .row:not(.cboth) {
        margin: 0 !important;
        padding: 0 !important;
      }

      h3 {
        font-size: 16px;
        font-weight: 400;

        span {
          color: $colorRed;
        }
      }

      .onefunding {
        margin-top: 30px;

        & > div {
            background: $colorWhite2;
            height: 100px;
            overflow-y: auto;
            display: flex;
            padding: 10px;
            justify-content: center;

            p {
                text-align: center;
                color: $colorRed;
                font-weight: 600;
                width: 100%;
                justify-content: center;
                display: flex;
                flex-direction: column;

                span {
                    width: 100%;
                    font-weight: 600;
                    color: $colorBlack;
                    display: block;
                    font-weight: 400;
                }
            }
        }
      }
    }

    table {
      width: 100%;
      margin: 20px auto 0;

      tr {
        background: $colorWhite;

        &:first-of-type {
          td {
            font-weight: 600;
          }
        }

        td {
            border: 1px solid $colorBorder;
            vertical-align: middle;
            line-height: 20px;
            text-align: left;
            font-size: 14px;

            &.fundingTableCell.nestedCell {
                padding: 0;

                .investorLine {
                    padding: 10px;
                }
            }

            a {
                color: $colorRed;
            }

            .line {
                width: calc(100% + 30px);
                border-top: 1px solid $colorBorder;
                height: 1px;
                margin: 5px -15px;
            }
        }
      }
        .row-bg-gray {
          background: $colorWhite1 !important;
        }
    }

    .jobs {
      .job {
        border-bottom: 1px solid $colorBorder;
        padding-bottom: 10px;
        margin: 10px 0;

        .img {
          float: left;
          width: 80px;
          height: 80px;
          display: table-cell;
          vertical-align: middle;
          background-size: contain !important;
          background-position: center center !important;
          background-repeat: no-repeat !important;
          margin: 0 20px 20px 0;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }

        .content {
          width: 50%;
          float: left;

          h3 {
            font-size: 14px;
            font-weight: 600;
            margin: 5px 0 15px;
            color: $colorBlack;

            &:hover {
              color: $colorRed;
              text-decoration: underline;
            }
          }

          p {
            font-size: 12px;
          }

          .info {
            span {
              color: $colorGrey4;
              margin-right: 20px;
              line-height: 15px;

              &:before {
                display: inline-block;
                width: 15px;
                height: 15px;
                margin-right: 5px;
                background-size: contain !important;
                content: " ";
              }

              &.time {
                &:before {
                  background: url($PUBLIC_IMG_URL+"/icons/tick.png") left bottom no-repeat;
                }
              }

              &.location {
                &:before {
                  background: url($PUBLIC_IMG_URL+"/location.png") left bottom no-repeat;
                }
              }

              &.ago {
                &:before {
                  background: url($PUBLIC_IMG_URL+"/icons/calendar.png") left bottom no-repeat;
                }
              }

              &.deadline {
                &:before {
                  background: url($PUBLIC_IMG_URL+"/icons/calendar.png") left bottom no-repeat;
                }
              }
            }
          }
        }

        .right {
          width: 200px;
          float: right;
          text-align: right;

          h4 {
            font-size: 16px;
            font-weight: 400;
            color: $colorRed;
            margin: 0;
          }

          p {
            color: $colorBlack1;
            margin: 5px 0 15px;
          }

          a {
            margin: 10px 0;
            padding: 5px 35px;
            color: $colorRed;
            font-size: 12px;
            font-weight: 600;
            border: 1px solid $colorRed;
            border-radius: 4px;

            &:hover {
              border: 1px solid $colorBlack;
              color: $colorBlack;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

.accountTitle {
  border: 1px solid $colorBorder;
  background: $colorWhite;
  padding: 10px;
  margin-bottom: 30px;

  h1 {
    font-size: 16px;
    color: $colorBlack;
    margin: 0;
    padding: 0;
    font-weight: 600;
  }

  span {
    color: $colorGrey4;
    font-size: 14px;
    display: block;
    margin-top: 10px;
  }

  .line {
    height: 1px;
    border-bottom: 1px solid $colorBorder;
  }
}

.accountSettings {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  border: 0;
  background-color: none;
  margin-bottom: 30px;
  max-width: 370px;

  @media (max-width: 600px) {
      max-width: unset;
  }

  .row {
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 100%;
    
    .block {
      padding: 0;
      width: 100%;

      .info {
        height: 185px;
        border: 1px solid $colorBorder;
        background: $colorWhite;
        text-align: center;
        border-radius: 5px;

        a {
          &:hover {
            color: $colorRed;
          }
        }

        img {
          margin-top: 40px;
          max-width: 60px;
          max-height: 50px;
        }

        h3 {
          padding: 25px 50px 0;
          font-size: 14px;
          font-weight: 600;
          margin: 0;

          &:hover {
            color: $colorRed;
          }
        }
      }
    }

    .doubleBlock {

      .first,
      .second {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 50%;
        font-size: 16px;
      }

      .first {
        background: $colorRed;
        color: $colorWhite;
        outline: 1px solid $colorRed;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        h2 {
          color: $colorWhite;
          font-size: 30px;
          margin: 0;
          font-weight: 300;
        }
      }

      .second {
        h2 {
          margin: 0;
          font-size: 18px;
          font-weight: 600;
        }
      }
    }
  }

  .info.notification {
    i {
      color: #383b4c;
      font-size: 56px;
      margin-top: 40px;

      & ~ h3 {
        margin-top: -6px !important;
      }
    }
  }
}

.addTtitle {
  border-bottom: 1px solid $colorBorder;
  background: $colorWhite;
  padding: 10px;
  border-radius: 4px;

  .line {
    height: 1px;
    border-bottom: 1px solid $colorBorder;
  }

  h1 {
    font-size: 20px;
    color: $colorBlack;
    margin: 0;
    padding: 0;
    //font-weight: 600;
  }

  span {
    color: $colorGrey4;
    font-size: 14px;
    line-height: 0;
  }

  .progressBlock {
    margin-top: -30px;
    width: 100%;

    .progressRound {
      width: auto;
      text-align: center;
      color: $colorBlack;
      float: left;
      /*margin-right: calc((100% - 4*140px)/3);*/
      position: relative;
      width: 15%;
      z-index: 7;

      &:last-of-type {
        margin-right: 0;
      }

      div {
        background: $colorGrey;
        border-radius: 30px;
        line-height: 60px;
        font-size: 18px;
        font-weight: 600;
        margin: 0;
        padding: 0;
        margin: 0 auto;
        width: 60px;
        height: 60px;
      }

      p {
        margin: 20px 0;
      }

      &.active {
        div {
          color: $colorWhite;
          background: $colorRed;
        }

        color: $colorRed;
      }

      &.complete {
        div {
          background: #8bc53f;
          color: $colorWhite;
        }
      }
    }
  }

  .progressLine {
    width: calc(100% - 140px);
    margin: 68px auto 0;
    height: 4px;
    background: $colorGrey;
    z-index: 5;
    position: relative;
  }


  .progressLineActive {
    margin: -4px 0 0 70px;
    max-width: calc(100% - 140px);
    width: 0;
    height: 4px;
    background: $colorRed;
    z-index: 6;
    position: relative;
  }
}

.funding-round {
  width: 60vw;
  min-height: 300px;
  height: auto;
  margin-left: -30vw;
  margin-top: -300px;

  .title {
    border-bottom: 1px solid $colorBorder;

    h3 {
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 600;
      padding: 10px 0 19px;
    }
  }

  .buttons {
    margin-top: 15px;
    margin-bottom: 15px;

    a {
      width: 42%;
      margin: 0 7%;

      &:first-of-type {
        margin-right: 1%;
      }

      &:last-of-type {
        margin-left: 1%;
      }
    }
  }
}

.funding {
  padding: 25px 30px 20px;
  text-align: left;
  height: auto;

  label {
    width: 90%;
    margin: 0px 5%;
    font-size: 14px;
  }

  .important {
    text-align: center;

    &:before {
      content: "\f06a";
      display: inline-block;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      font-size: 50px;
      color: $colorRed;
    }
  }

  p.delete {
    text-align: center;
  }

  input,
  select {
    width: 90% !important;
    margin: 0 5%;
    height: 35px;
    border-radius: 4px;
    padding: 5px 10px;
  }

  input[type="checkbox"] {
    width: 20px !important;
    margin: 0 !important;
  }

  select {
    padding: 0;
  }
}

.addBlock {
  padding: 10px 10px 0px;
  border: 1px solid $colorBorder;
  background: $colorWhite;

  h1,
  h2,
  h3,
  h4 {
    margin: 0;
  }

  ul.preload {
    border: 1px solid $colorBorder;
    position: absolute;
    top: 60px;
    width: 100% !important;
    margin: 0 !important;
    background: $colorWhite;
    white-space: normal;
    z-index: 20;
    text-align: left !important;
    text-transform: none !important;

    li {
      border-bottom: 1px solid $colorBorder;
      width: 100% !important;
      padding: 3px 5%;

      a {
        color: $colorRed;
      }
    }

    &.preload90 {
      top: 90px !important;
    }
  }

  .funding-invite-block {
    border: 1px solid $colorBorder;
    position: absolute;
    top: 60px;
    background: $colorWhite;
    white-space: normal;
    z-index: 20;
    text-align: left;
    text-transform: none;
    margin-left: 15px;
    margin-right: 30px;
    text-transform: none;

    &.investor-block {
      margin: 0 5%;
    }

    .text-card {
      height: 85px;
      padding: 10px;
      font-size: 13px;
    }
  }

    .addTeam,
    .addRound,
    .showInvestor {
        width: 90%;
        display: block;
        border-radius: 4px;
        text-align: center !important;
        color: $colorWhite !important;
        background: $colorRed;

        &:hover {
            background: $colorBlack1;
        }
    }

    .editButton,
    .removeButton {
        color: #818a91 !important;

        &:hover {
            color: $colorRed !important;
        }
    }

    .investorLine {
        &:not(:first-child) {
            border-top: 1px solid $colorBorder;
        }

        span {
            color: $colorRed;
        }
    }

    .editButton,
    .removeButton {
      display: inline-block;
      font-family: FontAwesome;
      font-size: 17px;
      text-rendering: auto;
      margin: 0 5px;
      -webkit-font-smoothing: antialiased;
      text-decoration: none;

      &:before {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
    }
    }

    .editButton {
        &:before {
            content: "\f303";
        }
    }

    .removeButton {
        &:before {
            content: "\f1f8";
        }
    }

  .mainContent .right {
    .usersBlock {
      width: 96%;
      margin: 20px;

      & > div {
        width: 100%;
        margin: 10px 0;
        border: 1px solid $colorBorder;
        border-radius: 4px;

        .contentBlock {
          padding: 0px;
          margin: 0;
        }

        .logo {
          width: 60px;
          height: 60px;
          float: left;
          border: 1px solid $colorBorder;
          background-position: center center;
          margin: 10px;
          margin-left: 10px !important;
          margin-right: 10px !important;

          h2 {
            text-align: center;
            line-height: 60px;
          }
        }

        h3 {
          padding: 10px;
          font-size: 16px;
          font-weight: bold;
        }

        span {
          font-size: 15px;
        }

        .bottomTeam {
          height: 40px;
          border-top: 1px solid $colorBorder;
          background: #f8f8f8;

          a {
            color: #8b9399;
            font-size: 16px;
            float: right;
            padding: 0 20px;
            line-height: 40px;
            text-decoration: none;

            &:before {
              display: inline-block;
              font-family: FontAwesome;
              font-size: 16px;
              line-height: 40px;
              font-size: inherit;
              text-rendering: auto;
              margin: 0 5px;
              -webkit-font-smoothing: antialiased;
            }

            &:first-of-type {
              border-left: 1px solid $colorBorder;

              &:before {
                content: "\f1f8";
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
              }
            }

            &:last-of-type {
              &:before {
                content: "\f040";
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
              }
            }

            &:hover {
              color: $colorRed;
            }
          }
        }
      }
    }
  }

  table {
    width: calc(100% - 30px) !important;

    tr {
      max-width: 100%;
      border: 1px solid $colorBorder !important;

      &:nth-child(even) {
        background: #f8f8f8;
      }

      td,
      th {
        min-width: auto !important;
        //border: 0px !important;
        border: 1px solid $colorBorder;
        width: auto !important;
        line-height: 20px;
        padding: 10px;
        font-size: 14px;

        &:nth-child(6) {
          border-left: 1px solid $colorBorder !important;
        }

        &:last-of-type {
          border-left: 1px solid $colorBorder !important;

          .addButton {
            margin: 0;
            float: none;
          }

          .req {
            color: $colorRed;
            position: relative;

            img {
              max-width: 15px;
              margin: 0 5px;
              cursor: pointer;

              &:hover {
                & + .message {
                  display: block !important;
                }
              }
            }

            .message {
              background: #222 none repeat scroll 0% 0%;
              color: $colorWhite !important;
              border: 0px solid $colorWhite;
              border-radius: 4px;
              font-size: 12px !important;
              line-height: 1.5;
              font-family: "helvetica neue", arial, helvetica, freesans, "liberation sans", "numbus sans l", sans-serif !important;
              max-width: 200px;
              font-weight: 400;
              padding: 10px;
              white-space: normal;
              position: absolute;
              min-width: 200px;
              top: -8px;
              right: -210px;
              z-index: 20;
              display: none;
              text-align: left !important;
              text-transform: none !important;

              &:before {
                width: 0px;
                height: 0px;
                border-style: solid;
                border-width: 10px 10px 10px 0;
                border-color: transparent #222 transparent transparent;
                left: -10px;
                top: 9px;
                content: "";
                font-size: 0px;
                position: absolute;
              }
            }
          }
        }

        input {
          width: 100%;
        }
      }
    }
  }

  .line {
    height: 1px;
    width: 100%;
    border-bottom: 1px solid $colorBorder;
  }

  .createJob {
    float: left !important;
  }

  .createJob + p {
    margin: 30px 0 0 10px;
    float: left;

    a {
      color: $colorRed;
    }
  }

  .createJob {
  }

  .mainContent {
    padding: 15px 0;
    border-bottom: 1px solid $colorBorder;

    .create {
      width: 115%;
      color: $colorRed;
      text-align: center;
      display: block;
      line-height: 30px;
      border: 2px solid $colorRed;
      border-radius: 4px;
      margin: 30px 0 0;
    }

    &:last-of-type {
      /*border-bottom: none;*/
    }

    .left {
      //width: 20%;
      display: inline-block;
      float: left;
      min-height: 20px;

      h3 {
        font-size: 14px;
        text-transform: uppercase;
        color: $colorGrey4;
        font-weight: 600;
        margin: 0;
      }

      p {
        font-size: 12px;
        margin: 10px 0;
        color: $colorBlack;
      }
    }

    label {
      font-size: 13px;
    }

    textarea {
      width: 100%;
      //height: 115px;
      padding: 5px 15px;
      border: 1px solid $colorBorder;
      border-radius: 4px;
      //line-height: 20px;
      margin-bottom: 30px;
    }
    select {
      width: 100%;
      border: 1px solid #e6e6e6;
      background: $colorWhite;
      border-radius: 4px;
      height: 32px;
      font-size: 12px;
      margin-bottom: 20px;

      &.dateSelect {
        width: 48%;
        margin-right: 4%;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    .iLinkedin,
    .iTwitter {
      i {
        position: absolute;
        z-index: 10;
        top: 7px;
        left: 25px;
      }
    }

    .iFacebook,
    .iWebsite,
    .iApple,
    .iGooglePlay {
      i {
        position: absolute;
        z-index: 10;
        top: 9px;
        left: 25px;
      }
    }

    input {
      width: 100%;
      padding: 5px 15px;
      border: 1px solid $colorBorder;
      border-radius: 4px;
      line-height: 20px;
      font-size: 12px;
      margin-bottom: 20px;

      &.linkedin,
      &.facebook,
      &.twitter,
      &.website,
      &.ios-app-url,
      &.android-app-url,
      &.skype {
        padding: 5px 15px 5px 30px;
      }
    }

    .ticketsize-input {
      width: 200%;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      z-index: 99;
    }

    .ticketsize-select {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      // padding-left: 180px;
      position: relative;
      left: -3px;
    }

    .iCalendar {
      position: absolute;
      font-size: 18px;
      left: 25px;
      bottom: 26px;
      z-index: 10;
    }

    .hideButton {
      margin: 0 20px 20px 0;
      line-height: 20px;
      color: $colorBlack;
      float: right;

      &:before {
        content: "\f056";
        display: inline-block;
        font-size: 20px;
        margin: 0 5px;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
      }
    }

    .addInvestor {
      margin: 0 20px 20px 0;
      line-height: 20px;
      color: $colorRed;
      float: right;
    }

    .addButton {
      margin: 0 20px 20px 0;
      line-height: 20px;
      color: $colorBlack;
      float: right;

      &:before {
        content: "\f055";
        display: inline-block;
        font-size: 20px;
        margin: 0 5px;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
      }
    }

    .loadingMessage {
      margin: 0 20px 20px 0;
      line-height: 20px;
      color: $colorBlack;
      float: right;

      i {
        margin-right: 3px
      }
    }

    .fundintText {
      margin: 20px 0;

      a {
        color: $colorRed;
      }
    }

    .right {
      //width: 80%;
      width: 100%;
      display: inline-block;
      padding: 0 0 20px;
      position: relative;

      .addTeam {
        //margin: 30px 0 0 0;
        margin: 10px 0 0 0;
        padding: 5px 25px;
        width: 100%;
        margin-bottom: 20px;
      }

      .addButton60 {
        margin: 60px 0 0 0;
      }

      .addMore {
        color: $colorBlack;
        margin-left: 15px;

        img {
          max-width: 20px;
        }
      }

      .row,
      .row div:not(.item-body):not(.item-img) {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }

      .row > div {
        width: 96%;
        //margin: 0 2% !important;
      }

      .row > div.col-xs-6,
      .row > div.col-sm-6,
      .row > div.col-md-6 {
        width: 46%;
      }

      .row > div.col-xs-4,
      .row > div.col-sm-4,
      .row > div.col-md-4 {
        width: 29.3%;
      }

      .row > div.col-xs-3,
      .row > div.col-sm-3,
      .row > div.col-md-3 {
        width: 21%;
      }

      label {
        width: 100%;
        font-weight: normal;
        margin: 0;
        line-height: 19px;
        padding-top: 13px;

        span.characters {
          float: right;
        }

        .req {
          color: $colorRed;
          position: relative;

          img {
            max-width: 15px;
            margin: 0 5px;
            cursor: pointer;

            &:hover {
              & + .message {
                display: block !important;
              }
            }
          }

          .message {
            background: #222 none repeat scroll 0% 0%;
            color: $colorWhite !important;
            border: 0px solid $colorWhite;
            border-radius: 4px;
            font-size: 12px !important;
            line-height: 1.5;
            font-family: "helvetica neue", arial, helvetica, freesans, "liberation sans", "numbus sans l", sans-serif !important;
            max-width: 200px;
            font-weight: 400;
            padding: 10px;
            white-space: normal;
            position: absolute;
            min-width: 200px;
            top: -8px;
            right: -210px;
            z-index: 20;
            display: none;
            text-align: left !important;
            text-transform: none !important;

            &:before {
              width: 0px;
              height: 0px;
              border-style: solid;
              border-width: 10px 10px 10px 0;
              border-color: transparent #222 transparent transparent;
              left: -10px;
              top: 9px;
              content: "";
              font-size: 0px;
              position: absolute;
            }
          }
        }
      }

      .multi {
        li {
          width: auto;
          display: inline;
          background: $colorRed;
          padding: 3px 10px;
          color: $colorWhite;
          margin: 5px;
          cursor: pointer;
          font-size: 12px;
          border-radius: 15px;
        }
      }

      .fileupload {
        width: 100% !important;
        border-radius: 4px;
        cursor: pointer;
        min-height: 30px;
        overflow: hidden;
        position: relative;
        text-align: left;

        .selectbutton {
          background-color: $colorGrey4;
          border-left: 1px solid $colorBorder;
          color: $colorWhite;
          position: absolute;
          right: 0;
          font-size: 12px;
          height: 30px;
          line-height: 30px;
          overflow: hidden;
          text-align: center;
          width: 100px;

          &:hover {
            background: $colorBlack1;
          }
        }

        input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          -moz-opacity: 0;
          filter: alpha(opacity=0);
          opacity: 0;
          font-size: 150px;
          height: 30px;
          z-index: 20;
        }

        .fileformlabel {
          float: left;
          height: 30px;
          line-height: 30px;
          width: 100%;
          border: 1px solid $colorBorder;
          overflow: hidden;
          padding: 0 5px;
          text-align: left;
          vertical-align: middle;
          position: absolute;
          border-radius: 4px;
        }
      }

      ul.checkboxes {
        width: 96%;
        margin: 0 2%;

        li {
          width: 33%;
          display: inline-block;
          position: relative;

          input {
            cursor: pointer;
            position: absolute;
            opacity: 0;
            width: 100%;
            height: 30px;
            margin-bottom: 0;
            left: 0;
            top: 0;

            & + label:before {
              content: " ";
              display: block;
              float: left;
              width: 20px;
              height: 30px;
              margin: 0 10px 0 10px;
              background-size: contain !important;
            }

            &:checked {
              & + label:before {
                background: url($PUBLIC_IMG_URL+"/icons/ticked.png") center center no-repeat;
              }
            }

            &:not(checked) {
              & + label:before {
                background: url($PUBLIC_IMG_URL+"/icons/checkbox.png") center center no-repeat;
              }
            }
          }

          label {
            padding-left: 0 !important;
          }
        }
      }

      p {
        font-size: 16px;
        margin-bottom: 25px;

        &.errorsContainer {
          color: $colorRed;
          margin: 20px 0 0 10px;
        }
      }

      p.or {
        font-size: 14px;
        top: 35px;
        //width: 100%;
        position: relative;
        z-index: 99;
        //text-align: center;
        //left: 412px;
      }
    }
  }

  .nextStep {
    background: $colorRed;
    border-radius: 4px;
    padding: 5px 30px;
    color: $colorWhite;
    margin: 30px 0 0;
    float: right;
    font-size: 14px;

    &:hover {
      background: $colorBlack1;
      text-decoration: none;
    }
  }

  .prevStep {
    background: $colorGrey3;
    border-radius: 4px;
    padding: 5px 30px;
    color: $colorWhite;
    margin: 30px 10px 0;
    float: right;

    &:hover {
      background: $colorRed;
      text-decoration: none;
    }
  }
}

.funding-modal-error {
    color: $colorRed;
    font-size: 13px;
    margin-top: 10px;
    margin-left: 16px;
}

.noIcon {
  &:before {
    display: none !important;
  }
}

#root {
  background: $colorWhite1;
  padding: 0 0 0px 0;
  //padding: 0 0 30px 0;
  overflow-x: hidden;
  overflow-y: hidden;
}

.jobDashBoardBlock {
  min-height: 400px;
  width: 100%;
  border: 1px solid $colorBorder;
  border-radius: 4px;
  background: $colorWhite;
  padding: 10px;
  margin: 0 0 30px;
  box-shadow: 0 0px 1px 0px rgba(0, 0, 0, 0.1);

  .title {
    margin: 0 10px;
    padding: 5px 0;
    border-bottom: 1px solid $colorBorder;

    h3 {
      margin: 0;
      padding: 0;
      display: initial;
      font-size: 20px;
      text-transform: capitalize;
    }

    a {
      float: right;
      //margin: 0 10px;
      padding: 0;
      color: $colorGrey4;
      font-size: 16px;

      &:before {
        display: inline-block;
        font-family: FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        padding: 0 5px;
      }

      &:hover {
        color: $colorRed;
      }

      &.edit {
        &:before {
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          content: "\f044";
        }
      }

      &.delete {
        &:before {
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          content: "\f1f8";
        }
      }
    }
  }

  .previewInfo {
    margin: 20px 0;
    div {
      width: 30%;
      background: $colorWhite1;
      float: left;
      margin: 0 2.48%;
      height: 60px;
      text-align: center;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }

      h3 {
        font-size: 18px;
        font-weight: 600;
        margin: 5px 0;
        padding: 0;
      }

      p {
        font-size: 18px;
      }
    }
  }
}


.manageJobsPagenation {
  border: 1px solid $colorBorder;
  border-radius: 4px;
  background: $colorWhite;
  padding: 10px;
  box-shadow: 0 0px 1px 0px rgba(0, 0, 0, 0.1);
  font-size: 13px;
  user-select: none;
  button {
    border: none;
    background: $colorWhite;
  }
  select {
    cursor: pointer;
    height: 24px;
    min-width: 24px;
    user-select: none;
    padding-left: 8px;
    padding-right: 12px;
    box-sizing: content-box;
    font-size: inherit;
    color: inherit;
    border: none;
    background-color: transparent;
    appearance: none;
    direction: ltr;
  }
  .dropIcon {
    left: 50%;
  }
}

@media only screen and (max-width: 620px) {
  .manageJobsPagenation {
    white-space: nowrap;
    justify-content: center !important;
    #pagination-first-page,
    #pagination-last-page {
      display: none;
    }
    .pagenationIndicator {
      margin: 0 0.8rem !important;
    }
  }
}

.addBlock .mainContent .right {
  .addAdditional {
    padding: 20px;
    margin: 10px 0;
    border-bottom: 1px solid $colorBorder;
    background: #f8f8f8;

    .row {
      & > div {
        background: $colorWhite;
        border: 1px solid $colorBorder;
        margin: 5px 2% !important;
        line-height: 30px;
        padding: 0 10px !important;

        a {
          color: $colorBlack;
          padding: 0 0 0 25px;
          background-size: 18px !important;

          &.appSingleLine {
            background: url($PUBLIC_IMG_URL+"/appicons/single.png") left center no-repeat;
          }
          &.appMultipleLine {
            background: url($PUBLIC_IMG_URL+"/appicons/textarea.png") left center no-repeat;
          }
          &.appDropdown {
            background: url($PUBLIC_IMG_URL+"/appicons/dropdown.png") left center no-repeat;
          }
          &.appRadio {
            background: url($PUBLIC_IMG_URL+"/appicons/redio.png") left center no-repeat;
          }
          &.appCheckboxes {
            background: url($PUBLIC_IMG_URL+"/appicons/checkbox.png") left center no-repeat;
            background-size: 25px !important;
            padding: 0 0 0 30px;
            margin: 0 0 0 -5px;
          }
          &.appDate {
            background: url($PUBLIC_IMG_URL+"/appicons/date.png") left center no-repeat;
          }
        }
      }
    }
  }
}

.gender-radio {
  display: inline-block;
  width: 100px;
  margin-top: -6px;

  input {
    cursor: pointer;
    position: absolute;
    opacity: 0;
    margin-bottom: 0px !important;
    width: 100px !important;
    height: 35px;

    & + label:before {
      content: " ";
      display: block;
      float: left;
      width: 18px;
      height: 18px;
      margin: 0 3px 0 10px;
      background-size: contain !important;
    }

    &:checked {
      & + label:before {
        background: url($PUBLIC_IMG_URL+"/icons/radio-checked.png") center center no-repeat;
      }
    }

    &:not(checked) {
      & + label:before {
        background: url($PUBLIC_IMG_URL+"/icons/radio-unchecked.png") center center no-repeat;
      }
    }
  }

  label {
    padding-left: 0 !important;
  }
}

.headerButtons {
  display: inline-block;
  width: 75px;
  float: right;
  padding: 0;
  font-size: 12px;
  line-height: 38px;
  text-align: center;
  color: $colorWhite;
  background: $colorBlack1;
  margin: 0px 0 0 10px;
  border-radius: 5px;

  &:hover {
    background: $colorRed;
    color: $colorWhite;
    text-decoration: none;
  }

  &:nth-child(odd) {
    background: $colorGrey3;
  }
}

.errorMessage {
  width: 100%;
  padding: 20px 0 0 0;
  text-align: right;
  color: $colorRed !important;
}

.errorMessage2 {
  width: 100%;
  padding: 36px 0 0 0;
  text-align: left;
  font-size: 12px !important;
  color: $colorRed !important;
}

.successMessage {
  padding: 36px 0 0 0;
  color: #82c91e !important;
}

textarea:disabled,
input:disabled,
select:disabled,
.fileupload.disabled {
  background: $colorGrey !important;
}

.searchResults {
  max-height: 60vh;
  overflow-y: scroll;
  padding: 0 10px;

  .oneitem {
    padding-bottom: 5px;
    border-bottom: 1px solid $colorBorder;

    a {
      color: $colorBlack;
      display: block;

      &:hover {
        color: $colorRed;
      }

      .logo {
        float: left;
        width: 45px;
        height: 45px;
        border-radius: 4px;
        border: 1px solid #d6d8da;
        margin: 5px 10px 0 0;
        background-color: $colorWhite;
        background-size: contain !important;
        background-repeat: no-repeat;
        background-position: center center !important;

        h2 {
          margin: 0;
          text-transform: uppercase;
          text-align: center;
          line-height: 45px;
        }
      }

      h2 {
        font-size: 14px;
        font-weight: bold;
        padding: 9px 0 0 0;
        height: 26px;
        margin: 0;
        overflow: hidden;
      }

      p {
        font-size: 12px;
        height: 15px;
        overflow: hidden;
      }
    }
  }
}

.searchMainResult {
  width: 80%;
  left: 10%;
  background: $colorWhite;
  max-height: 30vh;
  overflow-y: scroll;
  padding: 0 10px;
  position: absolute;

  .oneitem {
    .logo {
      float: left;
      width: 45px;
      height: 45px;
      border-radius: 4px;
      border: 1px solid #d6d8da;
      margin: 8px 10px 0 0;
      background-color: $colorWhite;
      background-size: contain !important;
      background-repeat: no-repeat;
      background-position: center center !important;

      h2 {
        margin: 0;
        text-transform: uppercase;
        text-align: center;
        line-height: 45px;
      }
    }

    a {
      color: $colorBlack;

      &:hover {
        color: $colorRed;
      }
    }
    h2 {
      font-size: 16px;
      font-weight: bold;
    }
  }
}

/*.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
width: 2.5rem !important;
line-height: 2.5rem !important;
}

.react-datepicker {
font-size: 1.3rem !important;
}

.react-datepicker__current-month, .react-datepicker-time__header {
font-size: 1.5rem !important;
}

.react-datepicker__navigation {
border: 0.85rem solid transparent !important;
}

.react-datepicker-popper {
z-index: 10 !important;
}

.react-datepicker-popper[data-placement^="top"] {
margin-bottom: 3px;
}

.react-datepicker-popper[data-placement^="bottom"] {
margin-top: 0px !important;
}

.react-datepicker__navigation--previous {
border-right-color: #b3b3b3 !important;
}

.react-datepicker__navigation--next {
border-left-color: #b3b3b3 !important;
}
.react-datepicker-wrapper {
width: 100%;

.react-datepicker__input-container {
width: 100%;
}
}

.react-datepicker__input-container input {
padding: 5px 35px !important;
}*/

/***************************** New Loader **************************/

.spin {
  margin: auto;
  height: 100%;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;

  .spinnerLogo {
    display: block;
    margin: auto;
  }
  .spinnerDot {
    width: 13px;
    height: 13px;
    background-color: $colorRed;
    display: inline-block;
    animation-name: loading;
    animation-duration: 1s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
    margin-top: 12px;
  }

  .spinnerDot2,
  .spinnerDot3 {
    margin-left: 18px;
  }

  .spinnerDot2 {
    animation-delay: 0.2s;
  }

  .spinnerDot3 {
    animation-delay: 0.4s;
  }

  @keyframes loading {
    10%,
    30%,
    38% {
      transform: translate3d(-1px, 0, 0);
      transform: rotate(5deg);
    }
    25%,
    35%,
    40% {
      transform: translate3d(1px, 0, 0);
      transform: rotate(-5deg);
    }

    40% {
      transform: translate3d(0px, -8px, 0);
    }

    100% {
      transform: translate3d(0px, 0px, 0);
    }
  }
}

.spinner {
  width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;
  //background: $colorWhite;
  text-align: center;
  padding: 150px;

  img {
    max-width: 75px !important;
  }
}

.mainSingleImage {
  width: 100%;
}

.errorsContainer {
  margin: 10px 0 20px 0 !important;
  font-size: 12px;
  color: $colorRed;
}

.applyBlock {
  padding: 40px;
  background: #f8f8f8;
  margin: 30px 0 30px;
}

.rtecenter {
  text-align: center;
}

.suggestionFilterList {
  width: 90%;
  position: absolute;
  margin: -5px 5%;
  background: $colorWhite;

  li {
    width: 90%;
    color: $colorRed;
    text-align: left;
    padding: 0 5%;
    line-height: 30px;
    border-bottom: 1px solid $colorBorder;

    a {
      color: $colorRed;
    }
  }
}

.collapse-btn {
  background: transparent;
  border: 0;
  padding: 0;
}

.declineReasonTextarea {
  width: 100% !important;
  margin: 0px !important;
}
.declineReasonLabel {
  width: 100% !important;
  margin: 5px 0px !important;
}

.urlValidationError {
  color: $colorRed !important;
}
.CustomErrorWrap {
  position: relative;
}
.CustomErrorWrap input {
  margin-bottom: 30px !important;
}
.CustomErrorWrap .urlValidationError {
  position: absolute;
  left: 0;
  bottom: 4px;
}

/*Loader*/
.loader {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: $colorRed;
  background: -moz-linear-gradient(left, $colorRed 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, $colorRed 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, $colorRed 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, $colorRed 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, $colorRed 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before {
  width: 50%;
  height: 50%;
  background: $colorRed;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}
.loader:after {
  background: $colorWhite;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.otherInputField {
  opacity: 1 !important;
  height: 100% !important;
  cursor: auto !important;
  margin-top: 7px !important;
  margin: 0 0 0 94px !important;
  top: 0;
  width: 85% !important;
}

.bgGrey {
  background: #f2f2f2 !important;
}
.hauto img {
  height: auto !important;
}
.overHidden {
  overflow: hidden;
}

.mbot15 {
  margin-bottom: 15px;
}
.interests {
  &:before {
    background: url($PUBLIC_IMG_URL+"/interest_icon.png") left center no-repeat;
  }
}
.progressSixSteps .progressBlock .progressRound {
  width: 110px;
}

.margBot10 {
  margin-bottom: 10px;
}

.redCircle:before {
  background: #b6101f !important;
}
.greenCircle:before {
  background: #37b751 !important;
}

.btnReportMenu {
  color: $colorWhite;
  display: inherit;
  width: auto;
  text-transform: none;
  font-size: 14px;
  display: inline-block;
  padding: 0.375rem 0.75rem;
  color: $colorWhite;
  border-radius: 4px;
  margin: 0 0 0 10px;
  text-decoration: none;
  text-align: center;
  &:first-child {
    margin-left: 0 !important;
  }
}

.btnRedMenu {
  background: $colorRed;

  &:hover {
    background: $colorBlack1;
    color: $colorWhite;
  }
}
.btnBlueMenu {
  background: $colorBlack1;

  &:hover {
    background: $colorRed;
    color: $colorWhite;
  }
}
.btnGreyMenu {
  background: $colorGrey3;
  &:hover {
    background: $colorBlack1;
    color: $colorWhite;
  }
}
.splashTag {
  background: #d31147;
  border-radius: 5px;
  font-size: 9px;
  padding: 2px 6px;
  margin: 0 0 0 7px;
  color: $colorWhite;
}

.fileUpload {
  padding-left: 0 !important;
}
.fileUpload:before {
  content: "\f016";
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 15px !important;
  font-size: inherit;
  text-rendering: auto;
  margin: 0 12px 0 3px;
  -webkit-font-smoothing: antialiased;
  color: #6f777d;
  left: 0;
  position: relative;
}

.fileUpload2 {
  padding-left: 0 !important;
}
.fileUpload2:before {
  content: "\f016";
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 15px !important;
  font-size: inherit;
  text-rendering: auto;
  margin: 0 12px 0 3px;
  -webkit-font-smoothing: antialiased;
  color: #6f777d;
  left: 0;
  position: relative;
  float: left;
}

.marg15 {
  margin: 0 15px !important;
}

.showmore {
  margin-top: 10px;
  width: 100%;
  a {
    color: $colorRed;
    font-size: 14px;

    &:hover {
      color: $colorBlack;
    }
  }
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

hr.cross {
  border: 1px solid #9296a9;
  border-radius: 1px;
  width: 7px;
  padding: 0;
  margin: auto;
}

.counters-main-wrap {
  width: 100%;
  float: left;
  text-align: center;
  margin: 0px 0;
  padding: 9px 1px;
  margin-left: 0;
}
.counters-main-wrap .col-md-4 {
  color: $colorRed;
  font-size: 15px;
  border-right: 1px solid $colorBorder;
  border: 1px solid $colorBorder;
  padding: 20px 15px;
  margin: 0 1% 0 0;
  width: 32.6%;
}
.counters-main-wrap .col-md-3 {
  color: $colorBlack;
  font-size: 15px;
  border-right: 1px solid $colorBorder;
  border: 1px solid $colorBorder;
  padding: 20px 30px;
  margin: 0 2% 5% 2%;
  width: 20.14256%;
}

.counters-main-wrap .col-md-3:nth-child(2) {
  color: $colorBlack;
  font-size: 15px;
  border-right: 1px solid $colorBorder;
  border: 1px solid $colorBorder;
  padding: 20px 30px;
  margin: 0 0% 5% 0;
  width: 20.14256%;
}

.counters-main-wrap .col-md-3:nth-child(4) {
  color: $colorBlack;
  font-size: 15px;
  border-right: 1px solid $colorBorder;
  border: 1px solid $colorBorder;
  padding: 20px 30px;
  margin: 0 0% 5% 0;
  width: 20.14256%;
}
.active-count {
  background: $colorRed;
  color: $colorWhite !important;
  padding: 0;
}

.non-active-count {
  color: $colorRed;
  background: #eff0f2;
}
.fa-stack {
  text-align: center;
  height: 1em;
}

.fa-stack .fa-sort-down {
  position: absolute;
  bottom: 0;
}

.fa-stack .fa-sort-up {
  position: absolute;
  top: 0;
}

.active-sort {
  color: $colorRed;
}

.startupTitle {
  padding: 0 15px;
  color: $colorRed !important;
}
.startupTitle:hover {
  color: $colorBlack !important;
}

.redStatusLine {
  border-left: 5px solid #b6101f;
}
.greenStatusLine {
  border-left: 5px solid #37b751;
}
.yellowStatusLine {
  border-left: 5px solid $colorYellow;
}

.activeTab {
  color: $colorRed;
}
.counters-main-wrap .col-md-4:last-child {
  margin-right: 0;
}
.counters-main-wrap span {
  font-size: 40px !important;
  clear: both;
  display: block;
  line-height: 36px;
}

.topMargin10 {
  margin-top: 10px !important;
}
.addBlock .mainContent .right .row > div.userCrudBlock {
  border-bottom: 1px solid $colorBorder;
  margin: 0 0 15px 0 !important;
  padding: 0 0 15px 0 !important;
  overflow: auto !important;
}
.addBlock .mainContent .right .row > div.userCrudBlock:last-child {
  border-bottom: 0 !important;
}

.content-block .right-side .mainContent .services .userCrudBlock .imagePeople {
  margin: 0 1.5% 0 0 !important;
}

.mbot5 .img {
  margin-bottom: 5px !important;
}

/**************** Mailchimp Popup CSS start *************/

.modalContent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 490px;
  overflow: hidden;
  background: $colorWhite;
  border-radius: 5px;
}

.modalContent__content {
  clear: both;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.content__button {
  margin-bottom: 20px;
  margin-top: 20px;
  height: 36px;

  .submitBtn {
    display: block;
    margin: auto;
  }
}
.content__formFields {
  margin-top: 20px;
}
.modalContent--mobile {
  width: 100%;
}

.modalContent--mobile .content__gdpr,
.modalContent--mobile .content__gdprLegal {
  margin-left: -22px;
  margin-right: -50px;
  padding-left: 22px;
}
.modalContent--mobile .content__gdprLegal img {
  float: none;
  display: block;
  margin: 0 auto 20px auto;
}

.popupEmailInput {
  background-color: $colorWhite;
  border: 2px solid #c9c9c9;
  border-radius: 3px;
  display: inline-block;
  margin: 0 0 24px;
  min-height: 36px;
  min-width: 50px;
  padding: 0 0.4em;
  vertical-align: middle;
  width: 100%;
}

.react-datepicker__navigation {
  z-index: 10 !important;
}

/**************** ManageSingleApplicationContainer ***************/

.ManageApplicationContainer {
  //#jobSingleCard
  //{
  //  border-bottom: none;
  //}
  .jobSingle {
    .manageAppHeadInfo {
      a h2 {
        padding: 0;
      }
      .col-xs-3 .img {
        position: relative;
        float: none;
        margin: 0 auto;
      }
      .manageAppStatupInfo {
        margin-bottom: 10px;
        margin-top: 10px;
        a h2,
        .info {
          width: 100%;
          text-align: left;
        }
        .costs {
          margin-right: 30px;
          font-size: 20px;
        }
      }
    }
    .manageAppFilterStats {
      .col-md-2 div {
        text-transform: uppercase;
        color: #767f84;
        margin-left: 0;
        padding-left: 0;
        padding-right: 8px;
        font-size: 10px;
        padding-top: 25px;
        text-align: center;
        margin-top: 5px;
      }
      .col-md-10 .counters-main-wrap {
        margin-top: 10px;
        .col-xs-6 {
          border: 10px solid $colorWhite;
          padding-top: 18px;
          padding-bottom: 15px;
          div {
            cursor: pointer;
          }
        }
        .non-active-count {
          color: #6c7175;
        }
      }
    }
  }
  .manageAppSelectFilter {
    div .row {
      margin-top: 22px;
      .col-md-2 div {
        text-align: center;
        text-transform: uppercase;
        cursor: #767f84;
        margin-left: 0;
        margin-right: 0;
        margin-top: 5px;
        margin-bottom: 15px;
        padding-right: 10px;
        font-size: 10px;
      }
      .col-md-10 .row {
        margin-top: 0;
        .col-xs-4 {
          i {
            color: $colorRed;
          }
          select {
            font-size: 13px;
            height: 30px;
            max-width: 150px;
          }
        }
      }
    }
    .manageAppSort {
      float: right;
      margin-top: 20px;
      padding-top: 5px;
      margin-right: 5px;

      span:first-child {
        margin-right: 8px;
      }
      span:first-child + i {
        color: $colorRed;
      }
    }
  }
}


.filterJobs {
  white-space: no-wrap;
  font-size: 14px !important;
  padding: 0 10px !important;
  width: 200px !important;
  position: absolute;
  top: -15%;
  right: 0%;
  .filterJobsContainer {
    width: 200px !important;
    padding: 0 10px !important;
  }
  span {
    margin-bottom: 2.55px;
 white-space: nowrap;
  }
  select {
    cursor: pointer;
    background: none !important;
    font-size: 14px;
  }

  select option  {
    padding: 5px 10px !important;
    font-size: 14px !important;
    border-radius: 5px !important;
  }

  .dropIcon {
    left: 70%;
    bottom: 30%;
  }
}

@media only screen and (max-width: 670px) {
  .filterJobs {
    top:-19%;
    span {
      display: none;
    }
    .filterJobsContainer {
      margin-left: 40%;
    }
    .dropIcon {
      left: 60%;
    }
  }
}

@media only screen and (max-width: 430px) {
  .filterJobs {
    select {
      display: none;
    }
    svg {
      display: none;
    }
  }
}


.manageJobsSort {
  float: right;

  padding-top: 5px;
  margin-right: 5px;

  span:first-child {
    margin-right: 8px;
  }
  span:first-child + i {
    color: $colorRed;
  }
}

#manageCard {
  padding: 0;

  .jobSingle {
    height: auto;
    margin: 0;
    padding-top: 17px;
    padding-right: 0 !important;
    padding-left: 0 !important;
    .col-md-10,
    .col-xs-12 {
      margin-bottom: 0;
      text-align: left;
      .info {
        padding-bottom: 20px;
        height: auto;
      }
      .messageExpanded {
        padding-bottom: 15px;
      }
      h3 {
        margin: 0;

        span {
          padding-left: 15px;
        }
      }
      .messageToggle {
        margin-right: 30px;
        float: right;
      }
      div {
        min-height: auto !important;
        margin-bottom: 0;
        div {
          padding-bottom: 0;
          margin-bottom: 4px;
          margin-top: 0;
          height: 20px;
          padding-left: 15px;

          i {
            color: #818a91;
            font-size: 14px;
          }
          a {
            color: $colorBlack;
          }
        }

        .message {
          cursor: pointer;
          height: auto;
        }
        .message:hover {
          color: $colorRed;
          i,
          a {
            color: $colorRed;
          }
        }
      }
    }
  }

  .messageExpanded {
    width: 100%;
    text-align: left;
    padding: 15px;
    height: auto;
  }
  .jobFooter {
    margin-top: 0;
    margin-left: 0;
    width: 100%;
    left: 0;
    float: none !important;
    position: static;
    .row p {
      span {
        margin-left: 15px;
        padding-bottom: 5px;
      }

      span:last-child {
        color: $colorBlack;
      }
    }
  }
}

.manageAppPopup {
  min-height: auto;
  height: 80%;
  .content {
    height: 70%;

    div {
      height: 100%;

      textarea {
        min-height: 150px;
      }
    }
  }

  .buttons {
    margin: 0px
  }
}

/**************** Newsletters ***************/
.newsLetterText {
  color: $colorBlack;
  font-size: 14px;
  /*min-height: 1628px;*/
  .title {
    font-size: 17px;
    margin-bottom: 10px;
    h1 {
      font-weight: bold;
      font-size: 22px;
      margin: 0;
      padding: 5px 0;
    }
    span {
      font-size: 14px;
    }
    p {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 10px;
    }

    & + p {
      font-weight: bold;
    }
  }
  .subTitle {
    font-size: 14px;
    margin-bottom: 25px;
  }

}

.requestCardCard {
  border-top: 1px solid rgba(235, 235, 235, 1);
  border-bottom: 1px solid rgba(235, 235, 235, 1);
  background-color: rgba(235, 235, 235, 0.66);
  padding-left: 5px;
  .contactImage {
    border-radius: 100%;
    height: 50px;
    width: 50px;
    background-size: cover;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .contactInfo {
    margin-top: 5px;
    color: #4c4c4c;
    font-size: 12px;
  }
  .reqContactInfo {
    position: relative;
    margin-top: 5px;
  }
  .response {
    padding-left: 5px;
    button {
      width: 45%;
      color: #4c4c4c;
      opacity: 0.7;
      padding: 0;
      &:hover,
      &:focus {
        text-decoration: none;
        opacity: 1;
        &:first-child {
          color: $colorRed;
        }
      }
    }
  }
}
.responseCard {
  border-left: 2px solid #37b751;
}

.helptext:hover > .tooltip-box {
  display: inline;
}

.helptext {
  color: $colorPureBlack !important;
  padding-left: 5px;
  position: relative;

  .tooltip-box {
    position: relative;
    display: none;
  }

  .message {
    background: #222 none repeat scroll 0 0;
    color: $colorWhite !important;
    border-radius: 4px;
    font-size: 13px !important;
    line-height: 1.5;
    font-family: $siteFont;
    width: 230px;
    font-weight: 300;
    padding: 10px 12px;
    white-space: normal;
    position: absolute;
    margin-top: -8px;
    margin-left: 15px;
    z-index: 20;
    text-transform: none !important;

    &:before {
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 10px 10px 10px 0;
      border-color: transparent #222 transparent transparent;
      left: -10px;
      top: 9px;
      content: "";
      font-size: 0px;
      position: absolute;
    }
  }
}

.tooltip {
  left: 5px !important;
}

.tooltip-inner {
  text-align: left;
  font-size: 13px !important;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

@media (min-width: 992px) {
  .cb-filter-category-lang {
    margin-left: 5px;
  }
}

.font-weight-bold {
  font-weight: bold !important;
}

.fundingTableCell {
  padding: 5px 15px;
}

.startup-funding-invite-block {
  width: 90% !important;
  margin: 10px 5%;
  margin-top: 5px;
  background: $colorWhite;
  padding: 10px;
}

.shadow {
  -webkit-box-shadow: 6px 7px 15px -6px rgba(0, 0, 0, 0.54);
  -moz-box-shadow: 6px 7px 15px -6px rgba(0, 0, 0, 0.54);
  box-shadow: 6px 7px 15px -6px rgba(0, 0, 0, 0.54);
}

.genericPopup {
  width: 450px;
  min-height: 250px;
  margin: -213px 0 0 -225px;

  .content {
    padding: 35px 0 23px;

    .linked {
      width: 80%;

      span {
        width: 80%;
        padding: 0 15px;
      }
    }

    p {
      margin: 0px 0 5px 10%;
      text-align: left;
    }

    .title {
      width: 90%;
      margin: 0 auto;
    }
  }

  .buttons {
    p {
      display: inline-block;
      width: 43%;
      float: left;
      margin: 0 0 10px 10%;
      line-height: 55px;
      text-align: left;
    }

    a {
      background: $colorGrey4 !important;
      margin: 15px 10% 0 0;
      width: 32%;
      float: right;

      &:hover {
        background-color: $colorRed;
      }
    }

    .closeButton {
      width: 90%;
      margin: 0;
    }

    .redButton {
      background-color: $colorRed !important;

      &:hover {
        background-color: $colorBlack1 !important;
      }
    }
  }
}

.messagingWidget {
  position: fixed;
  z-index: 999;
  right: 250px;
  padding-right: 30px;

  @media (max-width: 600px) {
    padding-right: 20px;
  }

  .contactList {
    position: fixed;
    bottom: 0;
    width: 250px;
    background-color: #f8f8f8;
    border: 1px solid #dcdcdc;
    // fix bug where list becomes too tall and doesn't allow you to close it
    max-height: 80vh;

    .contact {
      padding: 5px;
      cursor: pointer;
      height: 50px;
      line-height: 45px;
      margin-bottom: 1px solid #e0e0e0;

      &:hover {
        background: $colorWhite !important;
      }
    }

    .unreadBubble {
      // display: inline;
      float: right;
      margin: 5px;
      background-color: $colorRed;
      width: 25px;
      height: 25px;
      border-radius: 25px;
      color: $colorWhite;
      line-height: 25px;
      text-align: center;
    }

    .avatarBig {
      height: 35px;
      float: left;
      background: $colorWhite;
      width: 35px;
      border-radius: 25px;
      border: 1px solid #e0e0e0;
      margin: 4px 5px;
      text-align: center;
      text-align: center;
      line-height: 33px;
      color: $colorPureBlack;
    }

    .avatar {
      height: 30px;
      float: left;
      background: $colorWhite;
      width: 30px;
      border-radius: 25px;
      border: 1px solid #e0e0e0;
      margin: 7px 5px;
      text-align: center;
      text-align: center;
      line-height: 30px;
    }

    .heading {
      float: left;
      width: 60%;
      margin-left: 15px;
      font-size: 15px;
    }

    .subHeading {
      width: 100%;
      margin-left: 60px;
      font-size: 12px;
    }

    .searchBox {
      height: 50px;
      width: 100%;
      padding: 10px;
    }
  }

  .myChatBubble {
    background-color: #f2b7c8;
    word-break: "break-all";
    float: right;
    clear: both;
    margin-right: 10px;
    border-radius: 10px 10px 0px 10px;
    font-size: 12px;
    padding: 2px 10px;
    margin-bottom: 4px;
  }

  .chatBubble {
    background-color: #f4f4f4;
    word-break: "break-all";
    float: left;
    clear: both;
    margin-left: 10px;
    border-radius: 0 10px 10px 10px;
    font-size: 12px;
    padding: 2px 10px;
    margin-bottom: 4px;
  }

  .chatMsgContainer {
    height: 30px;
    width: 100%;
  }

  .chatMsgInput {
    width: 75%;
    border-radius: 5px;
    margin-top: 5px;
    padding: 3px;
    margin-left: 1%;
    float: left;
  }

  .chatMsgSend {
    float: right;
    width: 20%;
    margin-top: 5px;
    margin-right: 1%;
    background-color: $colorRed;
    color: $colorWhite;
    font-size: 12px;
    padding: 6px;
    border: 0px;
  }
}

.btn-standard {
  background-color: $colorRed;
  color: $colorWhite;
  font-size: 12px;
  padding: 6px;
  border: 0px;
}

/* === manage pages === */

.manage-company-box {
  padding: 0 !important;

  &:not(:last-child) {
    margin-bottom: 30px !important;
  }

  .application-actions {
    box-shadow: unset !important;
    border: 1px solid #EBEBEB;
    border-radius: 0.25rem;
  }
}

.manage-application {
  padding-right: 24px;
  padding-left: 24px;

  .fundingrounds {
    padding: 0px !important;
  }

  .btn {
    font-size: 14px;
  }

  .application-actions {
    padding: 30px;
    position: relative;
    background: $colorWhite;
    -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  }

  .application-actions {
    margin: 0;

    .col-auto {
      padding-left: 0;
      padding-right: 11px;
    }

    .logo {
      width: 80px;
      height: 80px;
      border: 1px solid $colorBorder;
      border-radius: 4px;
      background-size: contain !important;
      background-repeat: no-repeat !important;
      background-position: center center !important;
    }

    .startup-details {
      padding-left: 25px;

      .startup-name {
        color: $colorBlack;
        font-size: 20px;
        max-width: 400px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
      }

      .applied-fund {
        margin-top: 10px;

        i {
          margin-right: 5px;
        }
      }

      .applied-fund div {
        font-size: 14px;
      }

      .applied-fund div:first-of-type {
        margin-bottom: 5px;
      }
    }

    .btn-box {
      padding: 0;

      @media (max-width: 750px) {
        margin-top: 20px;
      }

      @media (min-width: 750px) and (max-width: 992px) {
        display: flex;
        margin-top: 20px;

        .btn {
          max-width: 200px;
          min-width: 165px;
          margin-right: 50px;
          margin-top: 0 !important;
        }
      }

      .btn:focus {
        border: none;
        box-shadow: none;
      }

      a:nth-child(2) button {
        margin-top: 15px;
      }

      .btn-light {
        background-color: #f1f2f3;
      }
    }
  }
}

.toastSuccessStyle {
  background: $colorRed !important;
  color: $colorWhite !important;
  box-shadow: 3px 3px 25px 3px rgba(0, 0, 0, 0.5) !important;
  font-size: 14px;
}

.toastSuccessProgressBar {
  background: $colorWhite !important;
}

.toastErrorStyle {
  background: $colorWhite !important;
  color: $colorRed !important;
  box-shadow: 3px 3px 25px 3px rgba(0, 0, 0, 0.5) !important;
  font-size: 14px;
}

.toastErrorProgressBar {
  background: $colorRed !important;
}

.Toastify__close-button {
  color: #e0e0e0 !important;
}

.Toastify__toast {
  padding: 8px 15px;
}

.mButtonRed{
  background-color: $colorRed;
  color: $colorWhite;
  border: none;

  &:hover {
    background-color: $colorBlack1;
  }
}

.urlStyle {
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    color: $colorRed;
  }
}

/* === callout / alert === */

.callout {
  padding: 15px;
  margin-top: 1.25rem;
  border: 1px solid $colorBorder;
  border-left-color: $colorBorder;
  border-left-width: 1px;
  border-left-width: 0.25rem;
  border-radius: 0.25rem;

  i {
    margin-right: 5px;
  }
}

.callout.border-red {
  border-left-color: $colorRed;
}

/* === New Filter Layout === */

.ticketsize-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  left: -3px;
  padding: 2px;
  background: $colorGrey;
  color: $colorBlack;
}

/* === custom checkbox === */

.custom-control-label,
.custom-control-input {
  cursor: pointer;
}

.custom-control-label {
  &::before {
    border: 1px solid #D8D8D8;
  }

  &.error-checkbox::before {
    border: 1px solid #D22248a8 !important;
  }

  &.checkbox-x::after {
    content: "\f00d";
    font-family: 'Font Awesome 5 Free';
    color: $colorWhite;
    font-weight: 600;
    background-image: unset !important;
    text-align: center;
    font-size: 11px;
    height: 100%;
    line-height: 17px;
  }
}

.custom-control-input {
  &:enabled:checked ~ .custom-control-label::before {
    color: $colorWhite;
    background-color: $colorRed;
    border-color: $colorRed;
  }

  &:focus:not(:checked) ~ .custom-control-label::before {
    border: 1px solid #D8D8D8;
  }

  &:not(:disabled):active ~ .custom-control-label::before {
    background-color: #D22248a8;
    border-color: #D22248a8;
  }

  &:focus ~ .custom-control-label::before {
    box-shadow: none !important;
  }

  &:disabled:checked {
    & ~ .custom-control-label {
      cursor: auto;

      &::before {
        background-color: #bfc0c1 !important;
        border-color: #bfc0c1;
      }
    }
  }
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
    border-color: #CDCDCD;
    background-color: $colorWhite;
}

// inverted - white bg, red checkmark
// for use on red backgrounds

.custom-control-input.checkbox-inverted {
  &:enabled:checked ~ .custom-control-label::before {
    background-color: $colorWhite;
    border-color: $colorWhite;
  }

  &:checked ~ .custom-control-label::after {
    // apply css filer to change svg color
    // filter generator: https://codepen.io/sosuke/pen/Pjoqqp
    filter: brightness(0) saturate(100%) invert(18%) sepia(86%) saturate(2167%) hue-rotate(323deg) brightness(103%) contrast(115%);
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hideScrollBar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScrollBar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.section-header-row {
  border-bottom: 1px solid $colorBorder;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0px;
}

.section-header-content {
  margin-top: 10px;
  margin-left: 10px;
  color: $colorBlack;
  font-size: 16px;
}

.section-body {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  padding: 10px;
}

.section-body-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 16px;
}

.section-button {
  background-color: $colorRed;
  color: $colorWhite !important;
  font-size: 16px;
  width: 135px;
  height: 28px;
  border-radius: 4px;
  text-align: center;
  margin-top: 20px;
}

.modal {
  .buttons {
    text-align: center;

    a {
      display: inline-block;
      width: 170px;
      line-height: 38px;
      border-radius: 4px;
      color: $colorWhite;
      text-decoration: none;
      font-size: 14px;
      text-transform: uppercase;
      margin: 0 0 0 10px;

      &:first-of-type {
        background: $colorBlack1;
      }

      &:last-of-type {
        background: $colorRed;
      }
    }
  }
  .hideButton {
    margin: 0 20px 20px 0;
    line-height: 20px;
    color: $colorBlack;
    float: right;

    &:before {
      content: "\f056";
      display: inline-block;
      font-size: 20px;
      margin: 0 5px;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
    }
  }

  .addInvestor {
    margin: 0 20px 20px 0;
    line-height: 20px;
    color: $colorRed;
    float: right;
  }

  .addButton {
    margin: 0 20px 20px 0;
    line-height: 20px;
    color: $colorBlack;
    float: right;

    &:before {
      content: "\f055";
      display: inline-block;
      font-size: 20px;
      margin: 0 5px;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
    }
  }
}

.custom-class-temp__control {
  min-height: 28px !important;
  margin-bottom: 20px;
  border: 1px solid #e6e6e6;
  box-shadow: none;
}

.custom-class-temp__value-container {
  height: 30px;
}

.custom-class-temp__placeholder, .custom-class-temp__single-value {
  // color: $colorPureBlack !important;
  font-size: 12px;
}

.custom-class-temp__indicators {
  height: 30px;
  // color: $colorPureBlack;
}

// .custom-class-temp__indicator-separator {
//   color: $colorPureBlack;
// }

.custom-class-temp__menu {
  border: 1px solid #e6e6e6;
  box-shadow: none;
}

.custom-class-temp__option {
 background: $colorWhite;
 color: $colorPureBlack !important;
 font-size: 12px !important;
}

/* === profile score === */

.profile-score {
  // background: linear-gradient(0.25turn, #b6101f, $colorYellow, #37b751); // red to yellow to green
  background: linear-gradient(0.25turn, #343a40, $colorRed); // magnitt black to red
  width: 150px;
  margin-top: 5px;
  display: flex;

  .progress-bar {
    background-color: transparent;
    height: 100%;
  }

  .progress-bar-hider {
    background-color: #e9ecef;
    float: right;
    border: 5px solid #e9ecef;
  }
}

/* Startups SEO section */

.seo_container {
  padding: 30px;
  background: $colorWhite;
  border-top: 1px solid $colorBorder;
  border-radius: 4px;
  box-shadow: 0 0px 1px 0px rgba(0, 0, 0, 0.1);
  border-bottom: 0.75px solid #e5e5e5;
  margin-top: 2vw;
  margin-bottom: 0;

  h1 {
    font-size: 18px;
    color: $colorRed;
    margin-bottom: 1rem;
  }

  li {
    margin-top: 5px;
  }
}

.followerImage {
  height: 45px;
  width: 45px;
  border-radius: 7px;
  background-color: $colorWhite;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

/* === tabbed display === */
.tabbed-display {

  .btn {
    background: #F8F8F8;
    border: 1px solid #EBEBEB;
    color: $colorBlack !important;
    margin-bottom: 12px;

    &:not(:last-child) {
      margin-right: 15px;
    }

    &[data-active="true"] {
      border-bottom: 2px solid $colorRed;
      color: $colorRed !important;
    }

    .badge {
        background: #EBEBEB;
        border-radius: 25px;
        color: $colorBlack;
        margin-left: 8px;
        padding: 5px 7px;
    }
  }
}

/* === typeform === */

.typeform-embed {
    border: 0;
    height: 500px;
    width: 100%;
}

ul.ticks {
    width: 80%;
    float: left;
    margin: 30px 0px 30px 10px;
    line-height: 25px;
}

ul.ticks ol {
    font-weight: 600;
    font-size: 17px;
    margin: 8px 0;
}

ul.ticks li {
    padding: 0 0 0 40px;
    margin: 8px 0;
    list-style: none;
    background: url($PUBLIC_IMG_URL+"/icons/tick.png") left center no-repeat;
    background-size: 15px;
}

/* === privacy & terms === */

.privacy-terms {
    .page-header div {
        display: inline-block;
        margin: 15px;
    }

    .info-box-title {
        margin-bottom: 20px;
    }

    .rteindent1 {
        padding: 0 0 0 20px;
    }
}

/* === download success === */

.download-success-wrapper {
    box-shadow: 2px 2px 4px #d9d9d9;
    background-color: $colorWhite;

  .download-success {
    margin: 0 auto;
    padding: 40px;

        p {
            font-size: 18px;
        }

        a {
            color: $colorRed;
        }
    }
}

/* === investor applications === */

// single page

.investor-logo {
  background: $colorWhite;
  border-radius: 5px;
  margin-bottom: 15px;
  height: 80px;
  width: 80px;
  background-size: contain;
  margin-left: auto;
  margin-right: auto;
  background-position: center;
  background-repeat: no-repeat;
}

// filters

.funding-verified-status {
  // not verified / default
  &:before {
    color: $colorBlack;
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    margin-right: 8px;
    opacity: 1;
  }

  &[data-verif = "0"] {
    &:before {
      content: "\f12a";
    }

    &:after {
      content: "Pending";
    }
  }

  // verified
  &[data-verif = "1"] {
    &:before {
      content: "\f00c";
      color: $colorRed;
    }

    &:after {
      content: "Verified";
    }
  }
}

/* === locked content (paywall) === */

.paywall-text-blur {
    filter: blur(3px);
    opacity: 0.8;
}

.paywall-text-undo-blur {
  filter: unset;
  opacity: unset;
}

.paywall-landxcape-img {
  max-width: 1100px;
}

.paywall-box {

  // use case 1: full content blurred
  // used on directories
  &.paywall-box-blurred {
    > :not(.paywall-content) {
      filter: blur(3px);
      opacity: 0.8;
    }

    .paywall-content {
      position: absolute;
      background: transparent !important;

      >a {
        position: absolute;
        padding: 25px;
      }
    }
  }

  // use case 2: only some text blurred
  // used in funding section, team, investors list, etc.
  &:not(.paywall-box-blurred) {
    .paywall-content {
      background: $colorBlue2 !important;

      .paywall-label {
        color: $colorWhite;
        padding: 30px;
        text-align: left;
        max-width: unset;
        margin: unset;
        width: 100%;

        &.paywall-label-sm {
          padding: 14px;

          .paywall-label-header {
            font-size: 16px;
          }

          .btn,
          .paywall-label-message {
            font-size: 14px;
          }
        }

        .paywall-label-header {
          font-weight: 600;
          font-size: 20px;
          margin: 0;

          // lock icon
          &:before {
            // standard font awesome styles
            display: inline-block;
            font-family: "Font Awesome 5 Free";
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;

            // font styles
            content: "\f023";
            font-weight: 900;
            margin-right: 10px;
          }
        }

        .paywall-label-message {
          font-size: 16px;
          margin: 0;
          margin-top: 5px;

          .btn {
            font-size: 16px;
            display: block;
            margin-top: 10px;
            max-width: 100px;
          }
        }

      }
    }
  }

  // styles shared between both use cases
  position: relative;
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  .paywall-content {
    width: 100%;
    z-index: 1;
    display: block !important;
    overflow: unset !important;

    >a {
      align-items: center;
      display: flex;
      height: 100%;
      width: 100%;

      &:hover {
        color: inherit;
      }
    }

    &:not(.paywall-content-absolute) {
      height: 100% !important;
    }

    .paywall-label {
      display: block;
      margin: 0 auto;
      max-width: 450px;
      text-align: center;

      &.paywall-label-sm {

        >span,
        .btn {
          font-size: 15px;
        }
      }

      i.fa-lock {
        background: $colorBlue2;
        border-radius: 50%;
        color: $colorWhite;
        font-size: 28px;
        margin: 0 auto;
        padding: 16px 18px;
      }

      >h3 {
        font-size: 21px !important;
        color: $colorBlack;
        margin-top: 15px;

        &~span {
          font-size: 16.5px !important;
          margin-top: 8px;
        }
      }

      >span {
        display: block;
        font-size: 18px !important;
        margin-top: 15px;
        color: $colorBlack;
      }

      .btn {
        margin-top: 15px;
        min-width: 120px;
      }

      .locked-inline-section {
        margin: 0;

        @media (min-width: 600px) {
          display: flex;
          justify-content: space-between;
        }

        .locked-inline-button {
          align-self: center;
          margin-left: 10px;

          @media (max-width: 600px) {
            margin-left: 0;
          }
        }
      }
    }
  }

  // style edits
  tr:first-of-type {
    border-bottom: none !important;

    td {
      font-weight: unset !important;
    }
  }

  // responsive edits
  // hide some funding round table rows on mobile

  @media (max-width: $fundingTableBreakpoint) {
    tr.paywall-content.hide-rows {

      &~tr:nth-of-type(2),
      &~tr:nth-of-type(3) {
        display: none !important;
      }
    }
  }

  @media (max-width: $fundingTableBreakpoint) {
    &:not(.no-border) {
      border-bottom: 0.75px solid #5d656d9e;
    }

    tr.paywall-content.hide-rows {

      &~tr:nth-of-type(4),
      &~tr:nth-of-type(5),
      &~tr:nth-of-type(6) {
        display: none !important;
      }
    }
  }

  // sample profile images
  // for locked content

  .paywall-content {

    &~.startupLogo,
    &~.image-locked-content,
    &~tr .startupLogo,
    &~tr .image-locked-content,
    &~.paywall-text-blur .startupLogo,
    &~.paywall-text-blur .image-locked-content {
      display: flex;
      justify-content: center;

      &::after {
        color: $colorWhite;
        font-size: 34px;
        display: flex;
        font-family: "Font Awesome 5 Free";
        font-weight: 600;
        align-self: center;
      }
    }

    &~.startupLogo {
      background-image: unset !important;

      &::after {
        font-size: 22px;
      }

      h2 {
        display: none; // hide initial
      }
    }

    &~tr:nth-of-type(1) .startupLogo,
    &~tr:nth-of-type(1) .image-locked-content,
    &~.paywall-text-blur .row>div:nth-child(1) .startupLogo,
    &~.paywall-text-blur .row>div:nth-child(1) .image-locked-content {
      background: $colorRed;

      &::after {
        content: "\f0e7";
      }
    }

    &~tr:nth-of-type(2) .startupLogo,
    &~tr:nth-of-type(2) .image-locked-content,
    &~.paywall-text-blur .row>div:nth-child(2) .startupLogo,
    &~.paywall-text-blur .row>div:nth-child(2) .image-locked-content {
      background: $colorBlue1;

      &::after {
        content: "\f1ad";
      }
    }

    &~tr:nth-of-type(3) .startupLogo,
    &~tr:nth-of-type(3) .image-locked-content,
    &~.paywall-text-blur .row>div:nth-child(3) .startupLogo,
    &~.paywall-text-blur .row>div:nth-child(3) .image-locked-content {
      background: $colorBlue2;

      &::after {
        content: "\f4fb";
      }
    }

    &~tr:nth-of-type(4) .startupLogo,
    &~tr:nth-of-type(4) .image-locked-content,
    &~.paywall-text-blur .row>div:nth-child(4) .startupLogo,
    &~.paywall-text-blur .row>div:nth-child(4) .image-locked-content {
      background: #F4EBD9;

      &::after {
        content: "\f753";
        color: #333;
      }
    }

    &~tr:nth-of-type(5) .startupLogo,
    &~tr:nth-of-type(5) .image-locked-content,
    &~.paywall-text-blur .row>div:nth-child(5) .startupLogo,
    &~.paywall-text-blur .row>div:nth-child(5) .image-locked-content {
      background: $colorBlue4;

      &::after {
        content: "\f669";
      }
    }

    &~tr:nth-of-type(6) .startupLogo,
    &~tr:nth-of-type(6) .image-locked-content,
    &~.paywall-text-blur .row>div:nth-child(6) .startupLogo,
    &~.paywall-text-blur .row>div:nth-child(6) .image-locked-content {
      background: #4F345A;

      &::after {
        content: "\f3ed";
      }
    }

    &~.paywall-text-blur .image-locked-content.image-locked-team {
      &::after {
        content: "\f007" !important;
      }
    }
  }
}

.paywall-box{
  .widget-search{
    display: none;
  }

  .startup-benchmarking {
    display: flex !important;
    flex-direction: column !important;
    padding: 20px !important;
    padding-top: 0 !important;
  }

  .benchmarking-table{
    filter: blur(3px);
    opacity: 0.8;

    .close-button{
      display: none;
    }
  }

}

.locked-network-map-main {
    .graph__chart, .map-card-wrapper {
        position: relative;
        .paywall-label {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top:0;
            z-index: 1;
            a {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                i.fa-lock {
                    background: $colorBlue2;
                    border-radius: 50%;
                    color:  $colorWhite;
                    font-size: 28px;
                    margin: 0 auto;
                    padding: 16px 18px;
                }
            }
        }
    }

    .group-buttons {
      cursor: default;
    }
}

/* === single sice pages === */

.single-sice {
    margin-bottom: 0;

    .single-profile-box {
        padding: 21px 5px;
        display: flex;

        .textInformation {
            h1 ~ i {
                margin-left: 5px;
            }
        }

        .profile-logo-box {
          width: 160px;
          margin-right: 15px;
        }

        .single-profile-btns {
            justify-content: flex-end;
            display: flex;
            align-items: baseline;

            .btn {
                min-width: 160px;

                &.btn-main-outline {
                    margin-left: 15px;
                }

                i {
                    margin-right: 6px;
                }

                @media (max-width: 767px) {
                    margin-top: 20px;
                    min-width: unset;
                    width: 100%;
                    padding: 8px;
                    font-size: 14px;
                }
            }
        }

        .helptext {
            .tooltip-box .message {
                width: 150px;
            }
        }
    }

    .widget-title {
        border-bottom: 1px solid $colorBorder;
        padding: 14px;

        h3 {
            font-size: 16px;
        }

        i {
            color: $colorRed;
            margin-right: 6px;
        }
    }

    .video-box {
        margin: 24px auto;
        // outline: 1px solid $colorBlack;
        max-width: 500px;
    }

    .mid-width-info-box {

        h2 {
            font-size: 18px;
            margin-bottom: 20px;
            color: $colorBlack;
        }

        .info-box-title {
            font-size: 16.5px;
        }
    }

    .description-text,
    .description-text p {
        line-height: 25px;
        font-size: 15px;
    }

    .description-text {
        p + p {
            margin-top: 20px;
        }
    }
}

.directory-banner {
 img {
   @media (min-width: 1300px){
    max-height: 100%;
    object-fit: contain;
    object-position: left;
   }
 }
}

.team.team-section {
    div,
    h3, [class^=col] {
        margin-top: 0 !important;
    }

    > div:last-child:not(.row) {
        margin-top: 24px !important;
    }

    .team-section-item {
        display: flex;
        margin-top: 35px !important;
        overflow: visible !important;

		  .founder-badge {
			font-weight: 400;
			padding: 5px 10px;
			font-size: 13.5px;
			margin-left: 5px;
			background-color: #ededed;
			color: #6c757d;
		  }

        > div {
            margin-bottom: 0 !important;
            // prevent child elements from being condensed when there is less space
            flex-shrink: 0;
        }

        div .image {
            background-size: cover !important;
        }

        .team-section-contact {
            margin-top: 10px !important;
            margin-bottom: 0 !important;

            .social-tab {
                justify-content: unset !important;
                margin-top: 5px !important;
                margin-bottom: 0;

                i {
                    font-size: 11px;
                    height: 25px;
                    width: 25px;
                }
            }

            .btn {
                margin-left: 10px;
                padding: 2px 7px;
                font-size: 13px;

                i {
                    margin-right: 7px;
                    font-size: 12px;
                }
            }

            .team-viewed-email {
                margin-bottom: 5px;
                display: inline-block;
            }
        }
    }
}

// news dir widget
.directory-widget-section{
  display:flex;
  justify-content: space-between;
}

.widget-newsletter {
  @media(min-width: 1500px){
    padding-left: 15px;
    width:30%;
  }
  @media (min-width: 1200px) and (max-width: 1500px) {
      padding-left: 15px;
      display: none;
  }

  @media (max-width: 1200px) {
      display: none;
  }
}

/* === account sett === */

.general-manage-page {

    .db-header div {
        line-height: 18px;

        p {
            margin-top: 15px;
        }
    }

    hr {
        margin-top: 25px;
        margin-bottom: 25px;
        border-width: 0.75px;

        @media (max-width: 600px) {
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }

    .manage-row {
        margin-bottom: -20px;

        > div {
            position: relative;
            padding-right: 15px;
            padding-left: 15px;
            width: 280px;
        }

        .show-more {
          margin: auto;
          justify-content: center;
          display: flex;
          margin-left: -15px;
          margin-right: -15px;
          width: 100%;
        }
    }

    .manage-btn {
        border: 1px solid #d6d8da;
        border-radius: 5px;
        cursor: pointer;
        padding: 10px 15px;
        justify-content: space-between;
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-bottom: 23px;

        &:hover,
        &:active,
        &:focus {
            color: inherit;
        }

        .manage-btn-icon {
            margin-right: 10px;
            color: $colorRed;
            font-size: 16px;
        }

        > div.d-flex {
            i:first-child {
                align-self: flex-start;
                margin-top: 3px;
            }

            span {
                font-weight: 700;
                margin-bottom: 1px;
                display: block;
                font-size: 14.3px;
            }
        }

        > i:last-child {
            font-size: 12px;
        }
    }
}

.account-sett-modal.update {
    .form-control {
        margin-right: 0 !important;
        margin-left: 0 !important;
        width: 100% !important;
        margin-top: 18px;
        margin-bottom: 20px;
        font-size: 14px;
    }

    .content {
        padding: 30px;

        > div:first-child {
            font-weight: 600;
        }
    }
}

/* === feedback msg modal === */

.feedback-modal {
    a.close {
        font-size: 32px;
        opacity: 0.3;
        position: absolute;
        right: 14px;
        top: 8px;
    }

    img {
        width: 52px;
        margin-bottom: 12px;
    }

    h3 {
        margin-bottom: 12px;
        font-size: 19px;
        font-weight: 600;
    }

    h3, p {
        line-height: 22px;
        max-width: 390px;
        margin-right: auto;
        margin-left: auto;
    }

    p {
        font-size: 15.5px;
        margin-top: 15px;
    }
}

/* === create/edit form === */

// scroll-margin-top = scroll offset when scrolling to id (scrollIntoView)

#edit-team-form,
#edit-edu-form,
#edit-exp-form {
  scroll-margin-top: 60px;

  @media (max-width: 600px) {
    scroll-margin-top: 110px;
  }
}

.create-edit-form {
    scroll-margin-top: 70px;

    @media (max-width: 600px) {
        scroll-margin-top: -190px;
    }

    .mid-width-info-box,
    .list-group {
        border-radius: 8px;
    }

    &.modal-body {
        padding: 20px;
        margin-bottom: -25px;

        table,
        .table-wide-button {
          margin-bottom: 0 !important;
        }
    }

    .info-box-container {
        .db-header {
            display: block;

            div {
              font-size: 21px;
            }

            p {
                font-size: 15px;
                margin-top: 15px;
            }
        }

        .form-feedback-container {
            margin-top: 0 !important;

            > div {
                color: $colorRed;
                margin-top: 6px !important;
                font-size: 14px !important;
                margin-bottom: 16px;

                &:nth-child(1) {
                    margin-top: 0px !important;
                }
            }
        }
    }

    .list-group {
        .list-group-item {
            border: none;
            cursor: pointer;

            &:not(:last-child) {
                border-bottom: 1px solid RGB(0 0 0 / 7%);
            }

            &.list-item-active {
                > div {
                    color: $colorRed;
                }
            }

            h6 {
                line-height: 22px;
                font-size: 15.5px;
            }
        }
    }

    .create-edit-btns {
        max-width: 650px;
        margin-top: 5px !important;

        div {
            margin-top: 0 !important;
        }

        .btn i {
            margin-right: 8px;
        }

        &:first-child {
            margin-bottom: 25px;
        }
    }

    .info-box-body {
        p, 
        a:not(.page-link) {
            font-size: 14px !important;
        }

        p {
            line-height: 21px;
        }
    }

    .form-box-layout {
        padding: 0px;
        margin-top: 0px;

        [class^="col"],
        .fileupload, .input-group,
        label ~ div:not(.view-selected-ops):not(.errorsContainer):not(.dropdown-form-list):not(.startup-funding-invite-block) {
            margin-top: 0px !important;
        }

        // react select reset
        .margin-reset {
            margin-top: 0px !important;
            font-size: 14px !important;

            &.--is-disabled {
                border: none !important;
            }

            div {
                margin-top: 0px !important;
            }

            input:not([type="checkbox"]) {
                margin-bottom: 0px !important;
            }

            input[type="checkbox"] {
                margin-bottom: 9px !important;
            }

            [class$="-Input"] {
                margin: 0px;
                padding: 0px;
            }

            // for react-select library, the input classnames are inconsistent between dev & production

            [class$="-Input"],  // dev
            &.__input {         // production
                input {
                    padding: 0 !important;
                    border: none !important;
                }
            }

            .__single-value {
                color: $colorBlack;
            }
        }

        .__value-container {
            padding: 0px 8px;
        }

        input[type="file"] {
            padding: 4px 3px !important;
        }

        .input-group {
            .input-group-text {
                margin-bottom: 25px;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                min-width: 40px; // make all same width
                display: flex;
                justify-content: center;
                border: none;

                & ~ input {
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                }
            }

            input ~ .input-group-prepend {
              .input-group-text {
                border-radius: 5px;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
              }
            }
        }

        label {
            display: block;

            span {
                font-size: 14px !important;
            }
        }

        textarea {
            height: 150px;
            padding: 6px 10px;

            @media only screen and (max-width: 800px) {
                height: 300px;
            }
        }

        .form-rule {
            margin-top: 8px;
            margin-bottom: 25px;
            border-top: 0.75px solid rgba(0,0,0,.1);
        }

        .form-sub-header {
            margin-bottom: 10px;
            font-size: 15px !important;
        }
        .add-edit-investor-container {
          .estimate-message-container {
            display: flex;
            width: 50%;
          }
          @media only screen and (max-width: 800px) {
            flex-direction: column !important;
            .estimate-message-container {
              width: 100%;
            }
          }
        }
        textarea,
        .errorsContainer {
            font-size: 14px !important;
        }

        .errorsContainer {
            margin-bottom: 0px !important;
            margin-top: -8px !important;
            padding-bottom: 20px; // offset input margin
            line-height: 20px;

            &.frounds-error {
                margin-top: -8px !important;
            }
        }

        .table-responsive {
            overflow-x: hidden;
            overflow-y: auto;
            padding-right: 0px;
            margin-top: 10px !important;
        }

        table {
            margin-top: 0px !important;
            border: none !important;
            border-right: 0.75px solid $colorBorder !important;
            border-bottom: 0.75px solid $colorBorder !important;

            @media (max-width: 600px) {
              display: block !important;
              padding-top: 0 !important;
              overflow-x: scroll;
            }

            th, tr, td, td * {
                font-size: 14px !important;
                text-align: left !important;
            }

            th, tr, td {
                border-left: 0.75px solid $colorBorder;
                vertical-align: middle;
                line-height: 20px;
                border-top: none;
                border-bottom: 0.75px solid $colorBorder;
                border-right: none;
            }

            .nestedCell {
                padding: 0 !important;

                div {
                    padding: 5px 12px;
                    margin-top: 0 !important;
                }

                div:not(:last-child) {
                    border-bottom: 1px solid $colorBorder;
                }
            }

            tr:nth-of-type(odd) {
                .nestedCell {
                    div:not(:last-child) {
                        border-bottom: 1px solid $colorWhite;
                    }
                }
            }
        }

        .btn {
            font-size: 14px !important;
            padding: 6.5px 8px;
            width: 100%;
            margin-bottom: 25px;
        }

        .btn-group .btn {
            margin-bottom: 0 !important;
        }

        .fileupload input:after {
            content: attr(data-filename);
            margin-left: 108px;
            position: absolute;
            background: $colorWhite;
            width: 180px;
            margin-top: 3px;
            border-left: 5px solid $colorWhite;
            left: 0;
        }

        .review-submission {
            margin-bottom: 25px;
            margin-top: 0 !important;
        }
    }

    // preloaded list
    // linked institutions, investors, etc.

    .dropdown-form-list {
        margin-top: -26px !important;
        border: 1px solid $colorBorder;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        margin-bottom: 26px;
        // scroll
        max-height: 110px;
        overflow-y: scroll;
        overflow-x: auto;

        &.is-absolute {
          // absolute styles
          position: absolute;
          width: calc(100% - 30px);
          background: $colorWhite;
          z-index: 1;
        }

        @media (max-width: 600px) {
          max-height: 140px;
        }

        li {
            margin: 0 !important;
            list-style: none !important;
            padding: 7px 10px;
            border-top: 1px solid $colorBorder;
            background: $colorWhite;
            font-size: 14px !important;
            color: $colorBlack !important;
            cursor: pointer;

            &:hover {
                color: $colorRed !important;
            }
        }
    }

    .support-checkbox {
        display: flex;
        align-items: center;
        margin-top: 0;
        margin-right: 25px;
        width: 190px;

        @media(max-width: 600px) {
            width: 100%;
        }
    }

    .startup-funding-invite-block {
        padding: unset;
        margin: unset;
        font-size: 14px !important;
        margin-top: -15px !important;
        background: transparent;
        margin-bottom: 12px;
        width: unset !important;

        select, input {
            margin-top: 0 !important;
            margin-bottom: 8px !important;
        }

        input {
            padding: 5px 10px !important;
        }

        select {
            padding: 8px 10px !important;
        }

        a, * {
            font-size: 14px !important;
        }

        .text-card {
            margin-top: 0 !important;
            line-height: 21px;
        }

        div:not(.text-card) > a {
            padding: 6px 12px !important;
        }

        .alert {
            padding: unset;
            background: unset;
            border: unset;
            margin-bottom: 7px;
            font-size: 14px;

            &.alert-danger {
                color: $colorRed;
            }

            & ~ div {
                margin-top: 0 !important;
            }
        }

        div:first-child {
            margin-top: 0 !important;
        }
    }

    // callout styles
    .callout,
    .callout span {
        font-size: 14px !important;
    }

    .callout {
        margin-top: 0 !important;
        display: inline-block;
        line-height: 22px;
        margin-bottom: 1.5rem;
    }
}

/* === multi rows indicator for multi select === */
// use on edit sice, inv app, create sice, etc.

.view-selected-ops {
    display: flex;
    flex-flow: wrap;
    align-items: flex-start;
    max-height: 118px;
    overflow-y: auto;
    margin-bottom: 12px;

    &:not(.view-selected-ops-lg) {
        margin-top: -10px !important;
    }

    > div {
        border-radius: 15px;
        border: 1px solid #dddddd;
        padding: 2px 10px;
        margin-right: 5px;
        margin-bottom: 10px;
        font-size: 13px !important;
        display: inline-block;
        cursor: pointer;
        width: max-content;
        background: $colorWhite;
        margin-top: 0 !important;

        i {
            margin-left: 8px;
            font-size: 13px !important;
        }
    }

    &.view-selected-ops-lg {
        margin-top: 10px ​!important;

        > div {
            padding: 4px 10px;
            font-size: 14px !important;
            margin-right: 12px;

            i {
                margin-left: 10px;

                &:hover {
                    color: $colorRed;
                }
            }
        }
    }
}

.item-box.team-list {
    @media (max-width: 600px) {
        margin-top: 30px !important;
    }

    .item-img,
    .item-header div {
        margin-top: 0 !important;
    }

    .item-img h2, .btn {
        margin-bottom: 0 !important;
    }
}

.modal-xl {
    @media (min-width: 576px) {
        max-width: 95%;
    }

    @media (min-width: 1200px) {
        max-width: 1140px;
    }
}

/* === onboarding === */

.onboarding-image {
    border-radius: 5px;
    background-image: url($PUBLIC_IMG_URL+"/bg/your_platform.svg") !important;
    background-position: center !important;
}

/* === related news widget === */

.related-news-widget {

    .widget {
        margin: 0 !important;

        .title {
            background-color: $colorRed;

            .news {
                color: $colorWhite;
                line-height: unset !important;
                padding: 12px 15px !important;
            }
        }
    }

    img {
        border: 0.75px $colorBorder solid;
        border-radius: 5px;
        // styles for test inv if img doesn't load
        background: $colorWhite2;
        min-width: 100%;
        line-height: 0;
    }

    .related-news-link {
        margin-top: 10px;
        display: block;
        font-size: 14px;
    }
}

/* modals */

.info-Modal {
  background: $colorGrey !important;
}

/* === backend error message when creating a new entity === */

.backend-errors-list {
    color: $colorRed;
    text-align: left;
    list-style:disc !important;
    margin-left: 40px;
    margin-top: 10px;
}

.card-link {
	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
	text-align: left;
	color: $colorRed;
	overflow-wrap: anywhere;
	display: flex;
	align-items: flex-start;

	i {
		 margin-top: 3px;
		 margin-right: 4px;
	}
}
