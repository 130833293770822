// Startup benchmarking tool

.startup-benchmarking {
    .title {
        align-items: baseline;
        display: block;
        float: left;
        margin-top: 0 !important;

        h2 {
            line-height: 27px;
        }

        .sub-title {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: $darkBlue1;
            margin-left: auto;

            .sm-screen-message{
                display: none;
            }
        }
    }

	.errorFallbackMessage {
		margin-top: 90px !important;
		text-align: center;
		padding   : 10px;

		h3,
		a {
			color: $colorRed !important;
		}
	}
    .widget-search {
        margin: 0 !important;
        float: right;

        .header-search-wrapper {
            position: relative;
            min-width: 300px;
        }

        .header-search-dropdown {
            overflow-y: scroll !important;
            padding: 0 10px !important;
            margin-top: -8px !important;
            top: 47px;

            .header-logo {
                width: 45px !important;
                height: 45px !important;
                margin: 0;
                flex-shrink: 0; // prevent logo from shrinking when text is long
            }
        }

        .startup-search-results {
            display: flex;
            align-items: center;

            h2,
            .header-logo {
                cursor: pointer;
            }
        }
    }

    .benchmarking-table {
        .css-grid-table,
        .css-grid-table-body {
            display: grid;
            width: 100%;
            line-height: 24px;
        }

        // Creating table structure where data is arranged in column
        .css-grid-table-body {
            grid-template-columns: auto auto auto;
            grid-template-rows: repeat(6, max-content);
            grid-auto-flow: column;
            overflow-x: auto !important;
            justify-content: flex-start;

            // Scrollbar styles
            &::-webkit-scrollbar,
            &::-webkit-scrollbar:hover {
                height: 8px;
            }

            &::-webkit-scrollbar {
                z-index: 999;
                opacity: 0.1;
            }

            &::-webkit-scrollbar:hover {
                opacity: 1;
            }

            // Track
            &::-webkit-scrollbar-track {
                z-index: 999;
                background: $scrollbar_track;
            }

            // Handle
            &::-webkit-scrollbar-thumb {
                background: $scrollbar_thumb;
                border-radius: 10px;
            }

            // Handle on hover
            &::-webkit-scrollbar-thumb:hover {
                background: $scrollbar_thumb_hover;
            }

            // Variable for table header (first column) width
            $tableHeaderWidth: 140px;

            .section-div {
                margin: 0 !important;
                padding: 15px 12px;
                min-width: 220px;

                @media (min-width: 851px) {
                    max-width: 300px;
                }
            }

            // Background colors for alternate rows
            .section-div:nth-child(2n+1) {
                background-color: $colorWhite;
            }

            .section-div:nth-child(2n+2) {
                background-color: #F8F8F8;
            }

            // Border for current startup in desktop view
            .section-div:nth-child(n+7):nth-child(-n+12) {
                border-left: 1px solid $darkBlue1 !important;
                border-right: 1px solid $darkBlue1 !important;
                position: sticky;
                z-index: 20;

                @media (min-width: 851px) {
                    left: $tableHeaderWidth;
                }
            }

            .section-div:nth-child(7) {
                border-top: 1px solid $darkBlue1 !important;
                border-top-left-radius: 7px !important;
                border-top-right-radius: 7px !important;
            }

            .section-div:nth-child(12) {
                border-bottom: 1px solid $darkBlue1 !important;
                border-bottom-left-radius: 7px !important;
                border-bottom-right-radius: 7px !important;
            }

            .table-header {
                font-size: 13px;
                line-height: 18px;
                font-weight: 500;
                color: #8A8A8A;
                background-color: $colorWhite; 
                padding: 15px 12px;
                width: $tableHeaderWidth;
                min-width: $tableHeaderWidth;
                position: sticky;
                left: 0;
                z-index: 20;
            }

            .company-profile {
                box-sizing: border-box;

                .seperator-left {
                    position: relative;
                }

                .startup-logo {
                    border-radius: 5px;
                    width: 40px;
                    height: 40px;
                    margin: 0;
                    object-fit: contain;
                    margin-bottom: 10px;
                    border: 1px solid $colorBorder;

                    h2 {
                        text-align: center;
                        color: $colorBlack;
                        text-decoration: none;
                        margin: 0px;
                        vertical-align: middle;
                        text-transform: uppercase;
                        font-size: 26px;
                        line-height: 40px;
                        width: 100%;
                        height: 100%;
                        background-color: #F7F7F7;
                    }
                }

                .industry-tag,
                .sm-industry-tag {
                    p {
                        font-size: 12px;
                        font-weight: 600;
                        border-radius: 4px;
                        padding: 0px 8px;
                        line-height: 18px;
                        width: fit-content;
                        color: #099137;
                        background-color: #E7F6EC;
                        max-height: 36px;
                    }
                }

                .industry-tag {
                    min-height: 36px;
                }

                .startup-name {
                    font-size: 17px;
                    font-weight: 600;
                    color: #101928;
                }

                .startup-location {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    color: #667185;
                }

                .startup-date {
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 18px;
                    color: #4A4A4A;
                }

                .startup-website {
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 20px;
                    color: $colorRed;

                    i {
                        font-size: 10px;
                        margin-right: 5px;
                    }
                }

                .close-button {
                    border-radius: 50%;
                    background-color: #2C373B;
                    color: $colorWhite;
                    font-size: 12px;
                    padding: 3px 5px;
                    vertical-align: middle;
                    margin-top: 0px;
                    position: absolute;
                    right: 0;
                    top: 0;
                    cursor: pointer;
                }
            }

            .latest-funding {
                .funding-stage {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 20px;
                    color: $darkBlue1;
                }

                .funding-date {
                    font-size: 13px;
                    font-weight: 600;
                    line-height: 18px;
                    color: #4A4A4A;
                }

                .funding-valuation-title {
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 20px;
                    color: #8A8A8A;
                    margin-top: 10px !important;
                    margin-bottom: 1px !important;
                }

                .funding-valuation {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 16px;
                    color: #161616;
                }
            }

            .investors-data {
                .investors-name {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                }

                .investors-name-entity {
                    color: $colorRed;
                    text-decoration: underline;
                }
            }

            .exit-data {
                .exit-title {
                    font-size: 13px;
                    line-height: 15px;
                    font-weight: 400;
                    color: #8A8A8A;
                }

                .exit-acquirers {
                    a {
                        font-size: 14px;
                        line-height: 18px;
                        font-weight: 600;
                        color: $darkBlue1 !important;
                    }
                }

                .exit-date {
                    font-size: 14px;
                    line-height: 23px;
                    font-weight: 500;
                    color: #4A4A4A;
                }
            }

            .team-data {
                .team-size {
                    font-size: 14px;
                    line-height: 15px;
                    font-weight: 500;
                    color: $colorPureBlack;

                    i {
                        font-size: 12px;
                        margin-right: 5px;
                    }
                }

                .team-members {
                    margin: 10px 0 !important;

                    .team-profile-img {
                        border-radius: 50%;
                        width: 30px;
                        height: 30px;
                        display: inline-block;
                        text-align: center;
                        color: $colorBlack;
                        text-decoration: none;
                        margin: 0px;
                        vertical-align: middle;
                        text-transform: uppercase;
                        font-size: 14px;
                        font-weight: 600;
                        background-color: #e7e7e7;
                        border: 2px solid $colorWhite;

                        p {
                            width: 100%;
                            height: 100%;
                            margin-top: 1px !important;
                        }
                    }
                }

                .team-members span:not(:first-child) {
                    position: relative;
                }

                .team-members span:nth-child(2) {
                    left: -10px
                }

                .team-members span:nth-child(3) {
                    left: -20px
                }

                .team-members span:nth-child(4) {
                    left: -30px
                }

                .team-members span:nth-child(5) {
                    left: -40px
                }
            }

            .founder-data {
                .founder-items:not(:last-of-type) {
                    margin-bottom: 15px !important;
                }

                .founder-profile-img {
                    border-radius: 50%;
                    width: 32px;
                    height: 32px;
                    display: inline-block;
                    text-align: center;
                    color: $colorBlack;
                    text-decoration: none;
                    margin: 0px;
                    vertical-align: middle;
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: 600;
                    background-color: #FFE7CC;

                    p {
                        padding-top: 2px;
                        width: 100%;
                        height: 100%;
                    }
                }

                .founder-name {
                    font-size: 14px;
                    line-height: 16px;
                    font-weight: 500;
                    color: $colorPureBlack;
                }

                .founder-role {
                    font-size: 12px;
                    line-height: 18px;
                    font-weight: 400;
                    color: #4A4A4A;
                }
            }

            .not-applicable {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                color: #939393;
            }

            .sm-screen-header {
                display: none;
            }

            .sm-industry-tag {
                display: none;
            }

            .sm-screen-seperator-left,
            .sm-screen-seperator-right {
                margin: 0
            }
        }
    }
}

@media (max-width: 850px) {
    .startup-benchmarking {
        .title {
            h2{
                padding-bottom: 15px !important;
            }

            .sub-title {
                margin: 0 !important;

                .sm-screen-message {
                    color: #8A8A8A;
                    display: inline;
                }
            }
        }

        .widget-search {
            display: none;
        }

        .benchmarking-table {
            .css-grid-table-body {
                display: block;
            }

            .css-grid-table-body {
                .sm-screen-header {
                    display: block;
                    margin: 0 -12px;
                    padding: 0 12px 15px 23px;
                    color: #667085;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 18px;
                }

                .sm-screen-top-padding {
                    padding-top: 15px;
                }

                .sm-screen-left-padding {
                    padding-left: 10px;
                }

                .section-div:nth-child(n):nth-child(-n+6) {
                    display: none;
                }

                // Set alternate rows color back to white in mobile view
                .section-div:nth-child(2n+2) {
                    background-color: $colorWhite;
                }

                // Current startup set inner borders to light grey color
                .section-div:nth-child(n+7):nth-child(-n+11) {
                    border-bottom: 1px solid #cccccc !important;

                    .sm-screen-header {
                        border-bottom: 1px solid #cccccc !important;
                    }
                }

                .section-div:nth-child(-n+12) {
                    border-bottom: 1px solid $darkBlue1 !important;

                    .sm-screen-header {
                        border-bottom: 1px solid #cccccc !important;
                    }
                }

                // Remaining startups set borders to light grey color
                .section-div:nth-child(n+13) {
                    border-left: 1px solid #cccccc !important;
                    border-right: 1px solid #cccccc !important;
                    border-bottom: 1px solid #cccccc !important;

                    .sm-screen-header {
                        border-bottom: 1px solid #cccccc !important;
                    }
                }

                // Set border radius to the box containing each startup details
                .section-div:nth-child(6n+13) {
                    border-top: 1px solid #cccccc !important;
                    border-top-left-radius: 7px !important;
                    border-top-right-radius: 7px !important;
                    margin-top: 20px !important;
                }

                .section-div:nth-child(6n+18) {
                    border-bottom: 1px solid #cccccc !important;
                    border-bottom-left-radius: 7px !important;
                    border-bottom-right-radius: 7px !important;
                    margin-bottom: 10px !important;
                }

                .section-div:nth-child(7) {
                    margin-top: 10px !important;
                }

                .section-div:nth-child(12) {
                    margin-bottom: 10px !important;
                }

                .sm-screen-seperator-left {
                    width: 50%;
                    float: left;
                    text-align: start;
                    padding: 5px 10px;
                }

                .sm-screen-seperator-right {
                    width: 50%;
                    float: right;
                    text-align: start;
                    padding: 5px 10px;
                }

                .company-profile {
                    .sm-screen-seperator {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .seperator-left {
                        margin-right: 15px !important;
                        margin-top: 7px !important;
                        position: inherit;
                    }

                    .startup-logo {
                        width: 50px;
                        height: 50px;
                        margin: 0px !important;
                    }

                    .industry-tag {
                        display: none;
                    }

                    .sm-industry-tag {
                        display: block;
                        margin-top: 6px !important;
                    }

                    .close-button {
                        display: none;
                    }
                }

                .exit-data {
                    .sm-screen-seperator-left {
                        margin-top: 10px;
                    }
                }

                .latest-funding {
                    .funding-valuation-title {
                        margin: 0px !important;
                    }
                }

                .team-data {
                    .team-size {
                        margin-top: 10px !important;
                    }

                    .team-members {
                        margin: 0 !important;
                    }
                }

                .founder-data {
                    .founder-info {
                        display: flex;

                        .founder-items {
                            width: 50%;
                            margin: 0 !important;
                        }
                    }
                }
            }
        }
    }
}

// Removing as scroll is enabled for the table
// Hide one similar startup from view to make the table more readable when sidenav is shown
// @media (min-width: 992px) and (max-width: 1300px) {
//     body:not(.bs-sidenav-hidden) {
//         .section-div:nth-child(n+25):nth-child(-n+30) {
//             display: none;
//         }
//     }
// }