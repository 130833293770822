/* directory chart styles */

.directory .map-dashboard-wrapper {
    margin: 0;
    border: none;

    .map-dashboard-graph {
        margin: 10px;

        .graph__title {
            margin-bottom: 20px;
            font-size: 18px;
        }

        &:not(:last-child) .graph__chart {
            margin-bottom: 35px;
        }
    }
}

.directory {
    .network-map-main .map-card-wrapper {
        margin: 0;
    }
}

.network-map-main {
    .no-chart-message {
        height: 300px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            max-width: 350px;
        }
    }

    .directory-sidebyside-chart {
        display: flex;
        justify-content: center;
        border: none;
        padding: 0;

        .graph__title {
            margin-bottom: 15px;
            padding: 0 7px;
        }

        .sidebyside-chart-container-left,
        .sidebyside-chart-container-right {
            border: 1px solid rgba(0, 0, 0, 0.125);
            border-radius: 7px;
            padding: 20px;
            flex: 1;
        }

        .sidebyside-chart-container-right {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-left: none;
        }

        .sidebyside-chart-container-left {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
        }

        .d3-tooltip {
            text-align: left !important;
        }

        .d3-chart-legend .legend-item {
            justify-content: center !important;

            .legend-text {
                .legend-name {
                    text-align: left !important;
                }

                .legend-value {
                    text-align: end !important;
                }
            }
        }

		  .horizontal-chart-container {
			height     : 100%;
			display    : flex;
			align-items: center;

			.d3-horizontal-stacked-svg {
				width: auto !important;

				svg {
					height: auto !important;
				}

				g,
				text,
				.tick {
					font-size  : 15px !important;
					font-weight: 600 !important;
				}

				.d3-horizontal-stacked-x-axis,
				.d3-horizontal-inner-label{
					display: none;
				}
			}
		}
	}

	.donut-sidebyside {
		.donut-chart-container svg {
			width : 450px !important;
			height: 300px !important;
		}
        .donut-center-label-lg {
            font-size: 40px !important;
        }

        .donut-center-label-sm {
            font-size: 30px !important;
        }
    }

    .bar-sidebyside {
        svg {
            width: auto !important;
            height: auto !important;
        }

        .d3-general-label, .d3-y-label, .d3-y-axis, .d3-x-axis {
            font-size: 30px !important;
        }

        .graph__title {
            margin-bottom: 0 !important;
        }
    }

    @media (max-width: 800px) {
        .directory-sidebyside-chart {
            flex-direction: column;

            .sidebyside-chart-container-right {
                border-radius: 7px;
                border-top-right-radius: 0;
                border-top-left-radius: 0;
                border-left: 1px solid rgba(0, 0, 0, 0.125);
                border-top: 0 !important;
            }

            .sidebyside-chart-container-left {
                border-radius: 7px;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
        
        .bar-sidebyside {
            .d3-general-label, .d3-y-label, .d3-y-axis, .d3-x-axis {
                font-size: 16px !important;
            }
        }
    }

    @media (max-width: 600px) {
        .donut-sidebyside {
            svg {
                width: auto !important;
                height: auto !important;
            }
        }
    }
}