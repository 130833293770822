$colorRed: #D22248;
$colorOrange: #FF8811;

$colorBlack: #5d656d;
$colorBlack1: #353f48; // #303f48
$colorPureBlack: #000000;

$colorWhite: #FFF;
$colorWhite1: #f3f4f4;
$colorWhite2: #e5e7e7;

$colorBlue1: #6e859e;
$colorBlue2: #4d5e71;
$colorBlue3: #394756;
$colorBlue4: #272f38;

$darkBlue: #1D2B36;
$darkBlue1: #17445D;
$darkBlueTransparent: rgba(29, 43, 54, 0.20); // 0.38 max
$vibrantBlue: #06283A;
$PE_Blue: #2265B3;

$colorBorder: #eeeeee;
$colorGreen: #7cc576;
$colorYellow: #f3d82c;
$colorYellowText: #eacc00; // easier to read

$colorGrey: #F5F5F5; // #e5e6e8
$colorGrey3: #ADB1B5;
$colorGrey4: #798289; // #818a91;
$colorGrey7: #aeb2b6;
$colorTableBorder: #dee2e6;

// text variables
$fontSize: 12.5px;
$fontAwesome: 'Font Awesome 5 Free';
$siteFont: "Lato", sans-serif;

// colors for custom scrollbar
$scrollbar_track         : adjust-color($colorWhite1, $lightness:-1.5%);
$scrollbar_thumb         : adjust-color($colorWhite1, $lightness: -10%);
$scrollbar_thumb_hover   : adjust-color($colorWhite1, $lightness: -15%);

// box shadow styles
$boxShadowRed: 0px 0px 15px 10px rgba(211, 17, 72, 0.03);
$boxShadowGray: 0px 0px 15px 10px rgba(23, 29, 48, 0.03);

// responsive breakpoints
$fundingTableBreakpoint: 1300px;

// new brand guideline - Dec 2023
// primary colors
$colorPrimary1: #FFF1F2;
$colorPrimary2: #FEE5E7;
$colorPrimary3: #FCCFD4;
$colorPrimary4: #FAA7B1;
$colorPrimary5: #F67688;
$colorPrimary6: #EE4561;
$colorPrimary: #D22248;
$colorPrimary7: #B8183E;
$colorPrimary8: #9A173B;
$colorPrimary9: #841738;
$colorPrimary10: #4A071A;

// secondary colors
$colorSecondary1: #F3F7F8;
$colorSecondary2: #E1EBEC;
$colorSecondary3: #C7D8DA;
$colorSecondary4: #9FBDC1;
$colorSecondary5: #71999F;
$colorSecondary6: #557E85;
$colorSecondary7: #4A6970;
$colorSecondary8: #40575E;
$colorSecondary9: #3A4B50;
$colorSecondary: #2C373B;
$colorSecondary10: #1F292D;

// accent colors
$colorAccent1: #FF8811;
$colorAccent2: #5B9EEC;
