$indentBreakpoint: 1350px;
$tabletColumnWidth: 50%;
$mobileColumnWidth: 40%;
$filtersResponsiveBreakpoint: 700px;

.funding-box {

    .fundingrounds {
        border-radius: 8px;
        box-shadow: rgba(149, 157, 165, 0.08) 0px 8px 24px;
        margin-bottom: 0 !important;

        .title {
            h3 {
                font-size: 15px;
                color: #667085;
                line-height: 23px;
                margin-right: 5px; // to stop filters button being too close

                b {
                    color: $darkBlue1;
                    font-weight: 600;
                }

                span {
                    color: $darkBlue1;
                    font-weight: 600;

                }
            }
        }

        .paywall-blur {
            h3 {
                font-size: 15px !important;
                color: #667085 !important;
                line-height: 23px !important;
                margin-right: 5px !important; // to stop filters button being too close

                b {
                    color: $darkBlue1 !important;
                    font-weight: 600 !important;
                    color: $darkBlue1 !important;
                    font-weight: 600 !important;
                    filter: blur(3px) !important;
                    opacity: 0.8 !important;
                }
            }
        }

        // table styles
        #no-more-tables {
            table {

                // fixed layout on mobile only (it causes issues when indent is hidden)
                @media (min-width: calc($indentBreakpoint)) {
                    &[data-table-indent='true'] {
                        table-layout: fixed;
                    }
                }

                &[data-table-indent='false'] {
                    @media (max-width: $fundingTableBreakpoint) {
                        .table-main-row td {
                            &:nth-child(2) {
                                display: none;
                            }

                            &:not(:first-child) {
                                border: none !important;
                            }
                        }
                    }

                    // tablet
                    @media (min-width: 600px) and (max-width: $fundingTableBreakpoint) {
                        .table-main-row td {
                            &:not(:first-child) {
                                width: 33%;
                            }
                        }
                    }
                }
            }

            th {
                border: none;
                color: #667085;
                font-weight: 600;
            }

            td {
                border-right: none;
                border-left: none;
                border-bottom: none;

                p:not(:last-child) {
                    margin-bottom: 4px;
                }

                // mobile table header
                @media (max-width: $fundingTableBreakpoint) {
                    &::before {
                        content: attr(data-mobile-title);
                    }
                }

                // cells that display on mobile only
                @media (min-width: calc($fundingTableBreakpoint + 1px)) {
                    &.cell-responsive:not(.cell-first-investor) {
                        display: none;
                    }
                }
            }

            tr:not(.table-main-row) {

                th,
                td {

                    &.table-indent {
                        border: none !important;

                        // hide on mobile & tab
                        // note: only works if table layout is not 'fixed'
                        // don't use display: none as it breaks colSpan alignment
                        @media (max-width: $indentBreakpoint) {
                            padding: 0;
                        }
                    }

                    i {
                        &:not([class^="fa-stack"]) {
                            font-size: 14px;
                            opacity: 0.9;
                        }

                        &[class^="fa-stack-1x"] {
                            font-size: 9px;
                            line-height: 19px;
                        }

                        &[class^="fa-stack-2x"] {
                            font-size: 19px;
                        }

                        @media (max-width: $fundingTableBreakpoint) {
                            &.fa-stack-2x {
                                width: auto;
                            }

                            &.fa-stack-1x {
                                width: 20px;
                            }
                        }
                    }
                }

                td {
                    $paddingOffset: 12px;

                    // tablet
                    @media (min-width: 600px) and (max-width: $fundingTableBreakpoint) {
                        padding-left: calc($tabletColumnWidth + $paddingOffset) !important;
                    }

                    // mobile
                    @media (max-width: 599px) {
                        padding-left: calc($mobileColumnWidth + $paddingOffset) !important;
                    }
                }

                // mobile border between funding breakdowns
                &:not(:has(+ .table-main-row)) {
                    @media (max-width: $fundingTableBreakpoint) {
                        td.table-indent {
                            border: none !important;
                        }

                        td:last-child {
                            border-bottom: 1px solid #DADADA;
                        }
                    }
                }
            }

            tr {
                &[data-first="false"] {
                    td {
                        border: none;
                    }
                }

                @media (max-width: $fundingTableBreakpoint) {
                    border: none;
                }
            }

            // hide desktop-only elements on mobile
            .table-header-desktop {
                @media (max-width: $fundingTableBreakpoint) {
                    display: none;
                }
            }

            .table-main-row {
                background-color: #F5F9FF;
                border-color: #F5F9FF;
                border-bottom: 5px solid $colorWhite;

                &:not(:first-of-type) {
                    border-top: 5px solid $colorWhite;
                }

                td {
                    font-weight: 400;
                    color: $colorBlack;
                    padding: 12px 10px;

                    @media (max-width: $fundingTableBreakpoint) {
                        padding: 14px !important;

                        &:not(:first-child) {
                            display: inline-block;
                            border: none !important;
                        }

                        &:nth-last-child(2) {
                            border: none !important;
                        }
                    }

                    // tablet
                    @media (min-width: 600px) and (max-width: $fundingTableBreakpoint) {
                        &:not(:first-child) {
                            width: $tabletColumnWidth;
                        }
                    }

                    // mobile
                    @media (max-width: 599px) {
                        &:not(:first-child) {
                            width: $mobileColumnWidth;
                        }
                    }
                }

                .overview-name-row {
                    display: flex;

                    .startupLogo {
                        margin-right: 10px;
                    }
                }

                .overview-detail {
                    p:first-child {
                        color: $darkBlue1;
                        font-size: 15px;
                        font-weight: 600;
                        margin-bottom: 4px;

                        a {
                            color: $darkBlue1 !important;
                        }
                    }

                    p:last-child {
                        font-size: 14px;
                    }
                }
            }

            // alternate view for exits table
            .exits-table-header {
                border: none;
                border-bottom: 1px solid $colorBorder;

                // fix cell text alignment
                th:first-child {
                    padding-left: 0;
                }

                // fix cell text alignment
                &~tr {
                    td:first-child {
                        padding-left: 0;
                    }

                    @media (max-width: $fundingTableBreakpoint) {
                        td:not(.nested-btn-cell)::before {
                            left: 0;
                        }
                    }
                }
            }
        }
    }

    .entity-row {
        .entity-row-box {
            display: flex;
            align-items: center;

            &:not(:first-child) {
                margin-top: 8px;
            }

            .startupLogo {
                margin-right: 9px;
            }

            a {
                color: $darkBlue1 !important;
            }

            p:last-child {
                margin-top: 4px;
            }
        }
    }

    .fa-stack {
        margin-top: -10px;
        max-width: 28px;

        .fa-stack-2x {
            font-size: 22px;
        }

        .fa-stack-1x {
            line-height: 22px;
        }

        &.icon-stack-pending {
            i:first-child {
                color: $darkBlue1;
            }

            .fa-stack-1x {
                font-size: 9px;
            }
        }

        &.icon-stack-verified {
            i:first-child {
                color: $colorGreen;
            }

            i:last-child {
                color: $colorWhite !important;
            }

            .fa-stack-1x {
                font-size: 12px;
            }
        }
    }

    .funding-filters {
        margin-top: 15px;

        @media (max-width: $filtersResponsiveBreakpoint) {
            margin-bottom: 15px !important;
        }

        #duplicate-filter-icon {
            display: none !important;
        }

        label {
            font-size: 14px;
            margin-top: 5px;
        }

        .filter-clear-btn {
            margin-right: 0 !important;

            .btn-main {
                min-width: 110px;
                text-align: center;
            }
        }

        .filter-row {
            margin-right: -10px;
            margin-left: -10px;

            @media (max-width: $filtersResponsiveBreakpoint) {
                flex-direction: column;
            }

            .filter-field {
                padding-right: 10px;
                padding-left: 10px !important;

                @media (max-width: $filtersResponsiveBreakpoint) {
                    width: 100%;
                    max-width: 100%;
                    flex: 0 0 100%;

                    &:not(:first-child) label {
                        margin-top: 10px;
                    }
                }
            }
        }
    }

    .funding-title {
        display: flex;
        justify-content: space-between;

        @media (max-width: $filtersResponsiveBreakpoint) {
            flex-direction: column;
        }

        .funding-filters-button {
            border-radius: 8px;
            box-shadow: 0px 1px 2px 0px #1018280D;
            border: 1px solid #D0D5DD;
            padding: 10px 12px;
            min-width: 95px;

            // text
            font-size: 14px;
            color: $darkBlue1;
            text-align: center;

            // flex
            align-self: baseline;

            i {
                margin-right: 6px
            }

            @media (max-width: $filtersResponsiveBreakpoint) {
                width: 100%;
                margin-top: 15px;
            }
        }
    }

    .funding-table-pagination {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 20px;

        @media (min-width: $fundingTableBreakpoint) {
            border-top: 1px solid $colorBorder;

            &:not([data-table-type="portfolioExits"]) {
                margin-top: 24px;
            }
        }

        .btn {
            border-radius: 8px;
            box-shadow: 0px 1px 2px 0px #1018280D;
            border: 1px solid #D0D5DD;
            padding: 8px 12px;

            // text
            font-size: 14px;
            color: $darkBlue1;
            text-align: center;

            @media (min-width: 401px) {
                min-width: 105px; // keep both buttons same width

                &:first-child i {
                    margin-right: 8px
                }

                &:last-child i {
                    margin-left: 8px;
                }
            }

            // smaller screens
            // hide 'previous' and 'next' label in button
            @media (max-width: 400px) {
                span {
                    display: none;
                }
            }
        }
    }
}

// hide indent when sidenav is minimized, to allow more space for the table
body.bs-sidenav-show.bs-sidenav-pinned {
    #no-more-tables table {
        table-layout: auto;

        th,
        td {
            &.table-indent {
                padding: 0;
            }
        }
    }
}