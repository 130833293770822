.add-company-modal {
    min-height: 200px !important;
    top: 20vh !important;

    .buttons {
        a {
            float: none !important;
        }
    }

    .content {
        padding: 25px !important;

        p {
            margin-bottom: 4px;
        }

        ul li {
            height: 41px !important;
            line-height: 40px !important;
            border: none !important;
            font-size: 16px !important;
            text-align: left !important;
            margin-top: 18px !important;

            a {
                // font
                font-weight: normal !important;
                color: #303e47;
                font-size: 14px;

                background: $colorWhite !important;
                padding: 0 5px !important;
                width: 100% !important;
                height: 40px !important;
                border-radius: 4px !important;
                cursor: pointer !important;
                border: 1px solid $colorWhite;

                // position
                display: flex;
                justify-content: center;

                &:hover {
                    text-decoration: none !important;
                    border: 1px solid $colorRed;
                    transition: border ease 0.3;
                }
            }

            i {
                width: 32px;
                height: 40px;
                background-size: 62%;
                background-position: center center !important;
                background-repeat: no-repeat !important;
                display: block;
                float: left;

                // margin
                margin-right: 3px;
                margin-left: -10px;
            }
        }
    }

    @media (max-width: 600px) {
        margin: 0 auto !important;
        top: 140px !important;
    }

    @media (min-width: 601px) and (max-width: 767px) {
        margin: 0 auto !important;
        top: 140px !important;
    }
}