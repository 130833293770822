// reusable section for investor list, linked institutions list, startup relations list

.simple-detail-section {
    .title {
        margin: 0 !important;

        span {
            margin-left: 5px;
            color: $colorGrey3;
            font-size: 15px;
        }
    }

	.people-entity {
		margin-top: 20px !important;
	 }

    .entity-container{
        margin-top: 35px !important;
    }

    .row {
        .simple-detail-text {
            margin-top: 0 !important;
            padding-left: 0 !important;

            p:first-of-type {
                font-size: 14.5px !important;
                font-weight: 600;
                color: $colorBlack !important;
                margin-bottom: 5px !important;

                &:hover {
                    color: $colorRed !important;
                }
            }
        }

        .simple-detail-img {
            // bugfix for missing outline when margin is removed
            margin-top: 0px !important;
            border-top: 1px solid $colorBorder;

            margin-bottom: 0 !important;
            border-bottom: 1px solid $colorBorder;

            outline: 1px solid $colorBorder;
            border-radius: 5px;
            width: 100%;
            aspect-ratio: 1;

            // flex
            display: flex;
            align-items: center;
            justify-content: center;

            div {
                text-transform: uppercase;
                font-size: 30px;
            }
        }
    }
}
