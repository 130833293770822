/* === careers page === */

// responsive breakpoints
$heroSectionBreakpoint: 1000px;
$globalSectionBreakpoint: 800px;
$reviewsBreakpoint: 1200px;

// need diff breakpoints, so the styles aren't doubly applied at 991px
$missionBreakpoint_mobile: 991px;
$missionBreakpoint_desktop: calc($missionBreakpoint_mobile + 1px);

.careers-heading {
    font-weight: 700;
    font-size: 30px;
    color: $colorBlue4;
    margin-bottom: 45px !important;
    line-height: 38px;

    &.w-100 {
        text-align: center;
    }
}

.careers-page,
.careers-single-page {
    position: relative; // for absolute pos line graphic
    overflow: hidden; // for absolute pos line graphic

    // limit element width on larger screens
    .careers-hero-section,
    .careers-employee-section,
    .careers-jobs-section,
    .careers-job-desc,
    .careers-memories-section,
    .careers-mission-section > div,
    .careers-interview-steps > div,
    .careers-global-section > div,
    .careers-benefits-section > div {
        margin: 0 auto;
    }

    .careers-hero-section,
    .careers-employee-section,
    .careers-jobs-section,
    .careers-memories-section {
        max-width: 1200px;
    }

    .careers-interview-steps {
        &>div {
            max-width: 1100px;
        }
    }

    .careers-global-section,
    .careers-benefits-section,
    .careers-mission-section {
        &>div {
            max-width: 1300px;
        }
    }

    .careers-hero-section {
       position: relative; // for absolute line graphic
        // desktop: padding & border radius
        // mobiles: full against page width, no border radius
        @media (min-width: $heroSectionBreakpoint) {
            padding: 40px;

            .video-box {
                border-radius: 14px;
            }
        }
		  .video-box {
				text-align: center;
            min-height: 400px; // for mobile
            max-height: 500px;
            overflow: hidden;
            position: relative;
            justify-content: center;
            z-index: 1;

            // flex
            display: flex;
            align-items: flex-start;
            // align-items: center; // crop the video to center point, if too tall

            img,
            video {
                width: auto;
                border-radius: inherit;
                height: 100%;
            }

            .video-overlay {
                padding: 20px;
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;

                // background-color: $darkBlueTransparent; // overlay to be used if there's an image / video
                // background-color: $vibrantBlue;
                background: radial-gradient(circle, #0b4868 0%, $vibrantBlue 100%);

                // flex
                display: flex;
                align-items: center;
                justify-content: center;

                h1 {
                    // txt
                    color: $colorWhite;
                    font-size: 40px;
                    font-weight: 600;

                    // pos
                    max-width: 540px;
                    margin: 0 auto;
                    text-shadow: 3px 4px 30px $darkBlue;

                    @media (max-width: $heroSectionBreakpoint) {
                        font-size: 36px;
                    }
                }

                .btn {
                    margin-top: 45px;
                    padding: 14px 28px;

                    i {
                        margin-left: 10px;
                    }
                }
            }

        }

        .lines-graphic.lines-graphic-left {
            left: -250px;
            bottom: -60%;
        }

    }

    $missionPadding: 45px;

    .careers-mission-section {
        background-color: $vibrantBlue;
        padding: $missionPadding;
        position: relative;

        // mobile
        @media (max-width: $missionBreakpoint_mobile) {
            margin: 0 -15px;
            padding: 55px 20px;

            &:before {
                top: 0;
                left: 0;
                content: "";
                position: absolute;
                height: 30vw; // flexible, so the white bg is always half the height of the team img
                width: 100%;

                // background
                background-color: $colorWhite;
                background-image: url($PUBLIC_IMG_URL+"/vectors/lines.svg");
                background-size: 60%;
                background-repeat: no-repeat;
                background-position: right center;
            }
        }

        .mission-img {
            display: flex; // for vertical alignment
            height: 100%;

            // mobile
            @media (max-width: $missionBreakpoint_mobile) {
                justify-content: center;

                img,
                video {
                    width: 100%;
                    max-width: 700px;
                }
            }

            img,
            video {
                border-radius: 14px;
                z-index: 1;
                position: relative;
                align-self: center; // for vertical alignment

                // desktop
                @media (min-width: $missionBreakpoint_desktop) {
                    margin-left: 80px;
                }
            }

            video {
                max-width: 800px;
                width: 100%;
            }
        }

        .mission-text {
            padding: $missionPadding;

            // mobile
            @media (max-width: $missionBreakpoint_mobile) {
                max-width: 550px;
                padding-bottom: 0;
                margin: 0 auto;
                text-align: center;

                h6,
                p {
                    color: $colorWhite;
                }

                p {
                    display: inline;
                }

                .btn {
                    display: block;
                    max-width: 200px;
                    margin: auto;
                    font-size: 17px;
                }
            }

            // desktop
            @media (min-width: $missionBreakpoint_desktop) {
                max-width: 470px;
                margin: -$missionPadding 0;
                height: calc(100% + $missionPadding + $missionPadding);
                padding-left: 90px;
                color: $colorBlue4;

                // background
                background-color: $colorWhite;
                background-image: url($PUBLIC_IMG_URL+"/vectors/lines.svg");
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
            }

            h6 {
                margin-bottom: 26px !important;
            }

            p {
                font-size: 20px;
                line-height: 28px;

                &:not(:last-of-type) {
                    margin-bottom: 14px;
                }
            }

            .btn {
                margin-top: 32px;
                padding: 8px 16px;
            }
        }
    }

    .careers-global-section {
        background-color: $vibrantBlue;
        padding: 45px;

        // mobile
        @media (max-width: $globalSectionBreakpoint) {
            padding-right: 5px;
            padding-left: 5px;
        }

        .careers-heading {
            color: $colorWhite;
        }

        #map-graphic {
            position: relative;
            z-index: 0;

            // mobile
            @media (max-width: $globalSectionBreakpoint) {
                margin-bottom: 40px;
            }
        }

        .global-counts-box {
            h6 {
                font-size: 20px;
                margin-bottom: 15px;
            }

            &:nth-of-type(2) {
                h6 {
                    margin-top: 50px;
                    margin-bottom: 26px;
                }
            }

            // center stats on mobile
            @media (max-width: $globalSectionBreakpoint) {
                display: flex;
                flex-flow: column;
                align-items: center;
            }

            .text-global {
                color: $colorWhite;
                display: flex;
                position: relative;
                z-index: 1;

                span {
                    font-weight: 700;
                    font-size: 32px;
                    margin-right: 5px;
                    line-height: 45px;

                    &+span {
                        margin-top: 10px;
                    }
                }

                p {
                    font-size: 20px;

                    &.icon-label {
                        font-size: 16px;
                        margin-top: 10px;
                        margin-bottom: 8px;
                    }
                }

                i {
                    font-size: 44px;
                }

                // invert svg from black to white
                img.svg-invert {
                    -webkit-filter: invert(100%);
                    /* Safari/Chrome */
                    filter: invert(100%);
                    height: 50px;
                }

                .counts-section {
                    display: flex;
                    flex-direction: column;

                    &:nth-child(1),
                    &:nth-child(2) {
                        margin-right: 35px;
                        align-items: center;
                    }

                    &:last-child {
                        justify-content: flex-end;

                        p {
                            line-height: 45px;

                            &+p {
                                margin-top: 10px;
                            }
                        }
                    }
                }

            }

        }

        // tablet responsiveness
        @media (min-width: 650px) and (max-width: 1199px) {

            // a flex element that's only used for tablet responsiveness
            .counts-flex {
                display: flex;
                align-items: center;
                justify-content: space-around;

                .global-counts-box h6 {
                    margin-top: 0;
                }
            }

            #map-graphic {
                margin-bottom: -5px;
            }

        }
    }

    .careers-benefits-section {
        padding: 45px;

        .benefit-box {
            text-align: center;

            img {
                margin-bottom: 10px;
                height: 48px;
                width: auto;
            }

            p {
                color: $colorBlue4;
                font-size: 16px;
                line-height: 21px;
                max-width: 170px;
                margin: 0 auto;
            }
        }

        @media (max-width: 1000px) {
            .careers-heading {
                margin-bottom: 10px !important;
            }

            .benefit-box {
                margin-top: 35px;
            }
        }
    }

    .careers-employee-section {
        position: relative;
        z-index: 1;
        padding: 45px;
        padding-top: 20px;
        padding-bottom: calc(55px + 35px + 8px); // offset absolute indicators
        // mobile
        @media (max-width: $reviewsBreakpoint) {
            padding-left: 25px;
            padding-right: 25px;
        }
		  
        .carousel {
            z-index: 1;

            .carousel-indicators {
                position: absolute; // must be absolute so next/prev can be center aligned
                bottom: -35px;

                li {
                    height: 7px;
                    border-radius: 30px;
                    border: unset;
                    opacity: 1;

                    &:not(.active) {
                        background-color: #D9D9D9;
                        width: 30px;
                    }

                    &.active {
                        background-color: $colorRed;
                        width: 70px;
                    }
                }
            }

            [class^="carousel-control"] {
                opacity: 1;
                background-image: unset; // remove bootstrap default icon

                // mobile
                @media (max-width: $reviewsBreakpoint) {
                    display: none;
                }

                &[class$="-prev"] {
                    left: -55px;
                }

                &[class$="-next"] {
                    right: -55px;
                }

                img {
                    height: 55px;
                    width: auto;
                }
            }

            .carousel-inner {
                box-shadow: $boxShadowRed;
                margin: 0 auto;

                // desktop
                @media (min-width: $reviewsBreakpoint) {
                    height: 500px;
                    max-width: 950px;
                }

                // mobile
                @media (max-width: $reviewsBreakpoint) {
                    max-width: 650px;
                }

                .carousel-item {
                    height: 100%;

                    .card {
                        border-radius: 14px;
                        border: unset;
                        height: 100%;

                        // desktop
                        @media (min-width: $reviewsBreakpoint) {
                            flex-direction: row;
                        }

                        .card-img {
                            width: 44%;
                            border-radius: 0;
                            border-top-left-radius: 14px;
                            border-bottom-left-radius: 14px;
                            background-position: center center;
                            background-size: cover;

                            // mobile
                            @media (max-width: $reviewsBreakpoint) {
                                display: none;
                            }
                        }

                        // image has diff style on mobile
                        .card-mobile-img {
                            display: none;

                            // mobile
                            @media (max-width: $reviewsBreakpoint) {
                                display: block;
                                height: 175px;
                                width: 175px;
                                border-radius: 14px;
                                background-position: center center;
                                flex-shrink: 0;
                                margin-right: 18px;
                                background-size: cover;
                                background-repeat: no-repeat;
                                margin-bottom: 10px;
                                border: 1px solid #5d656d12;
                            }
                        }

                        .card-body {
                            padding: 35px 50px;
                            align-self: center; // vertical align middle

                            // mobile
                            @media (max-width: $reviewsBreakpoint) {
                                padding: 35px;
                            }

                            .review-title {
                                font-weight: 300;
                                font-size: 26px;
                                margin-bottom: 28px;
                                color: $colorBlue4;
                            }

                            p {
                                font-size: 16px;
                                line-height: 24px;
                            }

                            .review-text {

                                p,
                                ul {
                                    margin-bottom: 14px;
                                }

                                p {
                                    font-weight: 400;

                                    // indent for quote mark
                                    &:nth-of-type(1) {
                                        text-indent: 15px;
                                    }
                                }

                                li {
                                    list-style-type: disc;
                                    margin-left: 14px;
                                }
                            }

                            .quote-mark {
                                font-size: 30px;
                                font-family: Georgia, serif;
                                line-height: 14px;
                                margin-top: 10px;
                                position: absolute;
                            }

                            .reviewer-info {
                                margin-top: 22px;

                                p:nth-of-type(1),
                                p:nth-of-type(2) {
                                    font-weight: bold;
                                    font-style: italic;
                                }

                                p:nth-of-type(3) {
                                    font-size: 14px;
                                    opacity: 0.75;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .lines-graphic.lines-graphic-left {
        // bg
        background-image: url($PUBLIC_IMG_URL+"/vectors/lines.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left bottom;

        // position
        position: absolute;
        width: 100%;
        height: 550px;
        bottom: -45%;
        left: -190px;
    }

    .careers-jobs-section {
        background: $colorWhite;
        border-radius: 14px;
        padding: 40px;
        box-shadow: $boxShadowRed;

        // for absolute pos line graphic
        position: relative;
        z-index: 1;

        @media (max-width: 600px) {
            padding: 40px 30px;
        }

        .careers-heading {
            margin-bottom: 20px !important;
        }

        .row:not(.job-types)>[class^='col-'] {
            margin-top: 25px;
        }

        .job-item {
            border: 1px solid #E2E0E0;
            border-radius: 14px;
            padding: 18px;
            display: flex;
            height: 100%;

            img {
                height: 48px;
                width: auto;
                margin-right: 15px;
            }

            p {
                color: $colorBlue4;

                &.job-name {
                    font-weight: 600;
                    font-size: 16px;
                    margin-bottom: 4px;
                    line-height: 22px;
                }

                &.job-info {
                    font-size: 14px;
                }
            }

            .btn {
                margin-top: 15px;
            }
        }

        .feedback-message {
            margin-top: 14px;
            width: 100%;
            text-align: center;

            p {
                font-size: 16px;

                &:first-of-type {
                    margin-bottom: 10px;
                }
            }
        }

        .job-types {
            justify-content: center;
            max-width: 900px;
            margin: 0 auto;
            margin-bottom: 15px;

            .col-auto {
                padding-left: 6px;
                padding-right: 6px;
            }

            .job-filter,
            .filter-divider {
                margin-top: 14px;
            }

            .job-filter {
                padding: 8px 14px;
                font-size: 15px;
                border: 1px solid #DFDFDF;
                border-radius: 8px;
                cursor: pointer;

                &[data-active="true"] {
                    color: $colorRed;
                    border: 1px solid $colorRed;
                }
            }

            .filter-divider {
                background-color: $colorBlue2;
                height: 39px;
                width: 5px;
                border-radius: 15px;
                margin-right: 10px;
                margin-left: 10px;
            }
        }
    }

    hr {
        margin: 2.5rem 0;
    }

    .job-secondary {
        text-align: center;

        h6 {
            font-size: 24px;
            margin-bottom: 6px;
            color: $colorBlue4;
            font-weight: 600;
        }

        p {
            font-size: 18px;
            max-width: 660px;
            margin: 0 auto;
            margin-bottom: 25px;
            margin-top: 15px;
        }

        .btn {
            padding: 8px 15px;
        }
    }

    // scroll to link
    #open-positions {
        scroll-behavior: smooth;
        scroll-margin-block-start: 125px;
    }

    .careers-memories-section {
        padding: 45px;
    }

}

.instagram-widget {
    overflow: hidden;

    // hide widget watermark
    &>div {
        width: 100%;
        margin-top: -20px;
        margin-bottom: -50px;
    }
}

// sidenav edits
.bs-sidenav-pinned {
    .careers-jobs-section {
        margin-left: 15px;
        margin-right: 15px;
    }
}

$departmentBoxBreakpoint: 1000px;

.careers-single-page {

    $departmentBoxOffset: 65px;

    .careers-hero-section {
        padding-bottom: 10px;

        .video-box {
            min-height: 420px;

            .video-overlay {
                padding: 20px 25px;

                // desktop
                @media (min-width: $departmentBoxBreakpoint) {
                    padding-bottom: calc($departmentBoxOffset + 10px);
                }

                h1 {
                    max-width: 600px
                }

                .btn {
                    padding: 8px 22px;
                    margin-top: 25px;
                }
            }
        }

        .lines-graphic.lines-graphic-left {
            bottom: -20%;
            left: -280px;
            // transform: rotateX(180deg);

            // mobile
            @media (max-width: $departmentBoxBreakpoint) {
                display: none;
            }
        }
    }

    .job-labels {
        display: block;
        margin-top: 28px;

        &.icon-smaller {
            margin: 0;
            margin-left: -10px;

            span {
                font-size: 14px;
                margin-bottom: 4px;
            }
        }

        a {
            color: $colorRed;
            margin-left: 4px;
        }

        span {
            color: $colorWhite;
            display: inline-block;
            font-size: 18px;
            background: rgba(255, 255, 255, 0.22);
            padding: 12px 17px;
            border-radius: 7.5px;
            margin: 0 9px 18px 9px;

            @media (max-width: 600px) {
                font-size: 16px;
            }

            i {
                color: $colorWhite;
                margin-right: 5px;

                &.fa-map-marked-alt {
                    margin-right: 9px;
                }
            }
        }

    }

    .about-department {
        background: $colorWhite;
        padding: 48px;
        margin: 0 auto;
        text-align: center;
        box-shadow: $boxShadowRed;
        z-index: 1;
        position: relative;

        // desktop
        @media (min-width: $departmentBoxBreakpoint) {
            margin-top: -$departmentBoxOffset;
            border-radius: 14px;
            max-width: 850px;
        }

        // desktop lg
        @media (min-width: 1200px) {
            max-width: 1000px;
        }

        h2 {
            font-size: 30px;
            font-weight: 700;
            color: $darkBlue;
            margin-bottom: 32px;
        }

        p {
            font-size: 18px;
            max-width: 740px;
            margin: 0 auto;
        }
    }

    .careers-job-desc {
        background: $colorWhite;
        padding: 40px;
        box-shadow: $boxShadowRed;
        margin-top: 10px;
        max-width: 1100px;

        @media (max-width: 600px) {
            padding: 40px 30px;
        }

        // desktop
        @media (min-width: 1125px) {
            border-radius: 14px;
            margin-bottom: 60px;
        }

        .careers-heading {
            margin-bottom: 32px !important;
        }

        p {
            font-size: 18px;
            line-height: 29px;

            &+.careers-heading {
                margin-top: 40px;
            }
        }
    }

    .careers-job-apply {
        background-color: $vibrantBlue;
        padding: 45px;
        position: relative;
        overflow: hidden;

        .careers-heading {
            color: $colorWhite;
            text-align: center;

            &+p {
                color: $colorWhite;
                text-align: center;
                font-size: 18px;
                max-width: 600px;
                margin: 0 auto;
                margin-top: -5px;

                a {
                    color: $colorRed;
                }
            }
        }

        .job-apply-form {
            max-width: 820px;
            margin: 0 auto;

            // for absolute line graphic
            position: relative;
            z-index: 1;

            label {
                color: $colorWhite;
                font-size: 19px;

                div {
                    font-weight: 600;
                    font-size: 15.5px;
                }
            }

            input,
            textarea {
                background-color: rgba(255, 255, 255, 0.22);
                border: none;
                font-size: 17px;
                padding: 14px;
                border-radius: 7px;
                color: $colorWhite;

                &.form-control-file {
                    padding: 14px;
                }
            }

            textarea {
                height: 100%;
                min-height: 150px;
            }

            // make full name + email fields line up with message box
            .flex-input {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .form-group {
                    height: calc(100% - 62.5px);
                }
            }

            .btn {
                padding: 8px 20px;
                font-size: 20px;
                min-width: 200px;
                margin-top: 30px;

                i {
                    margin-right: 10px;
                }

                @media (max-width: 700px) {
                    width: 100%;
                }
            }

            [class^="form-feedback"] {
                margin-bottom: 16px;
                font-size: 16px;

                &[class$="-error"] {
                    color: $colorRed;
                }

                &[class$="-success"] {
                    color: $colorGreen;
                }
            }
        }

        // style placeholder
        // for some reason, this won't work if you group the styles

        .form-control::placeholder {
            color: #DBDDDD;
        }

        .form-control:-ms-input-placeholder {
            color: #DBDDDD;
        }

        .form-control::-ms-input-placeholder {
            color: #DBDDDD;
        }

        // chrome bugfix
        // input bg changes after autocomplete
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            transition: background-color 5000s ease-in-out 0s !important;
            -webkit-text-fill-color: $colorWhite;
        }

        .lines-graphic {
            opacity: 0.4;

            @media (min-width: 1500px) {
                left: -250px;
            }

            @media (max-width: 1100px) {
                display: none;
            }
        }
    }

    $interviewStepsBreakpoint: 991px;
    $interviewStepsBreakpoint_desktop: calc($interviewStepsBreakpoint + 1px);

    .careers-interview-steps {
        background: #F8F8F8;
        padding: 50px;
        border-top: 2px solid $colorPureBlack;
        position: relative; // for absolute pos line graphic

        .careers-heading {
            text-align: center;
            margin-bottom: 60px !important;
        }

        .interview-step-box {
            background: $colorWhite;
            border-radius: 16px;
            padding: 28px;
            box-shadow: 0px 2px 16px 1px #69829926;

            h6 {
                color: $darkBlue;
                font-weight: 600;
                font-size: 18px;
                margin-bottom: 14px;
            }

            p {
                font-size: 15.5px;
                line-height: 22px;
                margin-top: 12px;
            }
        }

        $stepsBorderStyle: 3px solid $colorRed;

        // set up mixin for step's circle icon
        @mixin steps-circle-icon {
            position: absolute;
            content: '';
            background-image: url($PUBLIC_IMG_URL+"/icons/dot-step.svg");
            width: 100px;
            height: 100px;
            background-size: contain;
            background-repeat: no-repeat;
        }

        .row {
            z-index: 1;
            position: relative;

            // desktop layout
            @media (min-width: $interviewStepsBreakpoint_desktop) {
                [class^="col-"] {
                    padding: 60px;

                    &:last-child {
                        padding-bottom: 0;
                    }

                    &:nth-of-type(1) {
                        border-top: $stepsBorderStyle;
                        border-right: $stepsBorderStyle;

                        &:before {
                            @include steps-circle-icon();
                            top: -48px;
                            left: -48px;
                        }
                    }

                    &:nth-of-type(2) {
                        border-bottom: $stepsBorderStyle;

                        &:before {
                            @include steps-circle-icon();
                            top: 50px;
                            left: -48px;
                        }
                    }

                    &:nth-of-type(3) {
                        &:before {
                            @include steps-circle-icon();
                            top: -48px;
                            left: 50px;
                        }
                    }
                }
            }

            // mobile layout
            @media (max-width: $interviewStepsBreakpoint) {
                max-width: 650px !important;

                [class^="col-"] {
                    padding: 0 0 40px 40px;
                    border-left: $stepsBorderStyle;

                    &:last-child {
                        padding-bottom: 0;
                    }

                    &:nth-of-type(1) {
                        &:before {
                            @include steps-circle-icon();
                            top: -48px;
                            left: -48px;
                        }
                    }

                    &:nth-of-type(2),
                    &:nth-of-type(3) {
                        &:before {
                            @include steps-circle-icon();
                            top: -8px;
                            left: -48px;
                        }
                    }
                }
            }
        }

        .interview-steps-logo {
            position: absolute;
            border: $stepsBorderStyle;
            border-radius: 6px;
            padding: 14px;
            max-width: 124px;
            top: -32px;
            right: 0;
            background: #F8F8F8;

            // hide on mobile
            @media (max-width: $interviewStepsBreakpoint) {
                display: none;
            }
        }

        .lines-graphic.lines-graphic-left {
            bottom: 75px;
            left: 17%;
            width: 600px;

            // hide on mobile
            @media (max-width: $interviewStepsBreakpoint) {
                display: none;
            }
        }
    }
}

/* === values section === */
// reused for various landing pages

.values-flip-section {
    padding: 50px;
    background-color: #F8F8F8;

    & > div {
        margin: 0 auto;
        max-width: 1100px;
    }

    .careers-heading {
        margin-bottom: 20px !important;
    }

    .row {
        justify-content: center;

        & > [class^='col-'] {
            margin-top: 30px;
        }
    }

    .flip-card {
        cursor: pointer;
        width: 100%;

        // mobile
        @media (max-width: 810px) {
            height: 185px;
        }

        // desktop
        @media (min-width: 811px) {
            height: 165px;
        }

        .flip-card-inner {
            width: 100%;
            height: 100%;                

            background-color: $colorWhite;
            box-shadow: $boxShadowRed;
            border-radius: 14px;
            text-align: center;
            position: relative;

            // flex
            display: flex;
            flex-direction: column;
            justify-content: center;

            // for flip animation
            perspective: 1000px;
            transition: transform 0.8s;
            transform-style: preserve-3d;

            p {
                font-size: 16px;
                line-height: 22px;

                &.desc-text {
                    text-align: left;
                    margin: 8px;
                    font-size: 15px;
                    line-height: 21px;
                    max-width: 300px;
                }
            }

            img {
                margin-bottom: 12px;
                height: 48px;
                width: auto;
            }
        }

        /* do horizontal flip */
        .transform-class {
            transform: rotateY(180deg);
        }

        .flip-card-icon {
            height: 35px;
            cursor: pointer;

            // positioning
            position: absolute;
            top: 0;
            right: 0;
            margin: 10px;
        }

        /* position the front and back */
        .flip-card-front,
        .flip-card-back {
            position: absolute;
            width: 100%;
            left: 0;
            padding: 18px;

            // flex
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: center;

            /* safari fix - both sides display at once*/
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
        }

        .flip-card-front {
            .flip-card-icon {
                content: url($PUBLIC_IMG_URL+"/icons/plus-red.svg");
            }
        }

        .flip-card-back {
            transform: rotateY(180deg);

            .flip-card-icon {
                content: url($PUBLIC_IMG_URL+"/icons/minus-red.svg");
            }
        }
    }
}