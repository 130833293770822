$dir_mobile_breakpoint: 960px;

.directory-table {
    font-size: 14px;
    position: relative; // fix locked content msg from scrolling with table
    scroll-margin-top: 155px; // adjust position for scrollIntoView()

    .table-responsive {
        scroll-behavior: smooth;
    }

    .table {
        margin-top: 0 !important;

        thead tr,
        tbody tr:nth-of-type(even) {
            background-color: $colorWhite;
        }

        // striped
        tbody tr:nth-of-type(odd) {
            background-color: #F8F8F8;
        }

        th {
            font-size: 15px;
        }

        td {
            font-size: 14px;
        }

        th, td {
            border: none !important;
            vertical-align: middle !important;
            max-width: 350px;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 10px 12px;
            // take width of longest item + don't wrap text
            white-space: nowrap;
            // vertic align text
            padding-top: 0px;
            // hide cut off text
            padding-bottom: 0px;

            /* min-width and max-width are required even though width is specified, as the table column width gets adjusted based on rest of the columns and doesn't always respect width */

            &[data-rowtype="name"] {
                font-size: 14px;
                width: 260px;
                min-width: 260px;
                max-width: 260px;

                .startupLogo {
                    cursor: auto;
                }
            }

            &[data-rowtype="funding-sub"] {
                width: 247px;
                min-width: 247px;
                max-width: 247px;
            }

            &[data-rowtype]:not([data-rowtype="name"]):not([data-rowtype="funding-sub"]) > div {
                // medium data length
                max-width: 200px;
                min-width: 160px;
                // wrap text, cut after 2 lines
                white-space: normal;
                display: -webkit-box;
                overflow: hidden;
               -webkit-box-orient: vertical;
               -webkit-box-pack: center;
               -webkit-line-clamp: 2;
            }

            // long data length
            &[data-rowtype="description"] > div {
                min-width: 300px !important;
            }

            &[data-rowtype="industry_data"] {
                min-width: 225px;
            }

            &[data-rowtype="investors_data"] {
                min-width: 165px;
            }

            // investor list in directory tables
            // rowtype = field specified in selectableFieldsConfig
            &[data-rowtype="investors"],
            &[data-rowtype="investors_data"],
            &[data-rowtype="previous_investors_data"],
            &[data-rowtype="team_data"],
            &[data-rowtype="entity_data"] {
                padding-top: 8px !important;
                padding-bottom: 5px !important;

                .startupLogo {
                    height: 30px;
                    width: 30px;
                }

                > div {
                    min-width: 150px;
                }

                > div a + a {
                    margin-top: 5px;
                }

                a[data-toggle="modal"] {
                    color: $colorRed;
                    display: inline-block;
                    align-items: center;
                    display: flex;
                    margin-top: 1px;

                    i {
                        margin-left: 7px;
                        font-size: 10px;
                        margin-top: 2px;
                    }
                }

                h2 {
                    font-size: 16px;
                }
            }

            .messageBtn {
                margin-top: 4px;
                padding: 1px 7px;
                margin-right: 10px;
                width: 85px;
                font-size: 13px;
                color: $colorRed;
                border: none;
                cursor: pointer;
                padding-left: 0;
                text-align: left;
                display: block;

                i {
                    margin-right: 3px;
                }
            }

            .startup-name {
                white-space: pre-wrap;
                font-weight: 600;
            }

            // lock icon for paid fields for unsubscribed users
            .btn-main-outline {
                border: 1px solid $colorBlack;
                color: $colorBlack !important;

                .fa-lock {
                    & + span {
                        display: inline-block !important;
                        margin-left: 5px;
                    }
                }
            }

        }

        // pinned row
        th:first-child, td:first-child {
            background: inherit;
            box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.1);
            padding-top: 10px;
            padding-bottom: 10px;

            @media (min-width: 600px) {
                position: sticky;
                left: 0;
            }
        }

        // add extra padding for the second + last th
        // so scroll icon does not cover the header text if the column is short
        thead tr th:last-child {
            padding-right: 20px !important;
        }

        tbody tr td:nth-child(2),
        thead tr th:nth-child(2) {
            padding-left: 15px !important;
        }

        // scroll icons
        // font awesome bug - unicode icon doesn't work in :after
        th .table-scroll {
            display: none;
        }

        th:nth-child(2),
        th:nth-child(3) {
            .table-scroll {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                background: $colorWhite;
                border-radius: 50%;
                margin-top: -2px;
                height: 24px;
                width: 24px;
                box-shadow: 0px 0px 5px rgba(23, 29, 48, 0.2);
                cursor: pointer;

                &:before {
                    border: solid gray;
                    border-width: 0 2px 2px 0;
                    display: inline-block;
                    padding: 2.5px;
                    content: "";
                }
            }
        }

        // left icon
        th:nth-child(2) .table-scroll {
            margin-left: -28px;

            &:before {
                transform: rotate(135deg);
                -webkit-transform: rotate(135deg);
                margin-left: 3px;
            }
        }

        // right icon
        th:nth-child(3) .table-scroll {
            right: -12px;

            @media (max-width: 900px) {
                right: 7px;
            }

            &:before {
                transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
                margin-left: -2px;
            }
        }

    }
}

// investors - exit + funding rounds dir
[id^=investors-modal],
[id^=teams-modal] {
    .modal-dialog {
        z-index: 40;
        margin-top: 105px;

        .modal-header {
            h5.modal-title {
                font-size: 16.5px;
            }

            button.close span {
                font-size: 25px !important;
            }
        }

        .modal-body {
            a + a {
                margin-top: 15px !important
            }

            .startup-name {
                font-size: 14px;
            }
        }

        .startupLogo {
            height: 30px;
            width: 30px;
            border: 1px solid $colorTableBorder;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;

            h2 {
                font-size: 17px;
            }
        }
    }
}

.directory-filters {

    background: $colorWhite;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);

    .filterDropdown {
        background-color: $colorWhite;
        position: absolute;
        z-index: 9;
        box-shadow: 4px 5px 20px 0px rgba(0, 0, 0, 0.09);
        margin-top: 5px;
        width: 299px !important;
        border-radius: 5px;

        @media (min-width: 600px) and (max-width: 768px) {
				border: 15px solid #0000007a;
				border-bottom: 1000px solid #0000007a;
				width: 100% !important;
				outline: none;
				left: 0;
				position: fixed;
				top: 115px;
				width: 75% !important;
				left: 12.5%;
				-moz-background-clip: padding;
				-webkit-background-clip: padding;
				background-clip: padding-box;
				outline: 1000px solid #0000007a;
				border: none;
				height: auto;
				border-radius: 0px;
            * {
                font-size: 16px !important;
                background-color: $colorWhite;
            }
        }

        @media (max-width: 600px) {
            border: 15px solid #0000007a;
            border-bottom: 1000px solid #0000007a;
            width: 100% !important;
            outline: none;
            left: 0;
            top: -5px;
            background-color: $colorWhite;
            position: fixed;

            & > div:first-child {
                padding-top: 50px !important;
            }

            * {
                font-size: 15px !important;
            }

            ul {
                // take width of the list if its short
                height: auto;
                max-height: 57vh !important;
            }
        }

        ul {
            .list {
                margin-top: 10px;
            }
        }

        & > div:first-child {
            margin: 0 !important;
            padding-bottom: 12px !important;

            & > div {
                // btn + tags container
                padding: 12px;
                padding-bottom: 0;
                margin-bottom: 0 !important;

                // buttons
                & > a {
                    padding: 10px 25px !important;
                    display: inline-block;
                    margin-right: 12px !important;
                }
            }
        }

        .view-selected-ops {
            max-height: unset !important;
        }

        .multiselect-btn {
            display: inline;
            width: 100%;

            .btn {
                border-radius: 4px;
                font-size: 13px;
                margin-right: 10px;
                padding: 3px 12px;

                &:disabled {
                    cursor: not-allowed;
                }
            }
        }

        .multiselect-icon {
            margin-right: 6px;
            font-size: 14px;
        }

        .multiselect-no-options-msg {
            font-size: 13px;
            margin: 10px;
            margin-top: 0;
        }
    }

    .filterBox input:not(:last-child) {
        @media (min-width: 600px) and (max-width: 768px) {
            position: fixed;
            top: 120px;
            left: 12.5%;
            margin: 12px;
            height: 35px !important;
            width: 400px !important;
            -moz-background-clip: padding;
            -webkit-background-clip: padding;
            background-clip: padding-box;
            border: 1px solid $colorBorder !important;
            border-radius: 0px;
            outline: none;
            font-size: 16px !important;
            z-index: 10;
        }

        @media (max-width: 600px) {
            top: 30px;
            left: 28px;
            width: 87% !important;
            position: fixed;
            z-index: 11;
            border: 1px solid #e8e8e8 !important;
        }
    }

    .title {
        display: flex;
        justify-content: space-between;

        @media (max-width: $dir_mobile_breakpoint) {
            display: block;

            .btn {
                margin-top: 10px;
            }
        }

        * {
            color: $colorRed;
        }

        @media (max-width: 991px) {
            border-top-left-radius: 0px !important;
            border-top-right-radius: 0px !important;
            cursor: pointer;
        }

        i {
            font-size: 14px;
            line-height: 18px;

            &.fa-save {
                font-size: 16px;
            }
        }

        h3 {
            font-size: 16px;
            margin-left: 8px;
            font-weight: 600;
        }
    }

    .filters-header {
        @media (max-width: $dir_mobile_breakpoint) {
            margin-top: 15px;
        }

        p {
            font-size: 15px;

            span {
                letter-spacing: 0.4px;
            }
        }

        .sub-bold {
            color: #9F9F9F;
            font-weight: 600;
        }
    }

    hr {
        border-top: 0.75px solid #e5e5e5;

        &:not(.between) {
            margin: 15px -15px;
        }

        &.between {
            @media (min-width: 767px) and (max-width: 991px) {
                display: none;
            }
        }
    }

    i.fa.fa-chevron-up,
    i.fa.fa-chevron-down {
        font-size: 11px;
    }

    // range slider
    .rc-slider {
        width: 96%;
        margin: 0 auto;
        margin-bottom: 15px;

        .rc-slider-track {
            background-color: $colorTableBorder;
        }

        .rc-slider-handle {
            border: solid 2px $colorRed;

            &:focus {
                box-shadow: unset;
            }
        }
    }

    .number-range {
        label {
            margin: 0;
        }

        .row {
            display: flex;
            align-items: center;
            margin-top: 12px;

            .ticketsize-select {
                width: 60%;
                height: 34px;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                padding: 2px 3px;
            }

            input {
                border: 0.75px solid #e5e5e5 !important;
                width: 40% !important;
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
            }

            .number-filter {
                width: 100% !important;
                border-radius: 5px !important;
            }
        }
    }

    .filters-container {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        .view-selected-ops {
            margin-top: 10px !important;
            max-height: 61px;

            > div {
                width: max-content;
                background: $colorWhite;
            }
        }

        .view-filters-btn {
            padding: 5px 10px;
            min-width: 150px;
            margin-top: 10px;

            i {
                margin-right: 8px;
            }
        }
    }

    .dir-filter-input {
        flex-shrink: 0;

        // desktop
        @media (min-width: $dir_mobile_breakpoint) {
            width: 300px;
            display: inline-block;
            margin-right: 12px;
            margin-top: 12px;
        }

        // mobile
        @media (max-width: $dir_mobile_breakpoint) {
            margin-top: 20px;
            width: 100%;
        }
    }

    .filter-box {
        padding: 15px;
        padding-top: 0px;
    }

    // tablet
    @media (min-width: 767px) and (max-width: 991px) {
        .filter-margin {
            margin-top: 25px;
        }
    }

    .helptext {
        i {
            color: $colorGrey3;
            margin-right: 0 !important;
            margin-left: 5px;
            font-size: 20px;
        }
        i:hover {
            color: $colorRed
        }
    }
}

.saved-search-list {
	display: block;

	.saved-search-delete-btn {
		float: right;
		font-size: 14px;
		i {
			margin-right: 0.3rem;
		}
	}

	&:not(:last-child) {
		margin-bottom: 15px;
	}

	a {
		font-size: 15px;

		&[data-selected="true"] {
			color: $colorRed;
			text-decoration: underline !important;
		}

		&:before {
			content: "\f002";
			font-family: "Font Awesome 5 Free";
			font-weight: 900;
			color: $colorRed;
			margin-right: 7px;
		}
	}

	span {
		font-size: 13px;
		margin-left: 5px;
	}

	&:hover {
		color: $colorRed;

		a,
		.saved-search-delete-btn {
			color: $colorRed;
		}

	}
}

.filters {
    margin-top: 0 !important;

    @media (max-width: 991px) {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .clear-filters-box {
        border: none;
    }

    .sort-btn-container {
        @media(min-width: 961px) {
            .btn {
                margin-left: 10px !important;
            }
        }

        @media(max-width: $dir_mobile_breakpoint) {
            .btn {
                margin-top: 10px !important;
            }
        }
    }

    .firstLine {
        > div:first-child {
            padding: 8px 0px 8px 12px;
        }

        .sort-btn-container {
            margin: 0 0 0 auto;

            .list {
                margin-left: auto;

                li {
                    text-align: left;
                }
            }

            * {
                color: $colorRed;
                font-size: 14px;
            }

            i {
                margin-right: 8px;
            }

            span.sort-label {
                font-weight: bold;
                margin-right: 5px;
            }

            .btn-disable {
                background-color: $colorBorder !important;
                pointer-events: none;
                border: solid 1px $colorBlack !important;

                i, span, a {
                    color: $colorBlack !important;
                }
            }

            .view-actions-btn {
                .disable-list {
                    border: none !important;
                    background-color: $colorWhite !important;
                    padding-left: 0px;
                    padding-right: 0px;
                    display: block;
                    width: 100%;

                    div, i  {
                        color: $colorBlack !important;
                        text-align: left;
                    }

                    &:disabled {
                        cursor: not-allowed;

                        div, i  {
                            color: $colorGrey3 !important;
                        }
                    }
                }

                .dropdown-divider {
                    margin: 0;
                    margin-bottom: .5rem !important;
                }
            }
        }
    }

    .mobilefilter-main-wrap {
        .mobile-secondLine-filter-label a {
            background: none !important;
            width: auto !important;
            height: auto !important;
            margin-top: 0 !important;
        }
    }

    .btns-container {
        flex-wrap: wrap;

        .dropdown .dropdown-menu {
            border-radius: 5px;
            box-shadow: rgba(0, 0, 0, 0.09) 4px 5px 20px 0px;
            border: none;

            .dropdown-item {
                color: $colorBlack;
                font-size: 14px;
                padding: 4px 14px;

                &:hover {
                    background-color: unset;
                    color: $colorRed;
                }

                i {
                    font-size: 16px;

                    &:not(.fa-spin) {
                        margin-right: 6px;
                    }

                    &.fa-spin {
                        margin-left: 6px;
                    }
                }
            }
        }
    }

    .secondline-btns-container {
        display: flex;
        background: $colorWhite;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;
        flex-wrap: wrap;

        @media(max-width: $dir_mobile_breakpoint) {
            margin-top: 0;
            display: block;

            .btn,
            .btn-group {
                margin-top: 10px;
            }
        }

        .sort-btn-container {
            margin: 0 0 0 auto;

            .list div {
                right: auto !important;
                padding-top: 0;
            }
        }
    }

    .btn {
        margin-left: 10px;
        white-space: nowrap;
        font-size: 14px;

        @media(max-width: $dir_mobile_breakpoint) {
            margin-left: 0;
            margin-right: 10px;
        }

        i {
            margin-right: 8px;
        }

        &.btn-main {
            i {
                color: $colorWhite;
            }
        }
    }

}

/* dir table top */

.directory {
    .filters {
        padding: 20px;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;

        h1 {
            padding: 0 !important;
        }

        .firstLine {
            padding: 0 !important;
            margin: 0;

            @media(max-width: $dir_mobile_breakpoint) {
                display: block !important;
            }

            @media(max-width: 600px) {
                .directory-header {
                    align-items: flex-start !important;
                    flex-direction: column;
                }

                .directory-count {
                    .count-text{
                        margin-left: 42px;
                    }
                }
            }

            > div:first-child {
                padding: 0;
            }

            .directory-header {
                display: flex;
                align-items: center;
            }

            .directory-name {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-right: 10px;

                .dir-image {
                    height: 34px;
                    margin-right: 8px;
                    border: 1px solid $colorRed;
                    border-radius: 50%;
                    padding: 7px;
                    width: 34px;
                    display: flex;
                    align-items: center;

                    i {
                        color: $colorRed;
                        font-size: 18px;
                    }
                }

                .beta-badge {
                    background: $colorRed;
                    margin-left: 0.5rem;
                    color:#f6f9fc;
                    font-size:12px !important;
                    margin-top  : 2px;
                }
            }

            .directory-count {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .helptext {
                    color: $colorGrey3 !important;
                    margin: 0;
                    margin-left: 10px !important;
                    width: fit-content !important;
                    font-size: 16px !important;
                    padding: 0 !important;
                }

                .helptext:hover {
                    color: $colorRed !important;
                }
            }
        }

        .funding-count {
            margin-left: 42px;
        }

        .clear-all-btn {
            color: $colorBlack !important;
            border: solid 1px $colorBlack;
            padding: 2px 8px;
            margin: 0px !important;
            margin-left: 10px !important;

            span, i {
                color: $colorBlack !important;
            }
        }

        .clear-all-btn:hover {
            color: $colorRed !important;
            border: solid 1px $colorRed;

            span, i {
                color: $colorRed !important;
            }
        }
    }

    .content-widgets-row {
        @media (max-width: 1300px) {
            display: none;
        }
    }

    .network-map-cards .map-card-wrapper .my-masonry-grid .my-masonry-grid_column section {
        background: transparent !important;
    }
}

.funding-chart {
    background: $colorWhite;
    margin: 30px 0;

    &__heading {
        font-size: 16px;
        font-weight: 400;
    }
}

// directory selectable fields
.selectable-fields {
    margin-bottom: -6px;
    column-count: 2;

    .custom-checkbox {
        display: block;
        margin-bottom: 6px;

        .custom-control-label {
            font-size: 14px;
        }

        // disabled style
        .custom-control-input:disabled,
        .custom-control-input[disabled] {
            ~ .custom-control-label::before {
                background-color: #e9ecef;
                font-family: "Font Awesome 5 Free";
                font-weight: 600;
                content: " \f023";
                color: $colorBlack;
                font-size: 10px;
                text-align: center;
            }

            ~ .custom-control-label,
            ~ .custom-control-label::after {
                cursor: auto;
            }
        }

    }
}

/* scroll bar */

$scrollbar_size: 8px;

.directory-table .table-responsive {
    &::-webkit-scrollbar,
    &::-webkit-scrollbar:hover {
        height: $scrollbar_size;
    }
}

.slider-container .slider-scrollable {
    &::-webkit-scrollbar,
    &::-webkit-scrollbar:hover {
        width: $scrollbar_size;
    }
}

.directory-table .table-responsive,
.slider-container .slider-scrollable {
    &::-webkit-scrollbar {
        z-index: 999999;
        opacity: 0.1;
    }

    &::-webkit-scrollbar:hover {
        opacity: 1;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        z-index: 999999;
        background: $scrollbar_track;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $scrollbar_thumb;
        border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $scrollbar_thumb_hover;
    }
}

.slider-backdrop {
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: -30;
	background: $colorPureBlack;
	opacity: 0; 
	top: 0;
	left: 0;
	display: block; 
	transition: opacity 0.3s ease; 
}

.directory-paging {
    background: $colorWhite;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    border: 0.75px solid rgba(0, 0, 0, 0.1);
    border-top: none;

    @media (max-width: 550px) {
        flex-direction: column-reverse;

        .form-group .form-control {
            width: 100px;
            flex: unset !important;
        }

        .pagination {
            margin-bottom: 15px;
        }

        .pagination,
        .form-group {
            justify-content: center;
        }
    }

    .form-group {
        display: flex;
        align-items: center;
        margin-bottom: 0 !important;

        label {
            font-size: 14px;
            margin-bottom: 0;
            margin-right: 5px;
            width: auto;
        }

        .form-control {
            flex: 1 0;
            height: calc(1.5em + 0.5rem + 2px);
            font-size: 14px !important;
        }
    }

    .pagination {

        * {
            font-size: 14px !important;
        }

        .page-link {
            color: $colorBlack;

            &:hover {
                background-color: inherit;
                color: $colorRed !important;
            }

            &:focus {
                box-shadow: unset;
            }

            i {
                color: $colorRed;
                margin-left: 5px;
            }
        }

        // mobile
        @media (max-width: $dir_mobile_breakpoint) {
            .page-link {
                padding: 10px 20px;
                display: flex;
                align-items: center;
                height: 100%;

                span {
                    font-size: 25px !important;
                    line-height: 0;
                    margin-top: -3px;
                }
            }
        }

    }
}

#dataExportModal {
    .modal-header {
        padding: 15px;
    }
}

/* filter modal */

#filterModal {

    .directory-filters {
        box-shadow: unset;

        .filter-box {
            padding: 0;
				.input-section-description{
					width: 100%;
					margin-bottom: 0px;
					font-weight: 600;
				}
            .dir-filter-input {
                label {
                    font-size: 14px;
                }

                // section heading label
                & ~ label {
                    width: 100%;
                    margin-bottom: 0px;
                    font-weight: 600;
                }

                .dual-input {
                    display: flex;

                    > .react-datepicker-wrapper input {
                        padding: 6px 0 6px 10px;
                        font-size: 14px;
                    }

                    > .react-datepicker-wrapper:nth-child(1) input {
                        border-top-right-radius: 0px;
                        border-bottom-right-radius: 0px;
                    }

                    > .react-datepicker-wrapper:nth-child(2) input {
                        border-top-left-radius: 0px;
                        border-bottom-left-radius: 0px;
                    }
                }

					 .MultiTreeSelectFilter{
							 .dropdown-toggle-icon,.dropdown-toggle-icon-child{
									margin-top: -3.5px;
									cursor: pointer;
								i {
										font-size: 14px;
									}
							 }
							.dropdown-toggle-icon-child{
									margin-top: -0.5px;
							}
					 }
            }

            hr {
                margin: 20px 0;
                width: 100%;

                @media (max-width: $dir_mobile_breakpoint) {
                    margin: 30px 0;
                }
            }
        }
    }

    .modal-header {
        padding: 15px 20px;
    }

    .modal-body,
    .modal-footer {
        padding: 20px;
    }

    .modal-body {
        padding-top: 10px;
    }

    .modal-header {
        display: block;
        align-items: center;

        .title {
            padding: 0;
            width: 100%;

            h3 {
                font-size: 18px;
            }

            i {
                line-height: 23px;
            }
        }

        .close {
            margin-left: 12px;

            span {
                color: $colorBlack;
                line-height: 31px;

                @media (max-width: $dir_mobile_breakpoint) {
                    position: absolute;
                    top: 5px;
                    right: 10px;
                }
            }
        }

        .filters-header p {
            margin-top: 5px;
        }

        .btn i {
            margin-right: 8px;
        }
    }

    .modal-body {
        .view-selected-ops {
            margin-bottom: 0;
        }

        .form-control {
            font-size: 14px;
            color: #5d656de0;
            padding: 6.5px 9px;
            border: 0.75px solid #E5E5E5 !important;
        }

        // @media(min-width: 600px) {
        //     max-height: 65vh;
        //     overflow-x: hidden;
        //     overflow-y: scroll;
        // }
    }

    .modal-footer {
        .btn {
            min-width: 150px;

            @media (max-width: $dir_mobile_breakpoint) {
                width: 47%;
                min-width: unset;
                max-width: 200px;
            }
        }
    }
}

/* === startup directory table and chart view === */

.startup-nav-bar,
.investor-view-btn {
    display: flex;
    margin: 0 -20px;

    .btn {
        padding: 4px 20px !important;
        white-space: nowrap;
        margin: 0 !important;
        font-size: 16px;
        color: $colorBlack !important;
        border: solid 2px $colorBorder;
        border-bottom: none;
        border-left: none;
        border-radius: 0;
        border-top-right-radius: 7px !important;
        border-top-left-radius: 7px !important;
        flex-grow: 0;
    }

    .btn:first-child {
        margin-left: -20px;
        border-top-left-radius: 0 !important;
    }

    .btn-main {
        background-color: $colorWhite;
        color: $colorRed !important;
        border-bottom: solid 2px $colorRed;

        i {
            color: $colorRed !important;
        }
    }

    @media (max-width: 834px) {
        overflow-x: auto;

        &::-webkit-scrollbar,
        &::-webkit-scrollbar:hover {
            height: 8px;
        }

        &::-webkit-scrollbar {
            z-index: 999999;
            opacity: 0.1;
        }

        &::-webkit-scrollbar:hover {
            opacity: 1;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            z-index: 999999;
            background: $scrollbar_track;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $scrollbar_thumb;
            border-radius: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: $scrollbar_thumb_hover;
        }
    }
}

.selected-filter-tooltip {
    cursor: pointer;

    i {
        margin-left: 5px;
    }
}

.directory-landscape {
	.network-map-groups {
        margin: 0 !important;

        .segmentation-label {
            font-weight: bold;
            margin-right: 5px;
        }

        .segmentation-container {
            box-shadow: 4px 5px 20px 0px rgba(0, 0, 0, 0.09);
            position: absolute;
            z-index: 9;
            border-radius: 5px;
            background-color: $colorWhite;
            padding: 12px 10px 0px;
            right: -8px;
            margin-top: 5px;
        }

		.group-buttons {
			font-size: 14px !important;
			padding: 0;
            display: block;
            width: 100%;
            text-align: left;
            margin: 0;
		}

        .btn {
            margin-right: 0px !important;
        }
	}
}

.clear-filters-box {
    background: $colorWhite;
    border: 0.75px solid rgba(0, 0, 0, 0.1);
    border-top: none;
    padding: 20px;
}

// styles to control element visibility for export
.my-masonry-grid.is-generating-export {

    // only display first 9 boxes
    .my-masonry-grid_column .map-card:nth-child(n+3) {
        display: none;
    }

    // hide 'show more'
    .card__more {
        display: none;
    }

    // only show first 12 entity logos
    .map-card__items span:nth-child(n+13) {
        display: none;
    }

}
