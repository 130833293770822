.contact-page {
	max-width: 1200px !important;
	margin: 0 auto !important;

	.bg-img {
		background-image: url($PUBLIC_IMG_URL+"/bg/map.png");
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		border: 2px solid $colorWhite;
	}

	.demo-box {
		margin: 0 !important;
		max-width: 100% !important;
	}
}

.contact-form-box {

	// bugfix: cursor overlays placeholder txt
	[id^=react-select-] {
		caret-color: transparent;
	}

	h6 {
		color: $colorWhite;
		font-weight: 600;
		margin-bottom: 22px;
		line-height: 24px;
		font-size: 18px;
		text-align: center;
	}
}

.contact-page {
	.contact-form-box {
		.contact-form {

			.row {
				margin-left: -10px;
				margin-right: -10px;

				&>[class^='col'] {
					padding: 0 6px;
				}
			}

			input {
				margin-bottom: 12px;
			}

		}
	}
}