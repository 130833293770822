

.redText {
    font-weight: 600;
    color: $colorRed;
}

.content-block .right-side .messageBlock .jobsContent .jobSingle .infoBlock {
    float: left;
    width: 65%;
}
.content-block .right-side .messageBlock .jobsContent .jobSingle .borderimg {
    border: 1px solid #D6D8DA;
    border-radius: 4px;
    padding: 2px;
    width: 86px;
    height: 86px;
    margin: 0 15px 20px 0;
    float: left;
}
.content-block .right-side .messageBlock .jobsContent .jobSingle .img {
    background-size: cover !important;
    width: 80px;
    height: 80px;
}
.content-block .right-side .messageBlock .jobsContent .jobSingle i {
    display: block;
    text-align: right;
    cursor: pointer;
    margin-bottom: 20px !important;
}
.content-block .right-side .messageBlock .jobsContent .jobSingle i span {
    display: inline-block;
    width: 6px;
    height: 6px;
    background: #D6D8DA;
    border-radius: 3px;
    margin: 0 2px !important;
}

.content-block .right-side .messageBlock .jobsContent .jobSingle .costs {
    position: relative;
}
.content-block .right-side .messageBlock .jobsContent .jobSingle .deleteConnection {
    display: block;
    background: $colorWhite;
    -webkit-box-shadow: 0 0 10px $colorPureBlack;
    box-shadow: 0 0 2px $colorPureBlack;
    height: 36px;
    width: 156px;
    color: $colorRed;
    position: absolute;
    top: 30px;
    right: 0;
    padding: 0;
    text-transform: none;
    text-align: center;
    border: 0;
    font-size: 12px;
    line-height: 36px;
}

.formBlock {
    width: 59%;
    float: right;
    background: #EEEFF2;
}

.formBlock textarea {
    width: calc(100% - 40px);
    min-height: 60px;
    margin: 20px;
}

.formBlock a {
    background: $colorBlack1;
    padding: 5px 15px;
    float: right;
    color: $colorWhite;
    margin: 0 20px 20px 0;
}

.formBlock a:hover {
    background: $colorRed;
}