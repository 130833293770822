/* === pricing === */

.pricing {
    .page-header:first-of-type {
        @media (max-width: 600px) {
            padding: 0px 20px;
            margin-top: 10px;
        }
    }
}

.form-box-layout {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;

    @media (max-width: 991px) {
        padding-bottom: 20px;
    }

    .error {
        border: 1px solid #D22248a8 !important;
    }

    .react-select-error.__control {
        border: 1px solid #D22248a8;
    }

    label {
        width: 100%;
        font-weight: normal;
        margin-bottom: 10px;
        font-size: 14px !important;
        line-height: 22px;
    }

    .custom-control-label {
        margin-bottom: 0 !important;
    }

    label:not(:first-of-type) {
        padding-top: 13px;
    }

    input,
    select:not(#pagination-limit-select),
    textarea,
    label ~ .margin-reset,
    label ~ [class$="-container"] {
        width: 100%;
        border-radius: 4px;
        font-size: 14px !important;
        margin-bottom: 25px !important;
        line-height: 23px;
        color: $colorBlack;
    }

    input,
    select:not(#pagination-limit-select),
    textarea {
        border: 1px solid $colorBorder !important;
    }

    input {
        padding: 7px 10px !important;
    }

    select:not(#pagination-limit-select) {
        padding: 10px 5px;
    }

    select:disabled {
        opacity: 1;
    }
}

.form-box-layout {

    .shadow-box {
        padding: 0;
        overflow: hidden;
    }

    .plan-details {
        padding: 30px;

        .focus {
            font-size: 14px;
        }
    }
}

/* === img box element === */

.demo-box {
    h4 {
        margin-bottom: 10px;
        font-size: 22.5px;
        letter-spacing: 0.175px;
        line-height: 28px;
        font-weight: 700;
    }
}

.demo-box.img-left {
    background: $colorWhite;
    box-shadow: 0 40px 40px -20px rgba(23, 29, 48, 0.2);
    padding: 40px;
    margin: 50px auto;
    max-width: 1100px; // stop element getting too long when sidenav's minimized

    @media(max-width: 1250px) {
        margin: 25px;
    }

    .btn {
        font-size: 17px;
    }

    .demo-export {

        @media (min-width: 576px) and (max-width: 767px) {
            padding-left: 50px;
        }

        @media (max-width: 575px) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        p {
            font-size: 16px;
            padding-right: 10px;

            &:not(:last-of-type) {
                margin-bottom: 15px;
            }

            @media (min-width: 600px) and (max-width: 900px) {
                margin-bottom: 15px;
            }
        }

        .report-text,
        .report-list {
            margin-top: 20px;
            margin-bottom: 20px;

            @media (min-width: 600px) and (max-width: 900px) {
                margin-bottom: 10px;
                margin-top: 10px;
            }

            @media (max-width: 767px) {

                // hide second list to make content shorter on mobile
                &+.report-list:nth-child(1) {
                    display: none;
                }
            }

            @media (max-width: 576px) {
                width: auto !important;
            }
        }

        .report-demo-btns {
            @media (max-width: 576px) {
                justify-content: center;
                display: flex;
            }

            .btn {
                padding: 12px 10px;
                width: 100%;
                max-width: 260px;

                @media (min-width: 500px) and (max-width: 900px) {
                    font-size: 14px;
                    padding: 10px;
                    margin-top: 10px;
                }

                i {
                    margin-right: 10px;
                    font-size: 16px;
                }
            }

            .btn-main-outline {
                @media (max-width: 767px) {
                    margin-top: 20px;
                }

                i {
                    font-size: 19px;
                }
            }

        }
    }

    .demo-graphic {
        justify-content: center;
        display: flex;
        align-items: center;
        margin: -5px;

        @media (min-width: 600px) and (max-width: 767px) {
            padding-left: 25px;
            padding-right: 0;
        }

        img {
            height: 100%;
            max-height: 260px;

            @media (min-width: 767px) and (max-width: 1000px) {
                padding-right: 10px;
            }

            @media (max-width: 767px) {
                max-height: none;
                height: 180px;
                margin-bottom: 30px;
            }
        }
    }
}

/* === pricing, payment shared styles */

.features-list {
    p {
        // indent so list with two lines doesn't wrap under checkmark
        text-indent: -25px;
        margin-left: 25px;
    }

    p::before {
        margin-right: 10px;
        font-size: 15px;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f00c";
    }
}

.report-list {
    p {
        margin: 0;
        margin-bottom: 10px;
        font-size: 15.5px;
        // indent so list with two lines doesn't wrap under checkmark
        text-indent: -25px;
        margin-left: 25px;
    }

    p::before {
        margin-right: 10px;
        font-size: 15px;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f00c";
        color: $colorRed;
    }
}