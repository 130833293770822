/* common */
* {
	font-family: $siteFont;
}

.h {
	color: $colorBlack;
}
.error-text {
 color: $colorRed;
 padding: 0;
 font-size: $fontSize;
}
.mobile-show {
	display:none !important;
}
.mobile-hide {
 display:block !important;
}
.sm-screen-show {
 display:none !important;
}

.feedback-page-container {
    background: $colorWhite;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px auto 80px auto;
    padding: 35px;
    max-width: 540px;

	.feedback-page-icon {
		 font-size: 80px;
		 color: $colorRed;
		 line-height: 0.8;

		 &:not(.hide-shadow) {
			  text-shadow: 20px 20px 20px rgba(0, 0, 0, 0.2);
		 }

		 &.icon-smaller {
			font-size: 45px;

			&:not(.hide-shadow) {
			  text-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
			}
		 }
	}

	h5 {
		 margin-top: 20px;
	}

    .feedback-page-text {
        font-size: 16px;
        line-height: 24px;
        margin-top: 25px;
        text-align: center;
    }

	.btn {
		 margin-top: 50px;
	}
}

@media (max-width:600px){
 .mobile-show
 {
	display:block !important;
 }
 .mobile-hide
 {
	display:none !important;
 }
}

@media (min-width:768px) and (max-width:1024px){
 .sm-screen-show {
	display:block !important;
 }
}

.saved-search{
 width: 30%;
 height: auto;
 padding: 50px;
 z-index: 99999;
 position: fixed;
 top: 50vh;
 left: 50vw;
 overflow: hidden;
 transform: translate(-50%,-50%);
 border-radius: 4px;
 background: $colorWhite;
 &__close{
	position: absolute;
	top: 8px;
	right: 14px;
	opacity: 0.4;
	filter: alpha(opacity=40);
	z-index: 999;
 }
 &__img{
	max-width: 15px;
 }
 @media (max-width: 550px) {
	width:90%
 }
}

.directory-footer {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 margin-top: 30px;
 &__left{
	margin: 0 !important;
	display: flex;
	flex-basis: 100%;
	padding:20px !important;
 }
 &__startups{
	flex-basis: 50%;
 }
 &__investors{
	flex-basis: 50%;
 }

 @media (max-width: 768px){
	flex-direction: column;
 }
 @media (min-width: 768px) and (max-width: 1023px){
	.leftside {
	  display: block !important;
	}
 }
}
