/* === about page === */

.about-page {
    background: #364049;
    overflow: hidden; // fix arc scroll issue

    .page-header {

        h1,
        h2 {
            font-weight: 600;
        }
    }

    .page-header-about {
        margin: 0 auto;
        max-width: 1000px;
        padding: 70px 0px;

        @media (max-width: 600px) {
            padding: 40px;
        }

        @media(min-width: 601px) and (max-width: 1100px) {
            padding: 60px;
        }

        h1 {
            margin-bottom: 25px;

            @media (max-width: 600px) {
                text-align: left;
                margin-bottom: 20px;
            }
        }

        p {
            font-size: 17px;
        }

        p,
        strong {
            color: $colorBlack1;
        }
    }

    section.directory {
        background-image: url($PUBLIC_IMG_URL+"/about/laptop-bg.jpg");
        background-size: cover;

        @media (max-width: 1000px) {
            background-position: 75%;
        }
    }

    // not used but want to keep the styles
    .about-circles {
        display: flex;
        margin-top: 35px;
        justify-content: space-between;

        @media (max-width: 700px) {
            margin-top: 50px;
        }

        .sort-box {
            box-shadow: rgba(23, 29, 48, 0.2) 0px 8px 20px 0px;
            height: 115px;
            width: 115px;
            border-radius: 50%;
            overflow: hidden;

            @media (max-width: 600px) {
                height: 90px;
                width: 90px;
            }

            &:first-child,
            &:last-child {
                margin-top: -35px;
            }

            img {
                margin-bottom: 5px;

                @media (max-width: 600px) {
                    display: none;
                }
            }

            .sort-count {
                font-size: 17px;
                margin-bottom: 1px;
            }
        }
    }

    .arc-bottom {
        box-shadow: unset; // for the black bg
        height: 320px;

        &+.content-box {
            background: transparent;
        }
    }

    $founder_breakpoint_min: 700px;
    $founder_breakpoint_max: calc($founder_breakpoint_min - 1px);

    .about-team {
        background: #F8F8F8;
        padding: 50px 60px 60px 60px;

        // for absolute position line graphic
        position: relative;
        overflow: hidden;

        @media (max-width: 600px) {
            padding: 40px;
        }

        &.pb-0 {
            padding-bottom: 0 !important;
        }

        .page-header {
            margin-top: 0;
            margin-bottom: 35px;
        }

        .row {
            align-items: center;
            justify-content: center;
            max-width: 860px;

            .about-team-img,
            .about-team-txt {
                border-radius: 14px;
                box-shadow: $boxShadowGray;
                align-self: stretch;
            }

            .about-team-txt {
                background: $colorWhite;
                font-size: 16.5px;
                position: relative;
                color: #333;
                line-height: 23px;
                display: flex;
                align-items: center;
                padding: 35px;

                &.about-quote {
                    text-indent: 12px;
                    padding: 28px;
                }

                .about-quotemark {
                    font-size: 30px;
                    font-family: Georgia, serif;
                    line-height: 14px;
                    margin-top: 10px;
                    position: absolute;

                    &:nth-of-type(1) {
                        left: 14px;

                        // large screens
                        @media (min-width: 1500px) {
                            left: 25px;
                        }
                    }

                    &:nth-of-type(2) {
                        margin-left: -10px;
                    }
                }

                @media (max-width: $founder_breakpoint_max) {
                    margin-top: -50px;
                }

                // desktop
                @media (min-width: $founder_breakpoint_min) {
                    margin-left: -30px;
                    width: 60%;
                }

                // large screens
                @media (min-width: 1500px) {
                    padding: 40px;
                }
            }

            .about-team-img {
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;

                // mobile
                @media (max-width: $founder_breakpoint_max) {
                    width: 100%;
                    height: auto;
                    min-height: 380px;
                    aspect-ratio: 1;
                }

                // desktop
                @media (min-width: $founder_breakpoint_min) {
                    width: 40%;
                    min-height: 300px;
                }

                // large screens
                @media (min-width: 1500px) {
                    min-height: 370px;
                }

                @media (min-width: 1700px) {
                    min-height: 430px;
                }

            }
        }

        .about-quote-footer {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-top: 18px;
            text-indent: 0;

            .social-tab {
                text-indent: 0px;
                justify-content: flex-end;
                margin-top: 0;

                a,
                i {
                    color: $colorRed;
                }

                i {
                    border: 1px solid $colorRed;
                    font-size: 16px;
                    height: 34px;
                    width: 34px;

                    @media (max-width: $founder_breakpoint_max) {
                        height: 32px;
                        width: 32px;
                    }
                }
            }

            .btn {
                margin-top: 8px;
                padding: 8px 15px;

                i {
                    margin-right: 8px;
                }
            }
        }

        .lines-graphic {
            // bg
            background-image: url($PUBLIC_IMG_URL+"/vectors/lines.svg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: left bottom;

            // position
            position: absolute;
            width: 100%;
            height: 550px;
            bottom: -90px;
            left: -190px;

            @media (min-width: 1500px) {
                left: 70px;
            }

            @media (max-width: 1100px) {
                display: none;
            }
        }
    }

    $team_breakpoint_mobile: 700px;
    $team_breakpoint_tablet: 1000px;

    .explore-all {
        margin-top: 50px;
        font-weight: 600;

        @media (max-width: $team_breakpoint_mobile) {
            margin-bottom: 20px;
        }

        @media (min-width: $team_breakpoint_mobile) and (max-width: $team_breakpoint_tablet) {
            margin-bottom: 15px;
        }
    }

    .about-managers {
        color: $colorWhite;

        // for absolute pos line graphic
        z-index: 1;
        position: relative;
        overflow: hidden;

        h1 {
            font-size: 32px;
            margin-bottom: 0;
        }

        .row {
            justify-content: center;
            max-width: 1000px;
            z-index: 2;
            align-items: flex-start;

            // prevent box-shadow from getting cut off
            @media (max-width: $team_breakpoint_mobile) {
                margin-left: 0;
                margin-right: 0;
            }

            .team-item {
                width: 220px;
                border-radius: 14px;
                background-color: $colorWhite;
                margin: 50px 25px 0 25px;
                box-shadow: $boxShadowGray;

                // mobile, 2 across
                @media (max-width: $team_breakpoint_mobile) {
                    width: 42.5%;
                    box-sizing: border-box;
                    margin-right: 3.75%;
                    margin-left: 3.75%;
                    margin-top: calc(3.75% * 2);
                }

                // tablet, 3 across
                @media (min-width: $team_breakpoint_mobile) and (max-width: $team_breakpoint_tablet) {
                    width: 33.33%;
                    max-width: 200px;
                    margin-right: 19px;
                    margin-left: 19px;
                    margin-top: calc(19px * 2);
                }

                .member-photo {
                    width: 100%;
                    aspect-ratio: 1;
                    border-top-left-radius: 14px;
                    border-top-right-radius: 14px;
                }
            }

            .investor-item {
                // ensures long names/job titles display correctly
                // on all screens
                width: 165px;

                @media (max-width: 618px) {
                    width: 33.3%
                }
            }

            .team-item,
            .investor-item {
                align-items: center;
                color: $colorWhite;
                display: flex;
                flex-direction: column;
                text-align: center;
                // margin-top: 40px;

                .member-initial {
                    background: $colorWhite;
                    height: 75px;
                    width: 75px;

                    @media(max-width: 618px) {
                        height: 65px;
                        width: 65px;
                    }
                }

                .member-photo {
                    background-position: center center;
                    background-repeat: no-repeat;
                }

                .member-photo {
                    background-size: cover;
                }

                :nth-child(2) {
                    font-size: 16.5px;
                    margin-top: 15px;
                    color: $colorBlack1;
                    font-weight: 600;

                    @media (max-width: 1000px) {
                        font-size: 15px;
                        line-height: 20px;
                        margin-top: 14px;
                    }
                }

                :nth-child(3) {
                    font-size: 14px;
                    color: #A4AAAE;
                    line-height: 19px;
                    margin: 1px 15px 15px 15px;
                }
            }
        }

        hr {
            border-color: $colorWhite;
            border-top: 1px solid $colorWhite;
            width: 100%;
            margin: 3rem 0;
        }
    }

    $timeline_breakpoint_min: 1000px;
    $timeline_breakpoint_max: calc($timeline_breakpoint_min + 1px);

    .about-timeline {
        background: $colorWhite;
        padding: 50px;

        .page-header {
            margin-top: 0;
            margin-bottom: 25px;

            @media (max-width: 801px) {
                margin-bottom: 10px;
            }
        }

        .about-timeline-map {
            width: 100%;
            position: relative;

            // mobile
            @media (max-width: $timeline_breakpoint_max) {
                margin-top: 10px;
                width: 100vw;
            }

            img[data-map-type] {
                width: 100%;
                position: absolute;
                left: 0;
                transition: opacity 0.5s ease-in;
                opacity: 0; // set for default view
            }

            // map - launched in mena
            &[data-selected-map="mena"] img[data-map-type="mena"] {
                opacity: 1;
            }

            // map - launched in pak & turkey
            &[data-selected-map="pak-turkey"] {
                img[data-map-type="mena"],
                img[data-map-type="pak-turkey"] {
                    opacity: 1;
                }
            }

            // map - launched in afr
            &[data-selected-map="africa"] {
                img[data-map-type="mena"],
                img[data-map-type="pak-turkey"],
                img[data-map-type="africa"] {
                    opacity: 1;
                }
            }

            // map - launched in sea
            &[data-selected-map="sea"] {
                img[data-map-type] {
                    opacity: 1;
                }
            }
        }

        .about-timeline-box {
            background-color: $colorWhite;
            border-radius: 16px;
            box-shadow: $boxShadowGray;
            padding: 17.5px 35px;

            // desktop
            @media (min-width: 1301px) {
                position: absolute;
                top: 60px;
                left: 100px;
                width: 300px;
            }

            // tablet
            @media (min-width: $timeline_breakpoint_max) and (max-width: 1300px) {
                position: absolute;
                bottom: 0;
                left: 50px;
                width: 300px;
            }

            // mobile & tablet
            @media (max-width: $timeline_breakpoint_min) {
                position: relative;
                margin: 25px 25px 0 25px;
                width: auto;
                max-width: 500px;
                margin: 0 auto;
                margin-top: 25px;
            }

            // mobile sm
            @media (max-width: 600px) {
                max-width: 450px;
            }

            .timeline-item {
                display: flex;
                align-items: center;
                min-height: 75px;
                position: relative;

                // mobile
                @media (max-width: $timeline_breakpoint_min) {
                    flex-wrap: wrap;
                }

                // 'active' map item in animation
                &.active {
                    .timeline-text {
                        color: $colorRed;
                        font-weight: bold;
                    }

                    .timeline-funding {
                        opacity: 0.9;
                    }
                }

                .timeline-year {
                    color: $colorRed;
                    margin-right: 25px;
                    font-weight: bold;
                    position: relative;
                    background-color: $colorWhite;
                }

                &:not(:last-child) {
                    .timeline-year:after {
                        content: " ";
                        height: 35px;
                        border-left: 1px solid #E4E4E4;
                        position: absolute;
                        left: 20px;
                        top: 32px;
                    }
                }

                .timeline-text {
                    color: $colorBlack1;
                    font-size: 15px;

                    // mobile
                    @media (max-width: $timeline_breakpoint_min) {
                        flex-shrink: 0;
                    }
                }

                .timeline-funding {

                    // styling
                    opacity: 0;
                    transition: opacity 0.5s ease-in;

                    // flex
                    display: flex;
                    align-items: center;
                    position: absolute;

                    // desktop
                    @media (min-width: $timeline_breakpoint_max) {
                        left: 290px;
                        padding: 20px;
                        background-color: $colorWhite;
                        border-radius: 16px;
                        box-shadow: $boxShadowGray;
                    }

                    // mobile & tablet
                    @media (max-width: $timeline_breakpoint_min) {
                        left: 60px; top: 54px;
                    }

                    .investor-item {
                        img {
                            max-width: 80px;
                            max-height: 60px;

                            // mobile & tablet
                            @media (max-width: $timeline_breakpoint_max) {
                                margin-right: 18px;
                                max-width: 60px;
                                max-height: 40px;
                            }
                        }

                        // desktop
                        @media (min-width: $timeline_breakpoint_min) {
                            &:not(:last-child) {
                                margin-right: 35px;
                            }
                        }
                    }

                }
            }
        }
    }

    $solutions_breakpoint_mobile: 700px;
    $solutions_breakpoint_tablet: 1000px;

    .about-solutions {
        padding: 55px;
        padding-top: 42px;
        background-color: $vibrantBlue;

        .page-header {
            margin-top: 0;
            color: $colorWhite;
            margin-bottom: 35px;

            @media (max-width: $solutions_breakpoint_mobile) {
                margin-bottom: 30px;
            }
        }

        .solution-item {
            .btn {
                margin-top: 20px;
                font-size: 15px;
            }
        }

        .solution-info p {
            font-size: 15px;
        }

        .solutions-block {
            & > .row {
                margin: -15px;
                justify-content: center;

                .col-solution {
                    position: relative;
                    flex: 0 0 33%;
                    max-width: 33%;
                    max-width: 360px;

                    @media (min-width: $solutions_breakpoint_tablet) {
                        min-width: 320px;
                    }
                }

                @media (min-width: $solutions_breakpoint_mobile) and (max-width: $solutions_breakpoint_tablet) {
                    justify-content: center;

                    .col-solution {
                        flex: 0 0 50%;
                        max-width: 350px;
                    }
                }

                @media (max-width: $solutions_breakpoint_mobile) {
                    justify-content: center;

                    .col-solution {
                        flex: 0 0 100%;
                        max-width: 400px;
                    }
                }

                &:not(.report-view)>[class^="col-"] {
                    padding: 15px 25px;

                    @media (max-width: $solutions_breakpoint_tablet) {
                        padding: 25px;
                    }

                    .solution-item {
                        background: $colorWhite;
                        box-shadow: 0 40px 40px -20px rgba(23, 29, 48, 0.2);
                        padding: 30px !important;
                        height: 100%; // make all take same height
                        border-radius: 14px;
                        color: $colorBlack1;

                        [class^="col-"]:first-child {
                            display: flex; // vertically center the img
                        }

                        h6 {
                            font-size: 18px;
                            margin-bottom: 15px;
                            text-align: center;
                            margin-top: 25px;
                            line-height: 25px;
                            letter-spacing: 0.25px;
                            font-weight: 600;
                            color: $colorRed;
                        }

                        img {
                            max-height: 50px;
                            margin-left: auto;
                            margin-right: auto;
                            margin-bottom: 25px;
                            width: 100%; // center it
                            margin-top: 5px;
                        }

                        .solution-list {
                            p {
                                // indent so list with two lines doesn't wrap under checkmark
                                text-indent: -25px;
                                margin-left: 25px;
                                font-size: 15px;

                                &:not(:nth-last-child(1)) {
                                    margin-bottom: 10px;
                                }
                            }

                            p::before {
                                margin-right: 10px;
                                font-size: 15px;
                                font-family: "Font Awesome 5 Free";
                                font-weight: 900;
                                content: "\f00c";
                                color: $colorRed;
                            }

                            a {
                                color: $colorRed;
                            }
                        }

                        .solution-subtitle {
                            font-weight: 600;
                            text-align: center;
                            margin-bottom: 10px;
                            position: relative;
                            margin-top: 20px;

                            &:before {
                                content: " ";
                                width: 100px;
                                position: absolute;
                                top: -30px;
                                width: 50px;
                                margin: 0 auto;
                                left: 40.75%;
                                border-top: 2px solid $colorRed;

                                @media (max-width: 600px) {
                                    left: 39%;
                                }
                            }
                        }
                    }
                }
            }

        }

        // style edits for vertical version of .solutions-block

        .solutions-block-vertical {
            .solution-item {
                display: flex;
                flex-direction: column;

                .solution-info {
                    margin-bottom: 22px;
                    text-align: center;
                }

                // push btn to bottom of its container
                .btn {
                    margin-top: auto !important;
                    padding: 8px 12px;
                }
            }
        }
    }

    // override default styles
    .staticpage-demo-container {
        height: unset;
    }

    /* === clients section in demo form === */
    // on larger screens, this section overlaps the section above it

    $clients_overlap_breakpoint_mobile: 600px;
    $clients_overlap_breakpoint_tablet: 1100px;

    // desktop
    .about-demo-form .about-demo-pull-up {
        margin-top: -190px;
    }

    // tablet
    @media (max-width: $clients_overlap_breakpoint_tablet) {
        .about-demo-form .about-demo-pull-up {
            margin-top: -180px;
        }
    }

    // mobile
    @media (max-width: $clients_overlap_breakpoint_mobile) {
        .about-demo-form .about-demo-pull-up {
            margin-top: -150px;

            .about-clients {
                margin-bottom: 35px;
            }
        }
    }

    .about-demo-form .about-demo-pull-up {
        z-index: 1;
    }

    // set padding to offset margin-top above
    section.directory {
        padding-bottom: 100px !important;
    }

    /* === demo form section ===*/

    .about-demo-form {
        background-color: $colorRed;

        h1 {
            font-size: 32px;
            font-weight: 600;
            color: $colorWhite;
            margin-bottom: 10px;
        }

        p.staticpage-demo-title {
            font-size: 26px;
            line-height: 36px;
        }

        .about-clients,
        .staticpage-demo-input {
            width: 100%;
            max-width: 920px;

            @media (min-width: 750px) {
                min-width: 650px;
            }
        }

        .staticpage-demo-input input {
            border: none;
            padding: 26px 15px;
        }

        .staticpage-demo-input input,
        .staticpage-demo-btn {
            border-radius: 10px;
            font-size: 17px;
        }

        .staticpage-demo-btn {
				background-color: adjust-color($colorRed, $lightness: -8.5%);
            font-weight: 600;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            height: unset;
            padding: 12.5px;

            &:before {

                // desktop
                @media (min-width: 800px) {
                    content: "Book Your Demo Now!";
                }

                // mobile
                @media (max-width: 799px) {
                    content: "Book Demo";
                }

                @media (max-width: 600px) {
                    content: "Submit";
                }
            }
        }

        .form-error {
            color: $colorWhite;
        }
    }

    .about-clients {
        background-color: $colorWhite;
        border-radius: 14px;
        padding: 30px;
        margin-bottom: 45px;
        margin-top: 10px;
        box-shadow: $boxShadowGray;

        .page-header {
            margin-top: 0;
            margin-bottom: 25px;
        }

        h2 {
            color: $colorBlack1;
            font-size: 23px;
        }

        .references-box {
            margin-bottom: 0;
            border-bottom: unset;

            @media (max-width: 991px) {
                margin-top: -20px;
            }

            a {
                align-items: center;
                justify-content: center;
                display: flex;
                cursor: unset;
                max-height: 38px;
                max-width: 160px;

                @media (max-width: 600px) {
                    max-width: 148px;
                }

                img {
                    display: block;
                    max-width: 100%;
                    max-height: 100%;
                    width: auto;
                    height: auto;
                }
            }
        }
    }

}