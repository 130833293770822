.fields-grid {
    .custom-checkbox {
        display: inline-block;
        margin-bottom: 6px;
        width: 50%;

        .custom-control-label {
            font-size: 14px;
        }

        // disabled style
        .custom-control-input {
            &:disabled:not(:checked) ~ .custom-control-label::before {
                background-color: #e9ecef;
                font-family: "Font Awesome 5 Free";
                font-weight: 600;
                content: " \f023";
                color: $colorBlack;
                font-size: 10px;
                text-align: center;
            }

            &:disabled:not(:checked) ~ .custom-control-label,
            &:disabled:not(:checked) ~ .custom-control-label::after {
                cursor: auto;
            }
        }
    }
}
