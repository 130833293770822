$pill-color-green-icon: #099137;
$pill-color-green-bg: #E7F6EC;
$pill-color-green-text: #036B26;
$pill-color-orange-icon: #FF8903;
$pill-color-orange-bg: #FEF6E7;
$pill-color-orange-text: #865503;
$pill-color-gray-icon: #848C99;
$pill-color-gray-bg: #EAECF0;
$pill-color-gray-text: #575C64;

$logoSizeDesktop: 160px;
$logoSizeMobile: 120px;

$profileHeaderMobileBreakpoint: 700px; // point where profile header should switch to mobile view

.profile-header {
    margin-bottom: 30px;

    .row {
        margin-left: 0;
        margin-right: 0;
    }

    &>img {
        width: 100%;
    }

    &.single-profile-box {
        display: flex;

        @media (max-width: $profileHeaderMobileBreakpoint) {
            flex-direction: column;
        }

        .profile-logo-box {
            position: relative;
            
            // desktop
            @media (min-width: calc(1px + $profileHeaderMobileBreakpoint)) {
                width: $logoSizeDesktop;
                height: $logoSizeDesktop; // required to maintain position of verification badge when text is long
                margin-right: 30px;
            }

            // mobile
            @media (max-width: $profileHeaderMobileBreakpoint) {
                width: $logoSizeMobile;
                height: $logoSizeMobile; // required to maintain position of verification badge when text is long
                margin: 0 auto;
            }

            .verification-badge {
                position: absolute;
                right: 0;
                width: 35px;
                height: 35px;
                bottom: 12px;

                // mobile
                @media (max-width: $profileHeaderMobileBreakpoint) {
                    bottom: 5px;
                    width: 32px;
                    height: 32px;
                }
            }
        }

        .profile-name-box {
            display: flex;
            flex-direction: column;
            justify-content: center;

            // mobile
            @media (max-width: $profileHeaderMobileBreakpoint) {
                margin-top: 30px;
                text-align: center;
            }

            .pill-badge-tooltip {
                margin-bottom: 10px;
                align-self: flex-start; // equivalent of width: auto

                // mobile
                @media (max-width: $profileHeaderMobileBreakpoint) {
                    align-self: center;
                }
            }

            h1 {
                display: inline-block;
                font-size: 24px;
                font-weight: 700;
                line-height: 32px;

                @media (max-width: $profileHeaderMobileBreakpoint) {
                    font-size: 22px;
                    line-height: 30px;
                }
            }
        }

        .profile-header-btns {
            display: flex;
            align-items: flex-start;
            padding: 0;
            margin-top: 5px;
            flex-wrap: wrap;

            @media (min-width: $profileHeaderMobileBreakpoint) {
                justify-content: flex-start;
            }

            .btn {
                min-width: 160px;
                border-radius: 100px;
                margin-right: 15px;
                padding: 6px;
                margin-top: 16px;

                i {
                    margin-right: 7px;
                }

                // deskt
                @media (min-width: calc(1px + $profileHeaderMobileBreakpoint)) {
                    max-width: 250px;
                }

                // mobile
                @media (max-width: $profileHeaderMobileBreakpoint) {
                    min-width: 140px;
                    max-width: 310px;
                    margin-top: 18px;
                    margin-left: 7.5px; margin-right: 7.5px; // offset margin so it still aligns
                }
            }
        }

        @media (max-width: $profileHeaderMobileBreakpoint) {
            .profile-header-btns {
                display: block;
            }
        }
    }

    .logoBlock {
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        background: $colorWhite;
        padding: 3px;
        background-size: contain !important;
        background-position: 50% 50% !important;
        background-repeat: no-repeat !important;
        border-radius: 50%;
        z-index: 1;
        display: block;

        width: 100%;
        aspect-ratio: 1;
        height: $logoSizeDesktop;
        width: $logoSizeDesktop;

        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: $profileHeaderMobileBreakpoint) {
            height: $logoSizeMobile;
            width: $logoSizeMobile;
        }

        h2 {
            text-transform: uppercase;
            text-align: center;
            margin: 0;
        }

        img {
            width: 142px;
            height: 142px;
        }
    }

    .text-information {
        h1 {
            margin-bottom: 10px;
            display: inline-block;
            font-size: 19px;
            font-weight: 600;
        }

        .tick {
            position: relative;

            i {
                color: $colorWhite;
                background: $colorRed;
                border-radius: 10px;
                text-align: center;
                width: 20px;
                height: 20px;
                line-height: 20px;
                margin: 0 10px;
            }

            i:hover {
                &+.message {
                    display: block !important;
                }
            }

            .message {
                background: #222 none repeat scroll 0% 0%;
                color: $colorWhite !important;
                border: 0px solid $colorWhite;
                border-radius: 4px;
                font-size: 12px !important;
                line-height: 1.5;
                font-family: "helvetica neue", arial, helvetica, freesans, "liberation sans", "numbus sans l", sans-serif !important;
                max-width: 200px;
                font-weight: 400;
                padding: 10px;
                white-space: normal;
                position: absolute;
                min-width: 200px;
                top: -8px;
                right: -210px;
                z-index: 20;
                display: none;
                text-align: left !important;
                text-transform: none !important;

                &:before {
                    width: 0px;
                    height: 0px;
                    border-style: solid;
                    border-width: 10px 10px 10px 0;
                    border-color: transparent #222 transparent transparent;
                    left: -10px;
                    top: 9px;
                    content: "";
                    font-size: 0px;
                    position: absolute;
                }
            }
        }

        .info {
            margin-left: 10px;

            span {
                margin-right: 30px;
                font-size: 14px;

                &:before {
                    content: " ";
                    line-height: 15px;
                    width: 15px;
                    height: 15px;
                    display: inline-block;
                    background-size: contain !important;
                    margin-right: 5px;
                }
            }

            .views {
                &:before {
                    background: url($PUBLIC_IMG_URL+"/icons/eye.png") left bottom no-repeat;
                }
            }

            .connections {
                &:before {
                    background: url($PUBLIC_IMG_URL+"/m.png") left bottom no-repeat;
                }
            }

            .startups {
                &:before {
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    content: "\f135";
                    font-size: 20px;
                    color: $colorPureBlack;
                }
            }
        }
    }

    .buttons {
        margin-top: 20px;
        float: right;

        a {
            padding: 5px 35px 5px 15px;
            margin-left: 20px;
            color: $colorWhite;
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 600;
            background: $colorRed;
            border-radius: 4px;

            &:before {
                width: 20px;
                height: 20px;
                margin: 0 10px 0 0;
                font-family: $fontAwesome;
                display: inline-block;
            }

            &:hover {
                color: $colorWhite;
            }

            &.edit {
                &:before {
                    content: "\f044";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                }
            }

            &.report {
                &:before {
                    content: "\f06a";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                }
            }

            &.favourite {
                &:before {
                    content: "\f005";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                }
            }

            &.connect {
                background: $colorBlack1;

                &:before {
                    content: " ";
                    background: url($PUBLIC_IMG_URL+"/users.png") left bottom no-repeat;
                    background-size: 14px;
                }
            }

            &.cancel {
                &:before {
                    content: "\f05e";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                }
            }

            &.accept {
                &:before {
                    content: "\f058";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                }
            }

            &.ignore {
                &:before {
                    content: "\f057";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                }
            }

            &.message {
                &:before {
                    content: "\f003";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                }
            }

            &.apply {
                &:before {
                    content: "\f044";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                }
            }

            &.connect {
                background: $colorRed;
            }

            &.website {
                background: $colorBlack1;

                &:before {
                    content: "\f0ac";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                }
            }
        }
    }

    .description {
        width: 95%;
        margin: 20px 2.5%;
        padding-top: 15px;
        border-top: 1px solid $colorBorder;

        p {
            margin-bottom: 40px;
            overflow: hidden;
        }

        iframe {
            max-width: 50%;
            float: left;
            margin: 10px;
        }

        img {
            max-width: 100%;
            max-height: 900px;
        }

        .productPicture {
            max-width: 50%;
            margin: 10px;
            float: left;
        }

        .showmore {
            display: block;
            text-align: center;

            a {
                font-weight: 600;
                color: $colorBlack;
                text-decoration: none !important;

                img {
                    max-width: 12px;
                }

                &:hover {
                    color: $colorRed;
                }
            }
        }
    }
}

.pill-badge-tooltip {
    display: flex;
    align-self: flex-start;

    // mobile
    @media (max-width: $profileHeaderMobileBreakpoint) {
        justify-content: center;
    }

    .pill-badge {
        display: inline-flex;
        align-items: center;
        padding: 6px 12px;
        border-radius: 10px;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
        max-width: 30ch;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        i {
            margin-right: 7px;
        }
    }

    .pill-badge-green {
        background-color: $pill-color-green-bg;
        color: $pill-color-green-text;

        i {
            color: $pill-color-green-icon;
        }
    }

    .pill-badge-orange {
        background-color: $pill-color-orange-bg;
        color: $pill-color-orange-text;

        i {
            color: $pill-color-orange-icon;
        }
    }

    .pill-badge-gray {
        background-color: $pill-color-gray-bg;
        color: $pill-color-gray-text;

        i {
            color: $pill-color-gray-icon;
        }
    }

    &:hover .tooltip-box {
        display: block;
    }

    .tooltip-box {
        position: relative;
        display: none;

        .message {
            background: #222 none repeat scroll 0 0;
            color: $colorWhite !important;
            border-radius: 4px;
            font-size: 13px !important;
            line-height: 1.5;
            font-family: $siteFont;
            width: 250px;
            font-weight: 300;
            padding: 10px 12px;
            white-space: normal;
            position: absolute;
            margin-top: -8px;
            margin-left: 15px;
            z-index: 20;
            text-transform: none !important;

            &:before {
                width: 0px;
                height: 0px;
                border-style: solid;
                border-width: 10px 10px 10px 0;
                border-color: transparent #222 transparent transparent;
                left: -10px;
                top: 9px;
                content: "";
                font-size: 0px;
                position: absolute;
            }
        }
    }
}