.slider-container {
    height: 100vh;
    background: $colorWhite;
    box-shadow: 2px 2px 4px 2px $colorWhite2; // order: x-offset, y-offset, blur, spread, color

    // position
    position: fixed;
    top: 0;
    z-index: 1052; // higher than modal overlay

    // transition
    transition: right 1s ease-in-out;

    &[data-expanded="true"] {
        right: 0 !important;
    }

    // debugging
    // background: lightgreen;
    // border: 5px solid forestgreen;

    // make child take available width
    display: flex;
    flex-direction: column;

    hr {
        border-width: 0.75px;
        margin: 1.2rem 0;
    }

    .slider-header-section {
        padding: 20px;
        padding-bottom: 0;

        .btn {
            margin-top: 14px;

            i {
                margin-right: 6px;
            }
        }

        .slider-btn-box {
            position: absolute;
            top: 20px;
            right: 20px;
            z-index: 1;

            i {
                font-size: 1rem;
                margin-left: 6px;
                font-weight: 600;
                line-height: 1;
                color: $colorBlack;
                text-shadow: 0 1px 0 $colorWhite;
                opacity: 0.4;
                font-size: 18px;

                &.disabled {
                    opacity: 0.15;
                    cursor: auto;
                }
            }
        }

        .slider-logo {
            aspect-ratio: 1;
            border-radius: 7px;
            width: 100%;
        }

        .slider-header {
            font-size: 17px;
            margin-bottom: 8px;
            font-weight: bold;
            display: block;

            @media (min-width: 1400px) {
                font-size: 19px;
            }
        }

        .social-tab {
            // desktop
            @media (min-width: 601px) {
                position: absolute;
                right: 0;
                bottom: 0;
                margin-right: 15px;
            }

            // mobile
            @media (max-width: 600px) {
                margin-top: 10px !important;
                justify-content: flex-end !important;
            }
        }

        & + hr {
            margin-bottom: 0;
        }

        .col-10 {
            padding-left: 5px;
        }
    }

    .slider-text {
        font-size: 14px;

        span {
            font-weight: bold;
            margin-right: 1px;
        }
    }

    .slider-detail-text {
        margin-top: 8px;
        margin-bottom: -8px;

        @media (min-width: 500px) {
            column-count: 2;
        }
    }

    .slider-scrollable {
        overflow-x: auto;
        padding: 20px;

        h3 {
            font-size: 15.5px;
            margin-bottom: 8px;

            span {
                margin-left: 4px;
                color: $colorGrey7;
            }
        }

        .slider-text {
            & > div {
                margin-bottom: 8px;
                display: flex;

                .circle-icon {
                    border: 1px solid $colorWhite2;
                    border-radius: 50%;
                    height: 25px;
                    margin-right: 7px;
                    width: 25px;
                    font-size: 12.5px;

                    // flex
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    flex-shrink: 0;

                    i {
                        color: $colorBlue1;
                    }
                }
            }
        }
    }

    .slider-button {
        margin-top: 1.5rem;

        $slider_btn_width: 38px;
        $slider_btn_factor: 3px;

        a {
            display: flex;
            align-items: center;
            justify-content: center;

            @media (min-width: 1400px) and (max-width: 1599px) {
                min-height: ($slider_btn_width + $slider_btn_factor);
            }

            @media (min-width: 1600px) {
                min-height: ($slider_btn_width + ($slider_btn_factor * 2));
            }
        }
    }

    // common styles for investor and team section

    .team-section,
    .simple-detail-section {
        padding: 0 !important;

        .title {
            align-items: center;
            margin-bottom: -5px !important;

            h2 {
                font-size: 15.5px !important;
            }
        }
    }

    .item-in-slider {
        width: 50%;
        max-width: 50%;
        min-width: 50%;

        &:not(.team-section-item) {
            @media (min-width: 1400px) {
                width: 33%;
                max-width: 33%;
                min-width: 33%;
            }
        }

        @media (max-width: 500px) {
            width: 100%;
            max-width: 100%;
            min-width: 100%;
        }
    }

    // styles for team section

    .team-section {
        margin-bottom: 0 !important;
    
        .team-section-item {
            margin-top: 25px !important;

            h3 {
                font-size: 14px !important;
            }

            // move btn to next line as there's not enough space in this view
            .team-section-contact {
                a.btn {
                    display: block;
                    margin-left: 0 !important;
                    margin-top: 10px;
                    max-width: 100px;
                }

                .social-tab {
                    margin-top: 12px !important;
                }
            }
        }
    }

    // styles for investor section
    
    .simple-detail-section {
        a[class^=col] {
            margin-top: 25px !important;
        }
    }

    // initial styles for modal backdrop
    & + .modal-backdrop {
        display: none;
        opacity: 0;
    }
}