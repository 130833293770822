.analyst-hours-usage-policy-table {
	th {
		font-weight: inherit !important;
	}

	td {
		span {
			white-space: nowrap;
		}

		vertical-align: top;
	}

	td {
		text-align: left !important;
	}

}
