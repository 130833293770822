.landing-page {

    .page-header {
        h2 {
            font-weight: 700;
            color: #000000;
            line-height: 1.25;
        }
    }

    .landing-section-header {
        font-size: 27px;
        font-weight: 600;
        text-align: center;
        color: $colorBlue3;
        max-width: 900px;
        margin: 0 auto;
        line-height: 1.3;
    }

    .section-title {
        background-color: $colorWhite;
        position: relative;
        overflow: hidden; // hide overflow of lines-graphic

        .section-title-box {
            padding: 50px;
            max-width: 1200px;
            width: 100%;
            margin: 0 auto;
            z-index: 2;
            position: relative;

            .page-header {
                margin-top: 0;
                max-width: 725px;
                margin: 0 auto;

                h2 span {
                    color: $colorRed;
                }

                p {
                    max-width: 600px;
                    margin: 0 auto;
                }
            }
        }
    }

    $videoSectionPadding: 45px;
    $videoBreakpoint_mobile: 991px;
    $videoBreakpoint_desktop: calc($videoBreakpoint_mobile + 1px);

    .section-video {
        & > div {
            margin: 0 auto;
            max-width: 1300px;
        }

        .section-video-heading {
            font-weight: 700;
            font-size: 30px;
            color: $colorBlue4;
            margin-bottom: 45px !important;
            line-height: 38px;
        
            &.w-100 {
                text-align: center;
            }
        }

        &[data-pe-landing="true"] {
            background-color: $PE_Blue;
        }

        background-color: $vibrantBlue;
        padding: $videoSectionPadding;
        position: relative;

        // mobile
        @media (max-width: $videoBreakpoint_mobile) {
            margin: 0 -15px;
            padding: 55px 20px;

            &:before {
                top: -15px;
                left: 0;
                content: "";
                position: absolute;
                height: 30vw; // flexible, so the white bg is always half the height of the video/image
                width: 100%;

                // background
                background-color: $colorWhite;
                background-image: url($PUBLIC_IMG_URL+"/vectors/lines.svg");
                background-size: 60%;
                background-repeat: no-repeat;
                background-position: top right;
            }
        }

        .section-video-content {
            display: flex; // for vertical alignment
            height: 100%;

            // mobile
            @media (max-width: $videoBreakpoint_mobile) {
                justify-content: center;

                img,
                video {
                    width: 100%;
                    max-width: 700px;
                }
            }

            img,
            video {
                border-radius: 14px;
                z-index: 1;
                position: relative;
                align-self: center; // for vertical alignment

                // desktop
                @media (min-width: $videoBreakpoint_desktop) {
                    margin-left: 80px;
                }
            }

            video {
                max-width: 800px;
                width: 100%;
                box-shadow: 10px 40px 40px -20px rgba(0, 0, 0, 0.2);

                // tablet
                @media (min-width: $videoBreakpoint_mobile) and (max-width: 1200px) {
                    width: 120%;
                    margin-left: 0%;
                }

                // fix extra black borders added by chrome
                &.video-with-border {
                    outline: 1px solid white;
                    outline-offset: -1px;
                }
            }
        }

        .section-video-text {
            padding: $videoSectionPadding;

            // mobile
            @media (max-width: $videoBreakpoint_mobile) {
                max-width: 550px;
                padding-bottom: 0;
                margin: 0 auto;
                text-align: center;

                h6,
                p {
                    color: $colorWhite;
                }

                p {
                    font-size: 17px !important;
                }

                .btn {
                    display: block;
                    max-width: 230px;
                    margin: auto;
                    font-size: 17px;
                }
            }

            // desktop
            @media (min-width: $videoBreakpoint_desktop) {
                max-width: 470px;
                margin: -$videoSectionPadding 0;
                height: calc(100% + $videoSectionPadding + $videoSectionPadding);
                padding-left: 90px;
                color: $colorBlue4;
                border: 1px solid $colorBorder;

                // background
                background-color: $colorWhite;
                background-image: url($PUBLIC_IMG_URL+"/vectors/lines.svg");
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
            }

            h6 {
                margin-bottom: 26px !important;
            }

            p {
                // use this if there is less text
                // font-size: 20px;
                // line-height: 28px;

                // use this if there is more text
                font-size: 18px;
                line-height: 27px;

                &:not(:last-of-type) {
                    margin-bottom: 22px;
                }
            }

            .btn {
                margin-top: 32px;
                padding: 8px 16px;
                min-width: 150px;
            }
        }
    }

    $countsBreakpointMobile: 1200;

    .section-feature-counts {
        padding: 60px 40px;
        background: $colorWhite;

        @media (max-width: 600px) {
            padding: 40px 30px;
        }

        .row {
            align-items: center;
            max-width: 1200px;
            margin: 0 auto;
            margin-top: 15px;
            justify-content: center;

            @media (max-width: 575px) {
                max-width: 360px;
                margin-top: 10px;
            }

            [class^="col-"] {
                margin: 20px 0px;
                margin-bottom: 0 !important;
                text-align: center;

                @media (max-width: ($countsBreakpointMobile + 'px')) {
                    &:not(:first-child) {
                        margin: 25px 0px;
                    }
                }
            }

            .feature-icon-box {
                display: flex;
                align-items: center;

                i {
                    height: 54px;
                    width: 54px;
                    border-radius: 16px;
                    background-color: $colorRed;
                    font-size: 27px;
                    justify-content: center;
                    display: flex;
                    align-items: center;
                    margin-right: 16px;
                    color: $colorWhite;
                    flex-shrink: 0;
                }

                span {
                    font-size: 15.5px;
                    text-align: left;

                    b {
                        color: $colorRed;
                    }
                }
            }
        }
    }

    .section-image-promo {
        color: $colorWhite;
        padding: 60px 40px;
        
        &[data-pe-landing="true"] {
            background-color: $PE_Blue;

            .btn-main-outline {
                border: 1px solid #FFF;
                // color: $colorWhite !important;
                // background-color: transparent;
                font-size: 18px;
                background-color: $colorWhite;
                color: $PE_Blue !important;

                i {
                    margin-left: 10px;
                }
            }
        }

        img {
            border-radius: 8px;

            // desktop styles
            @media (min-width: 992px) {
                max-height: 250px;
                width: auto;
            }

            // mobile styles
            @media (max-width: 991px) {
                margin-bottom: 30px;
                max-width: 375px;
                height: auto;
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                display: block;
            }
        }

        .row {
            max-width: 1100px;
            align-items: center;
            margin: 0 auto;
        }

        .image-promo-content {
            // desktop styles
            @media (min-width: 992px) {
                margin-left: 30px;
            }

            // mobile styles
            @media (max-width: 991px) {
                text-align: center;
                max-width: 700px;
                margin-left: auto;
                margin-right: auto;
            }

            .landing-section-header {
                color: $colorWhite;
                margin: 0;

                // desktop styles
                @media (min-width: 992px) {
                    text-align: left;
                }
            }
    
            p:not(.landing-section-header) {
                font-size: 18px;
                margin: 25px 0;
            }
        }
    }

    .section-get-demo {
        text-align: center;
        color: $colorWhite;
        background: $colorRed;
        padding: 60px 40px;

        .landing-section-header {
            color: $colorWhite;
        }

        .demo-subtitle {
            font-size: 19px;
            font-weight: 100;
            margin: 30px auto;
            max-width: 600px;

            @media (max-width: 600px) {
                font-size: 17px;
            }
        }

        .demo-input {
            width: 550px;
            margin: auto;

            @media (max-width: 700px) {
                width: 100%;
            }

            .form-error,
            .form-success {
                color: $colorWhite;
                margin-top: 15px;
                font-size: 15px;
            }

            .btn {
                padding: 21px;
                border: 1px solid white;
            }

            .form-control, .btn {
                height: 45px;
            }
        }
    }

}