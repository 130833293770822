.table {
    color: $colorBlack1;
}

#no-more-tables {
    clear: both;

    // table header
    tr:first-of-type {
        border: 1px solid $colorBorder;
    }

    th {
        font-size: 14px;
        border: 1px solid $colorBorder;
        padding: 8px 0px 8px 10px;
        vertical-align: top;

        i {
            margin-left: 5px;
        }

        .helptext {
            color: $colorBlack !important;

            i {
                margin: 0;
            }
        }
    }

    td {
        font-size: 14px;
        color: $colorBlack;

        &:not(.nestedCell) {
            padding: 10px;
        }

        a {
            color: $colorRed;

            i {
                margin-right: 0.5rem;
            }
        }
    }

    .verified-status {

        // not verified / default
        &:before {
            color: $colorBlack;
            font-family: "Font Awesome 5 Free";
            font-weight: 600;
            margin-right: 8px;
            opacity: 1;
        }

        &[data-verif="0"] {
            &:before {
                content: "\f12a";
            }

            &:after {
                content: "Pending";
            }
        }

        // verified
        &[data-verif="1"] {
            &:before {
                content: "\f00c";
                color: $colorRed;
            }

            &:after {
                content: "Verified";
            }
        }
    }

    tr.table-main-row {
        background: $colorBlue2;
        border-color: $colorBlue2;

        div {
            margin-top: 0 !important;
        }

        td {
            border: none;
            color: $colorWhite;
        }

        br {
            padding: 0 !important;
        }

        .btn {
            border: none;
            width: unset !important;
            padding: 6px 9px !important;
            margin-bottom: 0;
            min-width: 85px; // make both buttons take the same width
            text-align: center !important;
            margin: -2px 0px;

            &.btn-main-outline {
                margin-left: 15px;
            }

            &.btn-secondary-outline {
                border: 1px solid $colorWhite;

                a {
                    color: $colorWhite !important;
                }
            }
        }

        .d-flex {
            justify-content: space-between;
            align-items: flex-start;

            @media (max-width: 500px) {
                .badge {
                    margin-top: 0.5rem !important;
                }
            }
        }

        .main-row-title {
            font-weight: 500;
            font-size: 16px !important;

            .main-row-primary {
                font-size: 18px !important;
                margin-bottom: 10px;

                .badge {
                    margin-left: 1.2rem !important;
                    padding: 0.25rem !important;
                    vertical-align: bottom !important;
                }

                a {
                    color: $colorWhite !important;
                }

                a:hover {
                    opacity: 0.7 !important;
                }
            }

            .main-row-secondary {
                font-size: 14px !important;
                vertical-align: middle !important;

                .fa {
                    border: 1px solid $colorWhite;
                    border-radius: 50%;
                    font-size: 14px !important;
                    padding: 6px !important;
                    color: $colorWhite;
                    font-family: "Font Awesome 5 Free";
                    font-weight: 600;
                    opacity: 0.7;
                }

                .rounds::before {
                    content: "\f51e";
                }

                .funding-amount::before {
                    content: "\f4b9";
                }

                .valuation::before {
                    content: "\f201";
                }

            }

            .secondary-label {
                font-weight: 900;
            }
        }

        .main-row-sub {
            opacity: 0.7;
        }
    }

    ~.table-wide-button {
        background: $colorWhite;
        border: 0.75px solid rgba(0, 0, 0, 0.1);
        border-top: none;
        display: flex;
        justify-content: center;
        margin-top: 0;
        margin-bottom: 25px;
        padding: 1rem;

        a {
            color: $colorRed;
            font-size: 14px !important;

            i {
                margin-right: 0.5rem;
            }
        }
    }

    tbody+tbody {
        border-top: unset;
    }

    td.nested-btn-cell {

        .btn {
            margin-bottom: 0;
            text-align: center !important;
            padding: 5px 9px !important;

            i {
                margin-right: 0.5rem;
            }

            &.btn-main-outline {
                margin-left: 15px;
            }
        }
    }

    &.table-investor-view {
        display: inline-block;
        width: auto;
        margin-bottom: 18px;

        @media (min-width: $fundingTableBreakpoint) {
            max-width: 400px;
        }

        table,
        tr {
            border: none !important;
        }

        tr {
            display: block !important;

            td.nestedCell {

                &::before {
                    content: none;
                }

                &>div {
                    border: 0.75px solid $colorBorder;
                    padding: 8px 12px;
                    margin-left: 0;

                }
            }

        }
    }

}

@media only screen and (min-width: $fundingTableBreakpoint) {

    #no-more-tables {

        /* limit column widths */

        [data-title="Location"],
        [data-title="Deal Term"],
        [data-title="Investment Vehicle"] {
            max-width: 130px;
            min-width: 105px;
        }

        [data-title="Date"] {
            min-width: 90px;
        }

        [data-title="Verified"] {
            min-width: 115px;
        }

        [data-title="Name"] {
            min-width: 210px;
        }

        [data-title="Fund Manager"] {
            min-width: 160px;
        }
    }

}

// mobile/tablet styles

@media only screen and (max-width: $fundingTableBreakpoint) {

    #no-more-tables {
        margin-top: 0 !important;

        /* Force table to not be like tables anymore */
        table,
        thead,
        tbody,
        th,
        td,
        tr {
            display: block;
        }

        // disable table scroll since the table doesn't need it
        table {
            overflow-x: auto;
        }

        /* Hide table headers (but not display: none;, for accessibility) */
        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        tr {
            border: 1px solid #ccc;
            border-bottom: none;

            &:last-of-type {
                border-bottom: 1px solid #ccc;
            }

            /* Behave  like a "row" */
            &:not(.table-main-row) td:not(.nested-btn-cell) {
                border: none;
                position: relative;
                white-space: normal;
                text-align: left;

                &:not([data-title="Investors"]) {
                    padding-left: 50%;
                }
            }

            // hide the table header with the column names
            // since on mobile, the rows will be displayed in column format
            &:first-child:not(.table-main-row) {
                display: none;
            }
        }

        td {
            &:not(:last-of-type) {
                border-bottom: 1px solid $colorBorder !important;
            }

            &:not(.nested-btn-cell)::before {
                /* Now like a table header */
                position: absolute;
                /* Top/left values mimic padding */
                left: 15px;
                width: 45%;
                padding-right: 10px;
                white-space: nowrap;
                text-align: left;
                font-weight: bold;
                /* Label the data */
                content: attr(data-title);
            }

            &.nestedCell {
                &::before {
                    margin-top: 6px;
                }

                div {
                    margin-left: 47%;
                    padding: 6px 12px;
                }
            }
        }

        tr.table-main-row {
            >td {
                padding: 12px !important;

                >.d-flex {
                    flex-direction: column;
                    align-items: flex-start !important;

                    // btn container
                    >div:nth-child(2):not(.main-row-sub) {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        margin-top: 14px !important;

                        .btn {
                            width: 47% !important;
                        }
                    }
                }
            }
        }

    }

}

/* styles for table collapsible rows */

.table-collapse td {
    box-shadow: unset !important;
}

/* react data-table package */

.data-table-box {
    margin-top: 15px;

    * {
        font-size: 14px;
    }

    .rdt_TableHeader {
        display: none;
    }
}