.startupLogo {
    // size
    width: 45px;
    height: 45px;

    border-radius: 7px;
    border: 1px solid $colorBorder;

    // image
    background-color: $colorWhite;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

    // flex (for name initial)
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    h2 {
        font-size: 25px;
        color: $colorBlack;
    }

    @media (max-width: 600px) {
        width: 38px;
        height: 38px;
    }

    // responsive version where size changes according to container size
    &[data-responsive="true"] {
        width: 100%;
        height: auto;
        aspect-ratio: 1/1;

        h2 {
            font-size: 35px;
        }
    }
}