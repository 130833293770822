.menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $colorRed;
    color: $colorWhite;
    border-radius: 4px 4px 0px 0px;
    padding: 15px;
    margin-bottom: 0;

}

.menu-collapsed {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $colorRed;
    color: $colorWhite;
    width: 3%;
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 40px;

    @media (max-width: 900px) {
        width: 15%;
    }
}

.list-group {
    border-radius: 0px;

    .list-group-item {
        border: none;
        cursor: pointer;

        &:not(:last-child) {
            border-bottom: 1px solid RGB(0 0 0 / 7%);
        }

        &.list-item-active {
            > div {
                color: $colorRed;
            }
        }

        h6 {
            line-height: 22px;
            font-size: 15.5px;
        }
    }
}

.tableau-dashboard {

    .db-header {

        div {
            font-size: 22px;
        }

        i {
            font-size: 24px;
            color: $colorRed;
            opacity: 1;
            margin-right: 10px;
        }
    }

    .tableau-iframe-container {
        overflow: hidden;
        position: relative;
        padding-top: 55%; // for absolute child's aspect ratio
        border-bottom: 1px solid #E3E3E3;
        margin-top: 20px;

        &:not(:last-child) {
            margin-bottom: 40px !important;
        }

        // hide 'open in data studios' hover link
        &::after {
            content: "|"; // add any char so the element takes up space
            background: $colorWhite;
            width: 100%;
            display: flex;
            position: absolute;
            height: 28px;
            bottom: 0px;
            color: $colorWhite;
            border-left: 1px solid #E3E3E3;
            border-right: 1px solid #E3E3E3;
        }

        iframe {
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            margin: none;
            // padding: 10px;
            border: 1px solid #E3E3E3;
            position: absolute;
        }
    }

    .helptext {
        i {
            color: $colorGrey3;
            margin-right: 0 !important;
            font-size: 26px;
        }

        i:hover {
            color: $colorRed
        }
    }
}


.advanced-analytics-form {
	.db-box {
		img {
			border: 1px solid $colorBorder;
		}
	}

	@media (max-width: 991px) {
		.db-box {
			display: flex !important;
			flex-direction: column;
			align-items: center;
		}
	}

	.advanced-analytics-icons {
		.stats-responsive-box {
			width: 130px;
			.stats-count {
				display: flex;
				justify-content: center;
				i {
					border: 1px solid #e7e7e7;
					border-radius: 50%;
					width: 60px;
					height: 60px;
					display: flex;
					justify-content: center;
					align-items: center;
					padding: 20px;
				}
			}
		}
	}
}
