// import font from magnitt-react rather than magnitt-shared
// (fix for htmlToImage cross-origin font errors)
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400&display=swap');



#export-charts-modal {

    @media (min-width: 600px) {
        .modal-content {
            min-height: 550px;
        }
    }

    .modal-body {
        .btn {
            min-width: 150px;
            font-size: 15px;

            i {
                color: $colorWhite;
            }
        }

        i {
            color: $colorRed;
            font-size: 18px;
            margin-right: 6px
        }

        p {
            font-size: 15px;

            i {
                margin-right: 8px;
            }

            &.red {
                max-width: 640px;
            }
        }

        label {
            font-size: 14px;
            margin-bottom: 12px;
            display: block;
        }

        input,
        select {
            width: 100%;
            font-size: 14px;
            border-radius: 5px;
            background-color: $colorWhite;
            padding-left: 10px;
            border: 0.75px solid #e5e5e5;
        }

        hr {
            margin: 2rem 0;
        }

        h5 {
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            margin-bottom: 20px;
        }

        .col-lg-4 {
            max-width: 600px;

            @media (max-width: 992px) {
                &:not(:last-of-type) {
                    margin-bottom: 15px;
                }

                // hide spacer label
                &:last-of-type {
                    label {
                        display: none;
                    }
                }

                .btn {
                    margin-top: 6px;
                }
            }
        }

        .export-preview-box {
            font-size: 15px;
            width: 100%;
            overflow-x: 'auto';

            .react-pdf__Document {
                margin-top: 25px;
            }

            .react-pdf__Page {

                canvas {
                    border: 1px solid $colorBorder;
                    border-radius: 5px;
                    margin: 0 auto; // center
                    width: 100% !important;
                    height: auto !important;
                    max-width: 900px;
                }

                &:not(:last-of-type) {
                    margin-bottom: 25px;
                }

            }

        }
    }
}

// table element
// will be made visible during export creation
#chart-export-table {
    display: none;

    td, 
    th, 
    td > div, 
    th > div {
        min-width: unset;
        min-height: unset;
    }

    td:first-child,
    th:first-child {
        box-shadow: unset;
    }

    // fix borders for exported file
    .table-responsive {
        overflow: hidden; // hides scrollbar on larger screens
        border: none;
        
        .table {
            border: 1px solid rgba(0, 0, 0, 0.095);
        }
    }
}