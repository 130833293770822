.general-manage-page {

    .manage-map-row > div {
        @media (max-width: 600px) {
            width: 100%;
        }
    }

    .tabbed-display .btn {
        margin-bottom: 20px;
    }

    .manage-map-btn {
        cursor: auto;
        padding: 15px;
        overflow: hidden;
        position: relative;

        & > .d-flex {
            z-index: 1;
        }

        & > .d-flex,
        & > .d-flex > div,
        & > .d-flex > div > span {
            width: 100%
        }

        span a {
            font-size: 16px;
            margin-bottom: 10px;
            display: inline-block;
            word-wrap: break-word;
            width: 100%;
        }

        .btn-group {
            width: 100%;
            max-width: 300px;
        }

        .btn {
            font-size: 14px;
            padding: 5px;
            width: 33.33%;

            i {
                margin-right: 0 !important;
                display: block;
                margin-bottom: 5px;
                max-height: 15px;
                font-size: 15px !important;
            }

            &:hover {
                background-color: $colorRed;

                &, i {
                    color: $colorWhite !important;
                }
            }
        }

        &:after {
            // icon
            font-family: "Font Awesome 5 Free";
            color: $colorBlue2;
            font-size: 100px;
            font-weight: 600;
            opacity: 0.08;

            // position
            position: absolute;
            top: -30px;
            right: -8px;
            z-index: 0; // put under everything else
        }
    }

    // globe icons
    .manage-row > div {

        &:nth-child(even) .manage-map-btn:after {
            content: "\f57d";
        }

        &:nth-child(odd) .manage-map-btn:after {
            content: "\f57c";
        }

        &:nth-child(3n) .manage-map-btn:after {
            content: "\f57e";
        }

    }

}

/* === preview/custom market maps page === */

.preview-market-map {
    background-color: $colorWhite !important;
    padding: 0 !important;
    border: 1px solid $colorBorder;
    border-radius: 5px;

    .network-map-navigation {
        margin: 0 !important;
    }

    .map-card-wrapper {
        margin: 0 10px !important;
    }

    .btn-main {
        width: 200px !important;
    }

    .map-dashboard-wrapper {
        .map-dashboard-graph {
            margin: 0px !important;

            &:not(:last-child) .graph__chart {
                margin-bottom: 22px !important;
            }
        }
    }

    // common container padding
    .network-map-cards,
    .map-dashboard-wrapper {
        padding: 20px;
    }

    .network-map-cards {
        .network-map-groups {
            margin: 0 !important;
        }

        .my-masonry-grid {
            margin: 0 -20px;

            .map-card {
                padding-bottom: 0 !important;
            }
        }

        .no-chart-data {
            padding: 0;
            margin-top: 15px;
        }
    }

    .directory-table {
        .table-responsive {
            margin-top: 0 !important;
            border-radius: 5px;

            @media (min-width: 600px) {
                overflow-x: scroll;
            }

            * {
                font-size: 14px;
            }

            table {
                border: 1px solid $colorWhite !important;
                margin-bottom: 0;
                border-left: none !important;
            }

            .startupLogo {
                cursor: pointer !important;
            }
        }
    }

    .no-chart-data {
        padding: 20px;
        background: $colorWhite;

        h6 {
            text-align: center;
        }
    }

    .startupLogo {
        .startup-name {
            font-size: 25px !important;
            margin: auto !important;
            font-weight: 400 !important;
        }
    }
}

.network-map-note {
    margin-bottom: 6px;
    font-size: 15px !important;
}

.market-map-header {
    display: flex;
	justify-content: space-between;

    &.db-header {
        div:last-child {
            flex-shrink: 0;
        }
    
        p {
            font-size: 16px !important;
        }
    }

    // desktop
    @media (min-width: 700px) {
        display: flex !important;

        .btn {
            margin-left: 12px;
        }
    }

    // mobile
    @media (max-width: 699px) {
        display: block;

        .btn {
            margin-top: 15px;
            width: 48%;
            max-width: 200px;

            &:first-of-type {
                margin-right: 4%;
            }
        }
    }

    .btn {
        min-width: 130px;
        padding: 7px 8px;
        font-size: 14.5px;
    }
}

.preview-box {
    .filters-header {
        margin-bottom: 15px;
    }
}

/* add/edit form */

.create-edit-form .info-box-container {
    p.selected-marketMap-filters {
        line-height: 24px;

        &, span {
            font-size: 15px !important;
        }
    
        .sub-bold {
            color: #9f9f9f;
            font-weight: 600 !important;
            letter-spacing: .4px !important;
        }
    
        i {
            color: $colorBlack !important;
            opacity: 1 !important;
        }
    }
}

/* default market map page */
.default-market-map {
    .directory-table {
        .table-responsive {
            border: 2px solid $colorBorder !important;

            table {
                margin-bottom: 0;
            }
        }
    }
}

/* landing page */
.market-map-product-img {
    aspect-ratio: 1.5;
    border: 1px solid $colorBorder;
    border-radius: 0.5rem;
    display: flex;
    padding: 10px;

    img {
        border: none !important;
        align-self: center; // vertically align imgs of diff heights
    }
}

.map-select-box {
    .map-select {
        // flex
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    
        border: 1px solid $colorBorder;
        border-radius: 4px;
        cursor: pointer;
        padding: 20px;    
        font-size: 14px !important;
        margin: 0 !important;
        text-align: center;
        height: 100%;
    
        &.active {
            border: 1px solid $colorRed;
        }
    
        h6 {
            font-weight: 600;
            margin-bottom: 8px;
        }
    
        .entity-icon {
            height: 37px; width: 37px;
            border: 1px solid $colorRed;
            border-radius: 50%;
            padding: 9px;
            display: flex;
            align-items: center;
            margin-top: 0 !important;
            margin-bottom: 8px;
        }
    
        p {
            max-width: 340px
        }
    }

    [class^="col"]:nth-child(2) {
        @media (max-width: 575px) {
            margin-top: 22px !important;
        }
    }
}