/* startup directory funding view */

.directory-table {

	// toggle btn
	a.messageBtn.accordion-toggle {
		 &[aria-expanded]:after {
			  margin-left: 3px;
			  font-family: $siteFont;
			  font-weight: 400;
		 }

		 &[aria-expanded="true"] {
			  i {
					rotate: 180deg;
			  }

			  &:after {
					content: "Hide Funding";
			  }
		 }

		 &[aria-expanded="false"]:after {
			  content: "See Funding";
		 }
	}

	// table
	.table-collapse .table.funding-collapsible {
		 a {
			  color: $colorBlack1;

			  &:hover {
					color: $colorRed;
			  }
		 }

		 thead th {
			  border-bottom: 1px solid $colorTableBorder !important;
		 }

		 .valuation-tooltip {
			  display: inline;

			  i {
					color: $colorBlack;
			  }
		 }
	}

	// profile btn
	.table td.funding-profile-link {
		 text-align: center;
	}

}
