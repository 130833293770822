// responsive margin
$margin: 30px;
$marginx2: 15px;

/* === global styles === */

.fontSize14 {
    font-size: 14;
}

// clear bootstrap default btn/input outline
.clear-outline:focus {
    // border: none;
    box-shadow: none;
    // border: 1px solid inherit;
}

.page-header {
    text-align: center;
    margin: 20px 0px;
    margin-bottom: $margin;
    // display: inline-block; // prevent margin collapse
    width: 100%;

    @media (min-width: 1300px) {
        margin-bottom: 45px;
    }

    // header with less margin/padding
    &.page-header-narrow {
        margin-bottom: 3.5vw;
        margin-top: 0.5vw;

        &:not(:first-of-type) {
            margin-top: 3.5vw;
            padding-top: 0;

            @media (max-width: 800px) {
                margin: 32px 0px;
            }
        }

        @media (max-width: 1200px) {
            margin-top: 1.5vw;
        }

        @media (max-width: 800px) {
            margin-bottom: 32px;
            margin-top: 15px;
        }
    }

    .header-sublink {
        color: $colorRed;
        text-transform: uppercase;
        font-size: 16.5px;
        margin-top: 10px;
        display: block;
        font-weight: 600;
    }

    h1 {
        font-size: 37px;
    }

    p {
        margin-top: 15px !important;
        font-size: 17px;
        letter-spacing: 0.6px;
        color: #6c757d;

        &.p-limit-width {
            max-width: 600px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.page-header.header-padding-2,
.page-header:not(:first-of-type) {
    padding-top: $margin;

    @media (max-width: 600px) {
        padding-top: $marginx2;
        margin-bottom: $marginx2;
    }
}

.sort-box {
    padding: 12px;
    background: $colorWhite;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 100%;

    .sort-count {
        font-weight: bold;
        font-size: 36px;
        margin-bottom: 15px;
        margin-top: 30px;
        color: $colorRed;
    }

    img {
        height: 50px;
        margin-bottom: 10px;
        margin-top: 2px;
    }

    div {
        font-size: 18px;
        line-height: 25px;
        color: $colorBlack1;
    }
}

.arc-bottom {
    background-color: $colorWhite;
    // width longer than page width to hide beginning of circle curve
    // use negative margin to offset
    // width: 150%;
    // margin-left: -25%;
    width: 195%;
    margin-left: -45%;
    // offset top half to reduce white space
    height: 280px;
    margin-top: -295px;
    box-shadow: 0 40px 40px -20px rgba(23, 29, 48, 0.2);
    border-radius: 0 0 50% 50%;
    overflow: hidden;
    border-top: 10px solid $colorWhite;

    @media (max-width: 700px) {
        width: 200%;
        margin-left: -50%;
        height: 300px;
    }

    &+.content-box {
        margin-top: -15px;
        z-index: auto;
    }
}

.references-box {
    justify-content: space-between;
    border-bottom: 15px solid transparent;

    @media (max-width: 991px) {
        margin-top: -$margin;
    }

    @media (max-width: 600px) {
        margin-bottom: $marginx2;
        border: none;
    }

    div {
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 991px) {
            padding-top: $margin;
        }

        a {
            background: $colorWhite;
            padding: 5px;
            border-radius: 8px;
            height: 75px;
            width: 100%;

            img {
                height: 100%;
                width: auto;
                display: flex;
                margin: 0 auto;
            }
        }
    }
}

@keyframes slide1 {
    0% {
        //opacity: 0;
        transform: translateY(-100px);
    }

    100% {
        //opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slide2 {
    0% {
        //opacity: 0;
        transform: translateY(100px);
    }

    100% {
        //opacity: 1;
        transform: translateY(0);
    }
}

.out {
    //animation: fadeout 2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation: 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s 1 normal both running slide1;
}

.in {
    //animation: fadein 2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation: 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s 1 normal both running slide2;
}

.staticpage-demo-container {
    padding: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #364049;

    @media (max-width: 600px) {
        padding: 40px 40px !important;
        width: auto;
        margin: auto;
    }

    h1 {
        margin-top: 0;
        font-weight: 200;
        font-size: $margin;
        color: $colorWhite;
        margin-bottom: 20px;
        line-height: 38px;
        text-align: center;
    }

    input {
        height: 33px;
        font-size: 15px;
        font-weight: 100;
    }
}

.staticpage-demo-input {
    width: 50%;

    @media (max-width: 1024px) {
        width: 70%;
    }

    @media (max-width: 600px) {
        width: 100%;
    }

    .form-error {
        color: $colorRed;
    }

    .form-success {
        color: $colorWhite;
    }

    [class^="form-"]:not(.form-control) {
        margin-top: 12px;
        font-size: 15px;
    }
}

.staticpage-demo-title {
    color: $colorBlack;
    text-align: center;
    font-weight: 200;
    color: $colorWhite;
    margin-bottom: 25px;
    font-size: 18px;
    letter-spacing: 0.7px;

    @media (max-width: 600px) {
        font-size: 16px;
    }
}

.staticpage-demo-btn {
    background-color: $colorRed;
    font-size: 15px;
    font-weight: 400;
    align-items: center;
    cursor: pointer;
    color: $colorWhite !important;
    height: 33px;
    font-weight: 200;
    border-radius: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    justify-content: center;
}

@media (max-width: 600px) {
    @keyframes slideSide1 {
        0% {
            //opacity: 0;
            transform: translateX(-100px);
        }

        100% {
            //opacity: 1;
            transform: translateX(0);
        }
    }

    @keyframes slideSide2 {
        0% {
            //opacity: 0;
            transform: translateX(100px);
        }

        100% {
            //opacity: 1;
            transform: translateX(0);
        }
    }

    .out {
        //animation: fadeout 2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        animation: 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s 1 normal both running slideSide1;
    }

    .in {
        //animation: fadein 2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        animation: 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s 1 normal both running slideSide2;
    }
}

/* === dashboard === */

// borders
.item-box,
.item-img {
    border-radius: 5px;
    overflow: hidden;
}

.item-box {
    // padding: 20px;
    height: 100%; // make all take same height
    border: 1px solid rgba(0, 0, 0, .1);
    position: relative;

    // rec startups
    .startup-image {
        display: block;
        background-color: $colorWhite; // for transparent imgs
        margin: 0px auto;
        position: relative;
        top: -20px;
        background-size: cover;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px;
        height: 90px;
        width: 90px;
    }

    // rec people
    .cover-bg {
        align-items: center;
        display: flex;

        &:not(.no-cover) {
            height: 130px;
            background-size: cover !important;
            // default if type doesn't match
            background: url($PUBLIC_IMG_URL+"/bg/bg_others.png");
        }

        &.no-cover {
            padding-top: 20px;

            .item-img {
                background-size: contain;
                // border: 1px solid rgba(0,0,0,.1)
                box-shadow: 0 0 0 0.7px RGB(0 0 0 / 10%);
            }
        }

        &[data-type="Founder"] {
            background: url($PUBLIC_IMG_URL+"/bg/bg_startup.png");
        }

        &[data-type="User"] {
            background: url($PUBLIC_IMG_URL+"/bg/bg_more.png");
        }

        &[data-type="Investor"] {
            background: url($PUBLIC_IMG_URL+"/bg/bg_investors.png");
        }
    }

    .ignore-btn {
        color: $colorWhite;
        position: absolute;
        top: 10px;
        right: 25px;
        font-size: 18px;
        // transition: opacity 0.5s linear;
        cursor: pointer;
    }

    .item-body {
        margin: 15px !important;
    }

    .item-header {
        margin-top: 10px !important;
        text-align: center;

        // name
        a {
            text-transform: capitalize;
            display: inline-block;
        }

        div:nth-child(2) {
            font-size: 14px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            margin-top: 2px;
            opacity: 0.9;
        }

        p {
            margin-bottom: unset !important;
        }
    }

    hr {
        margin: 12px 0px;
        opacity: 0.75;
        border-width: 0.75px;
    }

    .item-details {
        font-size: 14px;

        a,
        div {
            letter-spacing: 0.01rem;
            text-overflow: ellipsis;
            // white-space: nowrap;
            // overflow: hidden;
            margin-bottom: 5px;
        }

        a.text-dark:hover {
            color: $colorRed !important;
        }
    }

    .btn {
        align-self: center;
        width: 100%;
        margin-top: 15px;
        // btn sm
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
        line-height: 1.5;
        border-radius: 0.2rem;
    }

    .btn:disabled {
        cursor: auto;
        opacity: 0.5;

        &.btn-secondary {
            opacity: 0.3;
        }
    }
}

.item-img {
    height: 70px;
    width: 70px;
    background: $colorWhite; // for transparent images
    background-size: cover;
    display: block;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;

    h2 {
        text-transform: uppercase;
        font-size: 40px !important;
        line-height: 10px;
    }
}

.db-box .team {
    padding: 0 !important;
    margin: 0 !important;
    margin-left: -15px !important;

    .startup-box {
        margin: 0 !important;
        margin-bottom: $margin !important;

        h3 {
            margin: 0 !important;
        }

        a div.image {
            background-size: cover !important;
            margin: 0 15px !important;
        }
    }
}

.db-box.startups,
.db-box.recommended {
    padding-bottom: 0 !important;

    [class^="col-"] {
        margin-bottom: $margin;
    }
}

.db-box.no-background {
    background: none;
    box-shadow: none;

    .text-section {
        div {
            font-size: 40px;
            margin-bottom: 20px;
        }

        span {
            font-weight: 600;
        }

        .first-line {
            font-size: 25px;
            line-height: 32px;
        }

        p {
            font-size: 16px;
            margin-bottom: 15px;
            max-width: 600px;
        }
    }
}

// Recently Funded Startups Table in Dashboard
.db-box .table-responsive {
    margin: 0 20px;

    table {

        thead,
        tr {
            height: 40px;
            font-size: 15px;
        }

        thead,
        th {
            padding: 15px, 10px;
            vertical-align: middle;
        }

        tbody,
        td {
            font-weight: normal !important;
            border: none !important;
            padding: 12px !important;

            .startup-name-cell {
                display: flex;

                .startup-logo {
                    margin-right: 10px;
                }
            }

            .startup-name {
                color: $colorRed !important;
                font-weight: 600 !important;
                margin: auto 0 !important;
            }

            .startup-name:hover {
                color: $colorBlack !important;
            }

            .startup-logo {
                width: 40px;
                height: 40px;
                border-radius: 7px;
                border: 1px solid $colorBorder !important;
                color: $colorBlack !important;
                background-color: $colorWhite;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0; // prevent logo from shrinking when text is long
            }
        }

        tbody tr td:nth-of-type(1) {
            padding-right: 0 !important;
        }

        // striped table
        tbody tr:nth-of-type(even) {
            background-color: $colorWhite;
        }

        tbody tr:nth-of-type(odd) {
            background-color: #F8F8F8;
        }
    }
}

// generic item block with image and text
// reuse for startup widgets, recently funded, etc.

.item-block {
    display: flex;

    .item-img {
        border: 0.75px $colorBorder solid;
        margin: unset;
        margin-right: 10px;
        flex-shrink: 0;
    }

    .item-block-content {
        p {
            font-size: 13px;

            &:nth-of-type(1) {
                font-size: 14px;
                font-weight: 600;
            }
        }
    }

    // mobile responsive
    @media (max-width: 766px) {
        &:not(:last-child) {
            margin-bottom: 20px;
        }

        .item-img {
            margin-right: 15px;
        }

        .item-block-content {
            p {
                font-size: 14px;

                &:nth-of-type(1) {
                    font-size: 15px;
                }
            }
        }
    }
}

/* === Charts insufficient data === */
.charts-insufficient-data {
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    padding: 30px;
    font-size: 15px;
}

/* === end // Charts insufficient data === */

/* === Landing Page === */
.home-landing-page {
    section {
        background: $colorWhite;
    }

    .section-common {
        font-size: 37px;
        line-height: 1.2 !important;
        text-align: center;
        padding: 80px 40px;
        font-weight: 600;

        .btn {
            margin-top: 40px;
            padding: 10px 50px;
        }

        .subtext {
            font-size: 26px;
            margin-top: 30px;
        }
    }

    .section-banner {
        color: $colorBlack1;
        min-height: 400px;
        padding-bottom: 120px !important;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-image: url($PUBLIC_IMG_URL+"/homepage/home_banner.jpg");

        .banner-text {
            width: 55%;
            margin: auto;
            line-height: 50px;
            background: rgba(255, 255, 255, 0.6); // #FFF 60% opacity
        }

        @media (max-width: 600px) {
            background-size: 1500px 100%;
        }
    }

    .section-reference {
        font-size: 28px;
        text-align: center;
        padding: 60px 40px;
        background-color: $colorBlack1;
        color: $colorWhite;

        .logo-wrap {
            display: flex !important;
            flex-wrap: wrap;
            align-items: center;
            margin: auto;
            margin-top: 30px;
            max-width: 1300px;
            justify-content: center;

            .reference-logo {
                min-width: 160px;
                min-height: 55px;
                margin: 20px 30px;
                flex: 2 1 auto;
                background-size: contain !important;
                background-repeat: no-repeat;
                background-position-x: center;
                background-position-y: center;
                opacity: 0.5;
                max-width: 185px;
            }
        }
    }

    .section-count {
        background-color: $colorWhite;
        color: $colorBlack1;

        .count-text {
            width: 70%;
            margin: auto;
        }

        .count-wrap {
            display: flex;
            max-width: 1000px;
            margin: auto;
            margin-top: 80px;

            @media (max-width: 600px) {
                .count:last-child {

                    &,
                    .sort-box {
                        padding-bottom: 0;
                    }
                }
            }
        }

        .count {
            padding: 30px;
            border-bottom: 1px solid $colorRed;
            border-right: 1px solid $colorRed;

            .sort-count {
                font-size: 66px;
                margin-bottom: 25px;
                margin-top: 0 !important;
            }
        }

        /*sm*/
        @media(max-width:767px) {
            .count:nth-child(n) {
                border-right: none;
            }

            .count:nth-child(n+6) {
                border-bottom: none;
            }
        }

        /*md*/
        @media(min-width:768px) and (max-width:1199px) {
            .count:nth-child(2n) {
                border-right: none !important;
            }

            .count:nth-child(n+5) {
                border-bottom: none;
            }

            .count:nth-child(1),
            .count:nth-child(2) {
                padding-top: 0 !important;
            }

            .count:nth-child(5),
            .count:nth-child(6) {
                padding-bottom: 0 !important;

                .sort-box {
                    padding-bottom: 0 !important;
                }
            }
        }

        /*xl*/
        @media(min-width:1200px) {
            .count:nth-child(3n) {
                border-right: none;
            }

            .count:nth-child(n+4) {
                border-bottom: none;
            }

            .count:nth-child(1),
            .count:nth-child(2),
            .count:nth-child(3) {
                padding-top: 0 !important;
            }

            .count:nth-child(4),
            .count:nth-child(5),
            .count:nth-child(6) {
                padding-bottom: 0 !important;

                .sort-box {
                    padding-bottom: 0 !important;
                }
            }
        }
    }

    .section-demo {
        text-align: center;
        padding: 120px 40px;
        color: $colorWhite;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;

        h1 {
            font-size: 58px;
            font-weight: 600;

            @media (max-width: 600px) {
                font-size: 50px;
            }
        }

        .demo-subtitle {
            font-size: 24px;
            font-weight: 100;
            margin: 40px auto;
            max-width: 600px;
        }

        .demo-input {
            width: 50%;
            margin: auto;

            @media (max-width: 1024px) {
                width: 70%;
            }

            @media (max-width: 600px) {
                width: 100%;
            }

            .form-error,
            .form-success {
                margin-top: 15px;
            }

            .form-error {
                color: $colorRed;
            }

            .form-success {
                color: $colorWhite;
            }

            .btn {
                padding: 25px;
            }
        }
    }

    .section-feature {
        font-size: 28px;
        font-weight: 600;
        text-align: center;
        padding: 80px 40px;
        background-color: $colorWhite;
        color: $colorBlack1;

        .feature-text {
            width: 50%;
            margin: auto;
        }

        .feature-wrap {
            max-width: 1300px;
            margin: 40px auto;
        }

        .feature {
            padding: 25px;

            @media (max-width: 600px) {
                margin-top: 10px;
            }
        }

        .feature-img {
            margin-bottom: 15px;
            border: 1px solid $colorGrey3;
            border-radius: 15px;
        }

        .red {
            font-size: 20px;
        }
    }

    .section-dashboard {
        font-size: 28px;
        font-weight: 400;
        text-align: center;
        padding: 60px 40px;
        background-color: $colorRed;
        color: $colorWhite;

        .btn {
            border: 2px solid $colorWhite;
        }

        .new-slider {
            margin: auto;
        }

        .dashboard-img {
            border-radius: 15px;
            margin: 60px auto;
            margin-bottom: 20px;
            position: relative;
        }
    }

    .section-reports {
        padding: 0 0 60px 0 !important;

        .header-with-line {
            overflow: hidden;
            text-align: center;
        }

        .header-with-line>span {
            position: relative;
            display: inline-block;
        }

        .header-with-line>span:before,
        .header-with-line>span:after {
            content: '';
            position: absolute;
            top: 50%;
            border-bottom: 1px solid $colorRed;
            width: 100vw;
            margin: 0 20px;
        }

        .header-with-line>span:before {
            right: 100%;
        }

        .header-with-line>span:after {
            left: 100%;
        }

        .reports-wrap {
            display: flex;
            flex-wrap: wrap;
            margin: 40px auto !important;
            max-width: 1300px;
            margin: auto;

            .report-item {
                box-shadow: none !important;
                padding: 20px;

                h6 {
                    margin-bottom: 0 !important;
                    margin-top: 0;
                    font-size: 20px;
                    text-align: left;
                    font-weight: 600;
                }

                .text-muted {
                    margin-bottom: 15px;
                    font-size: 14px;
                    text-align: left;
                    font-style: italic;
                    margin-top: 5px;
                }

                img {
                    border: .75px solid $colorBorder;
                    border-radius: 5px;
                    width: 100%;
                    height: auto;
                    max-height: none;
                    margin-bottom: 0px;
                    aspect-ratio: 1.33;

                    @media (max-width: 575px) {
                        margin-bottom: 20px;
                        border: .75px solid #c4c2c2;
                    }
                }

                .report-list {
                    div {
                        font-size: 14px;
                        font-weight: 100;
                        overflow: hidden;
                        text-align: left;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        letter-spacing: 0.5px;

                        @media (max-width: 600px) {
                            -webkit-line-clamp: 5;
                        }

                        .read-more {
                            color: $colorRed;
                            font-weight: 600;
                            font-style: italic;
                            margin-top: 20px;
                            font-size: 16px
                        }
                    }
                }

                .col-8 {
                    @media (max-width: 575px) {
                        padding-left: 5px;
                    }
                }
            }
        }
    }

    /*md*/
    @media (max-width: 992px) {
        .section-banner .banner-text {
            width: 70%;
        }

        .section-count {
            .sort-box {
                margin-top: 10px;
            }

            .count-wrap {
                margin-top: 40px;
            }
        }
    }

    /*sm*/
    @media (max-width: 767px) {
        .section-banner {
            .banner-text {
                width: 100% !important;
            }

            .btn {
                padding: 10px 30px;
            }
        }

        .section-count .count-text {
            width: 100%;
        }

        .section-feature {
            .feature-text {
                width: 100%;
            }

            .feature {
                padding: 15px;
            }
        }

        .section-dashboard .dashboard-img {
            margin: 40px auto;
            transform: scale(1.2);
        }

        .section-reference .logo-wrap .reference-logo {
            min-width: 120px;
        }
    }
}

.section-about {
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    padding: 60px 40px;
    background-color: $colorWhite;
    color: $colorBlack1;
}

.section-testimonials {
    padding-bottom: 20px;

    .page-header {
        margin-bottom: 15px;
    }
}

/* === end // Landing Page === */


/* === Teaser Page === */
.pe-landing-page {
    section {
        background: $colorWhite;
    }

    .section-teaser {
        padding: 0px;
        position: relative;
        margin: -1px;

        .teaser-background {
            width: 100%;
            min-width: 100% !important;
            position: relative;
            z-index: 0;
        }

        .teaser-header {
            width: 100% !important;
            text-align: center;
            padding: 0 !important;
            background: transparent !important;
            position: absolute;
            top: 40px;
            z-index: 1;

            h1 {
                font-size: 34px;
                font-weight: 1000;
                color: $colorWhite;
            }

            .demo-subtitle {
                font-size: 28px;
                font-weight: 100;
                color: $colorWhite;
            }
        }

        .teaser-demo {
            position: absolute;
            bottom: 60px;
            width: 100% !important;
            text-align: center;
            padding: 0 !important;
            background: transparent !important;
            z-index: 1;

            .email-subtitle {
                font-size: 18px;
                font-weight: 100;
                color: $colorWhite;
                margin-bottom: 5px;
            }

            .demo-input {
                width: 60%;
                margin: auto;

                @media (max-width: 1024px) {
                    width: 70%;
                }

                @media (max-width: 600px) {
                    width: 85%;
                }

                .input-group {
                    background-color: $colorWhite;
                    border-radius: 15px !important;

                    input {
                        padding: 0 20px;
                        height: 36px;
                        border: none;
                        border-radius: 15px 0 0 15px;
                    }

                    .btn {
                        padding: 18px;
                        border-radius: 15px !important;
                        border: 1px solid $colorWhite;
                    }
                }

                .form-error,
                .form-success {
                    color: $colorWhite;
                }
            }
        }
    }

    // medium screen size
    @media (max-width: 992px) {
        .teaser-background {
            width: 130% !important;
            margin: 0 -15% !important;
        }

        .section-teaser {
            .teaser-header {
                top: 30px;

                h1 {
                    font-size: 30px !important;
                    padding: 0 10px;
                }

                .demo-subtitle {
                    font-size: 20px !important;
                }
            }

            .teaser-demo {
                bottom: 45px;

                .email-subtitle {
                    font-size: 16px !important;
                }
            }
        }
    }

    // small screen size
    @media (max-width: 576px) {
        .teaser-background {
            width: 140% !important;
            margin: 0 -20% !important;
        }

        .section-teaser {
            .teaser-header {
                top: 15px;

                h1 {
                    font-size: 20px !important;
                    padding: 0 10px;
                }

                .demo-subtitle {
                    font-size: 12px !important;
                }
            }

            .teaser-demo {
                bottom: 20px;

                .email-subtitle {
                    font-size: 10px !important;
                }

                .input-group {
                    height: 24px !important;

                    input,
                    .btn {
                        font-size: 10px !important;
                        height: 24px !important;
                    }

                    .btn {
                        padding: 11px !important;
                    }
                }
            }
        }
    }

    // extra small screen size
    @media (max-width: 400px) {
        .section-teaser {
            .teaser-background {
                width: 150% !important;
                margin: 0 -25% !important;
            }

            .teaser-header {
                top: 5px !important;

                h1 {
                    font-size: 18px !important;
                }

                .demo-subtitle {
                    font-size: 10px !important;
                }
            }

            .teaser-demo {
                bottom: 20px;

                .email-subtitle {
                    font-size: 8px !important;
                }
            }
        }
    }
}

/* === end // Teaser Page === */


/* === Join Customer Base Section === */

.join-wrap {
    background-color: $colorBlack1;
    color: $colorWhite;
    font-size: 37px;
    line-height: 1.2 !important;
    text-align: center;
    padding: 80px 40px;
    font-weight: 600;

    .join-text {
        width: 55%;
        margin: auto;
        line-height: 50px;
        margin-bottom: 40px;
    }

    .btn {
        padding: 10px 30px;
    }
}

/*xs*/
@media (max-width: 767px) {
    .join-wrap {
        font-size: 26px;

        .join-text {
            line-height: 38px;
            width: 100%
        }

        .btn {
            margin-top: 40px;
        }
    }
}

/* === end // Join Customer Base Section === */

/* === Testimonial Page === */

.testimonials-wrap {
    margin: 0 auto;
    max-width: 1200px;
    color: #333;

    .testimonial-box {
        padding: 30px;
        border-radius: 15px;
    }

    // make all slides auto-height
    .slick-slide[aria-hidden="true"] {
        max-height: 300px;
    }

    // fix issue of slides getting cut off
    .slick-list {
        box-sizing: initial;
        height: auto !important;
    }

    .slick-prev,
    .slick-next {
        position: absolute
    }

    .slick-prev:before,
    .slick-next:before {
        color: $colorBlack;
        border-right: 3px solid currentcolor;
        border-bottom: 3px solid currentcolor;
        content: '';
        position: absolute;
        width: 20px;
        height: 20px
    }

    .slick-prev:before {
        transform: rotate(135deg);
    }

    .slick-next:before {
        transform: rotate(-45deg)
    }

    .author-img,
    .logo-container {
        height: 90px;
        border: 1px solid #EFF0F6;
        border-radius: 12px;
    }

    .author-name,
    .company-name {
        text-align: left;
    }

    .author-img {
        margin: auto;
        aspect-ratio: 1;
    }

    .logo-container {
        margin: 0 auto;
        display: flex;
        align-items: center;
        padding: 20px;
        max-width: 200px;
    }

    .author-name {
        color: $colorPureBlack;
        font-size: 18px;
        font-weight: 700;
    }

    .company-name {
        color: $colorBlack;
        font-size: 14px;
        line-height: 21px;
        margin-top: 4px;
    }

    .description {
        margin-top: 25px;
        text-align: left;

        .testimonial-quotemark {
            font-size: 30px;
            font-family: Georgia, serif;
            line-height: 14px;
            margin-top: 5px;
            position: absolute;
            color: $colorOrange;
        }

        p {
            text-indent: 20px;
            font-size: 15px;
            font-weight: 400;
            color: $colorPureBlack;
        }
    }

    // desktop
    @media(min-width: 1301px) {
        .slick-prev:before {
            left: -10px;
        }

        .slick-next:before {
            right: -10px;
        }
    }

    // mobile
    @media(max-width: 1300px) {
        .slick-prev:before {
            left: 5px;
        }

        .slick-next:before {
            right: 5px;
        }
    }
}

/* === centered header with line === */
// used as secondary header on landing pages

.explore-all {
    display: inline-block;
    position: relative;
    font-size: 24px;

    &:before,
    &:after {
        content: "";
        position: absolute;
        border-top: 1px solid $colorRed;
        top: 20px;
        width: 50px
    }

    &:before {
        right: 100%;
        margin-right: 15px;
    }

    &:after {
        left: 100%;
        margin-left: 15px;
    }
}

// Home page
.home-dashboard {
    .db-header {
        margin-bottom: 15px !important;

        div {
            font-size: 18px;
            font-weight: 700;
            color: $darkBlue1;
        }
    }

    .table {
        margin-top: 0 !important;
    }

    // Recent funding section in home page
    .dashboard-recent-funding {
        #BarWithLineChart-container {

            // mobile
            @media(min-width: 992px) {

                g,
                text,
                .tick {
                    font-size: 25px !important;
                    font-weight: 600 !important;
                    padding: 5px !important;
                }
            }
        }

        .latest-funding-container {
            display: flex;
            padding-top: 20px !important;

            .chart-container {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 65%;
            }

            .table-container {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 35%;
            }

            .divider {
                border-right: none;
                border-top: 1px solid $colorTableBorder;
                width: 99%;
                margin: 3rem 0;
                margin-top: 35px;

                @media(min-width: 992px) {
                    border-right: 1px solid $colorTableBorder;
                    margin: 0 30px;
                    width: 0;
                }
            }

            @media(max-width: 992px) {
                flex-direction: column;
                align-items: center;

                .chart-container,
                .table-container {
                    width: 100%;
                }
            }
        }

        .table-container {
            td {
                padding: 15px 10px !important;
            }
        }
    }

    // Dashboards list section in home page
    .dashboard-list {
        .section-header {
            font-size: 16px !important;
            font-weight: 500 !important;
            margin-bottom: 15px;
        }

        [class*="col-"] {
            padding: 0 !important;
        }

        .row {
            margin: 0 auto !important;

            .saved-views {
                padding-right: 30px !important;
                border-right: 0.75px solid #e5e5e5 !important;
            }

            .saved-maps {
                padding-left: 30px !important;

                .map-icon {
                    background-repeat: no-repeat;
                    background-size: contain;
                    display: inline-block;
                    margin-top: 4px !important;
                    height: 16px;
                    width: 16px;
                    font-size: 16px !important;
                }

                .subheader-maps {
                    margin-left: 26px !important;
                }

                .see-examples {
                    margin-left: 10px;
                    border-color: $colorBlack;

                    a,
                    i {
                        color: $colorBlack;
                    }
                }
            }

            .create-dashboard-text {
                font-size: 15px;
                max-width: 650px;
            }

            .dashboard-columns {
                column-count: 2;
                column-gap: 30px;
                -webkit-column-count: 2;
                -webkit-column-gap: 30px;
                -moz-column-count: 2;
                -moz-column-gap: 30px;
                margin-bottom: 15px;
            }

            .dashboard-items {
                -webkit-column-break-inside: avoid;
                /* Chrome, Safari, Opera */
                page-break-inside: avoid;
                /* Firefox */
                break-inside: avoid;

                .dashboard-header {
                    display: flex;
                    font-size: 15px !important;
                    font-weight: 600 !important;

                    i {
                        color: $colorRed !important;
                        font-size: 12px;
                        margin-right: 10px;
                        margin-top: 6px;
                    }

                    a {
                        color: $colorBlack1;
                    }

                    a:hover {
                        color: $colorRed !important;
                    }
                }

                .dashboard-subheader {
                    font-size: 14px !important;
                    padding-bottom: 10px;
                    margin-left: 22px;
                }
            }

            .block {
                padding: 0 !important;
            }

            .dashboard-error-message {
                max-width: 600px;
            }

            @media (max-width:991.5px) {
                .saved-views {
                    border-right: 0 !important;
                    border-bottom: 2px solid #e5e5e5 !important;
                    padding: 0 !important;
                    padding-bottom: 25px !important;
                }

                .saved-maps {
                    padding: 0 !important;
                    padding-top: 25px !important;
                }
            }

            @media (min-width: 769px) and (max-width: 991px) {
                .dashboard-columns {
                    column-count: 3;
                    -webkit-column-count: 3;
                    -moz-column-count: 3;
                }
            }

            @media (max-width: 600px) {
                .see-examples {
                    margin: 0 !important;
                    margin-top: 15px !important;
                    display: block;
                }

                .btn {
                    width: 100%;
                    padding: 8px;
                }

                .dashboard-columns {
                    column-count: 1;
                    -webkit-column-count: 1;
                    -moz-column-count: 1;
                }
            }
        }
    }
}